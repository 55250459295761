// import vedio from '../../Images/website.filmpje.tarifa.mp4';


import vanlife_vedio from '../../Images/tripvedios/VanLife_vedio.mp4'
import Zanzibar_vedio from '../../Images/tripvedios/zanzibar_vedio.mp4'
import Snowkite_vedio from '../../Images/tripvedios/snow_kite_vedio.mp4'
import skyCapTown_vedio from '../../Images/tripvedios/skycapeTown_vedio.mp4'
import KitemeccaTraifa_vedio from '../../Images/tripvedios/kitemecca_vedio.mp4'
import EndlessSummerParty_vedio from '../../Images/tripvedios/Endless_Egypt_vedio.mp4'
import Brazil_vedio from '../../Images/tripvedios/Brazil_vedio.mp4'
import Sailkite_vedio from '../../Images/tripvedios/sailkite_vedio.mp4'
import Sicily_vedio from '../../Images/tripvedios/sicily_vedio.mp4'


import vanlifecover from "../../Images/tripvedios/vanlifecover.webp"
import zanzibarcover from "../../Images/tripvedios/zanzibar.webp"
import snowkitecover from "../../Images/tripvedios/snowkite.webp"
import skycaptowncover from "../../Images/tripvedios/skycapetowncover.webp"
import kitemeacacover from "../../Images/tripvedios/kitmecacover.webp"
import endlesscoover from "../../Images/tripvedios/egyptcover.webp"
import brazilcover from "../../Images/tripvedios/brazilcover.webp"
import sailkitecover from "../../Images/tripvedios/sailkitecover.webp"
import sicily from "../../Images/tripvedios/sciliycover.webp"




// valnlife img data .........................................


import vanlife_gall_img1 from '../../Images/vanlife1.webp'
import vanlife_gall_img2 from '../../Images/vanlife2.webp'
import vanlife_gall_img3 from '../../Images/vanlife3.webp'
import vanlife_gall_img4 from '../../Images/vanlife4.webp'

import van_hotel_img1 from '../../Images/van_acc_img1.webp'
import van_hotel_img2 from '../../Images/van_acc_img2.webp'
import van_hotel_img3 from '../../Images/van_acc_img3.webp'
import van_hotel_img4 from '../../Images/van_acc_img4.webp'
import van_hotel_img5 from '../../Images/van_acc_img5.webp'
import van_hotel_img6 from '../../Images/van_acc_img6.webp'




// Zanzibar images data ...............................

import zan_OW_gall_img1 from '../../Images/zan_van_img1.webp'
import zan_OW_gall_img2 from '../../Images/zan_van_img2.webp'
import zan_OW_gall_img3 from '../../Images/zan_van_img3.webp'
import zan_OW_gall_img4 from '../../Images/zan_van_img4.webp'

import zan_KS_img1 from '../../Images/zan_kitspot_img1.webp'
import zan_KS_img2 from '../../Images/zan_kitspot_img2.webp'
import zan_KS_img3 from '../../Images/zan_kitspot_img3.webp'

import zan_hosted_img1 from '../../Images/zan_host_img1.webp'
import zan_hosted_img2 from '../../Images/zan_host_img2.webp'
import zan_hosted_img3 from '../../Images/zan_host_img3.webp'
import zan_hosted_img4 from '../../Images/zan_host_img4.webp'
import hosted_earth from "../../Images/earth.webp"
import hosted_at from "../../Images/at.webp"

import kitespotimg1 from "../../Images/kitspot1.webp"
import kitespotimg2 from "../../Images/kitspot2.webp"
// import kitespotimg3 from "../../Images/kitspot3.webp"


import zanzibar_activity_img1 from '../../Images/activity1.webp'
import zanzibar_activity_img2 from '../../Images/activity2.webp'
import zanzibar_activity_img3 from '../../Images/activity3.webp'
import zanzibar_activity_img4 from '../../Images/activity4.webp'
import zanzibar_activity_img5 from '../../Images/activity5.webp'

import surf_img1 from '../../Images/surf-img1.png'
import surf_img2 from '../../Images/surf_img2.png'
import surf_img3 from '../../Images/surf_img3.png'
import surf_img4 from '../../Images/surf_img4.png'
import surf_img5 from '../../Images/surf_img5.png'
import surf_img6 from '../../Images/surf_img6.png'
import surf_img7 from '../../Images/surf_img7.png'
import surf_img8 from '../../Images/surf_img8.png'
import surf_img9 from '../../Images/surf_img9.png'
import surf_img10 from '../../Images/surf_img10.png'
import surf_img11 from '../../Images/surf_img11.png'
import surf_img12 from '../../Images/surf_img12.png'
import surf_img13 from '../../Images/surf_img13.png'




// Snowkite trips images data 

import snowkite_OW_img1 from '../../Images/snowkite_OW_image.webp'
import snowkite_OW_img2 from '../../Images/snowkite_OW_image2.webp'
import snowkite_OW_img3 from '../../Images/snowkite_OW_image3.webp'
import snowkite_OW_img4 from '../../Images/snowkite_OW_image4.webp'

import Snow_kitespotimg1 from '../../Images/snowkite_kitespot_img1.webp'


import Snow_hosted_img1 from '../../Images/snowkite_hosted_img1.webp'
import Snow_hosted_img2 from '../../Images/snowkite_hosted_img2.webp'
import Snow_hosted_img3 from '../../Images/snowkite_hosted_img3.webp'
import Snow_hosted_img4 from '../../Images/snowkite_hosted_img4.webp'





// Sky high capetown trip  IMAGES data ..................


import skycap_OW_gall_img1 from '../../Images/skyCap_OW_img1.webp'
import skycap_OW_gall_img2 from '../../Images/skyCap_OW_img2.webp'
import skycap_OW_gall_img3 from '../../Images/skyCap_OW_img3.webp'
import skycap_OW_gall_img4 from '../../Images/skyCap_OW_img4.webp'


import skycap_kitespotimg1 from '../../Images/skyCap_kitspot_img1.webp'
import skycap_kitespotimg2 from '../../Images/skyCap_kitspot_img2.webp'


import skycap_hotel1 from '../../Images/skyCap_ACC_img1.webp'
import skycap_hotel2 from '../../Images/skyCap_ACC_img2.webp'
import skycap_hotel3 from '../../Images/skyCap_ACC_img3.webp'
import skycap_hotel4 from '../../Images/skyCap_ACC_img4.webp'
import skycap_hotel5 from '../../Images/skyCap_ACC_img5.webp'
import skycap_hotel6 from '../../Images/skyCap_ACC_img6.webp'


import skycap_hosted_img1 from '../../Images/skyCap_hosted_img1.webp'
import skycap_hosted_img2 from '../../Images/snowkite_hosted_img3.webp'
import skycap_hosted_img3 from '../../Images/skyCap_hosted_img2.webp'
import skycap_hosted_img4 from '../../Images/snowkite_hosted_img4.webp'




// KitemeccaTraifa images data ...........................


import KitemeccaTraifa_img1 from '../../Images/KitemeccaTraifa1.webp'
import KitemeccaTraifa_img2 from '../../Images/KitemeccaTraifa2.webp'
import KitemeccaTraifa_img3 from '../../Images/KitemeccaTraifa3.webp'
import KitemeccaTraifa_img4 from '../../Images/KitemeccaTraifa4.webp'

import KitemeccaTraifa_img5 from '../../Images/KitemeccaTraifa5.webp'
import KitemeccaTraifa_img6 from '../../Images/KitemeccaTraifa6.webp'
import KitemeccaTraifa_img7 from '../../Images/KitemeccaTraifa7.webp'

import KitemeccaTraifa_img8 from '../../Images/KitemeccaTraifa8.webp'
import KitemeccaTraifa_img9 from '../../Images/KitemeccaTraifa9.webp'
import KitemeccaTraifa_img10 from '../../Images/skyCap_hosted_img1.webp'
import KitemeccaTraifa_img11 from '../../Images/snowkite_hosted_img4.webp'




// EndlessSummerParty trip IMAGES data Egy ............................................

import endless_img1 from '../../Images/endless1.webp'
import endless_img2 from '../../Images/endless2.webp'
import endless_img3 from '../../Images/endless3.webp'
import endless_img4 from '../../Images/endless4.webp'

import endless_img5 from '../../Images/endless_kitespot_1.webp'

import endless_acc_img1 from '../../Images/endless_acc_1.webp'
import endless_acc_img2 from '../../Images/endless_acc_2.webp'
import endless_acc_img3 from '../../Images/endless_acc_3.webp'
import endless_acc_img4 from '../../Images/skyCap_ACC_img1.jpg'
import endless_acc_img5 from '../../Images/skyCap_ACC_img3.webp'
import endless_acc_img6 from '../../Images/skyCap_ACC_img2.webp'




// brazil Images ...............

import brazil_img1 from "../../Images/brazil_OW_img1.webp"
import brazil_img2 from "../../Images/brazil_OW_img2.webp"
import brazil_img3 from "../../Images/brazil_OW_img3.webp"
import brazil_img4 from "../../Images/brazil_OW_img4.webp"


import brazil_kitespotimg1 from '../../Images/brazil_kitespot_img1.webp'
import brazil_kitespotimg2 from '../../Images/brazil_kitespot_img2.webp'

import brazil_hotel1 from '../../Images//brazil_acc_img1.webp'
import brazil_hotel2 from '../../Images//brazil_acc_img2.webp'
import brazil_hotel3 from '../../Images//brazil_acc_img3.webp'
import brazil_hotel4 from '../../Images//brazil_acc_img4.webp'
import brazil_hotel5 from '../../Images//brazil_acc_img5.webp'
import brazil_hotel6 from '../../Images//brazil_acc_img6.webp'


import Sicily_img1 from '../../Images/sicily_OW_img1.webp'
import Sicily_img2 from '../../Images/sicily_OW_img2.webp'
import Sicily_img3 from '../../Images/sicily_OW_img3.webp'
import Sicily_img4 from '../../Images/sicily_OW_img4.webp'

import sicily_kitespot_img1 from '../../Images/sicily_kitespot_img1.webp'
import sicily_kitespot_img2 from '../../Images/sicily_kitespot_img2.webp'

import Sicily_hosted_img1 from '../../Images/sicily_acc_img1.webp'


import Sicily_hotel1 from "../../Images/sicily_acc_img1.webp"
import Sicily_hotel2 from "../../Images/sicily_acc_img2.webp"
import Sicily_hotel3 from "../../Images/sicily_acc_img3.webp"
import Sicily_hotel4 from "../../Images/sicily_acc_img4.webp"
import Sicily_hotel5 from "../../Images/sicily_acc_img5.webp"
import Sicily_hotel6 from "../../Images/sicily_acc_img1.webp"



import hosted_img1 from "../../Images/Hosted1.webp"
import hosted_img2 from "../../Images/hosted2.jpg"
import hosted_img3 from "../../Images/hosted3.webp"
// import hosted_img4 from "../../Images/hosted4.webp"



import hotel1 from "../../Images/hotel1.webp";
import hotel2 from "../../Images/hotel2.webp";
import hotel3 from "../../Images/hotel3.webp";

import service1 from "../../Images/service1.webp";
import service2 from "../../Images/service2.webp";
import service3 from "../../Images/service3.webp";



import tab1_card1 from "../../Images/logo1.webp";
import tab1_card2 from "../../Images/logo2.webp";
import tab1_card3 from "../../Images/logo4.webp";
import tab1_card4 from "../../Images/logo1.webp";
import tab1_1 from "../../Images/tab1_1.webp";
import tab1_2 from "../../Images/tab1_2.webp";
import tab2_1 from "../../Images/DSC09834-1536x1024-1.webp";
import tab2_2 from "../../Images/E0105612-scaled.jpg";
import tab3_1 from "../../Images/tab1_2.webp";
import tab3_2 from "../../Images/tab1_2.webp";


const trips = [
    // vanlife trip data .......................

    {
        name: 'vanlife',
        vedio: vanlife_vedio,
        backcover: vanlifecover,
        alltabs: {
            highlight: [
                {
                    text: {
                        en: "Join an awesome #vanlife #kite community",
                        gr: "Schließe dich einer großartigen #vanlife #kite Gemeinschaft an",
                        du: "Sluit je aan bij een geweldige #vanlife #kite community"
                    }
                },
                {
                    text: {
                        en: "Unique road trip through Europe along the best kitespots",
                        gr: "Einzigartige Roadtrip durch Europa entlang der besten Kitespots",
                        du: "Unieke roadtrip door Europa langs de beste kitespots"
                    }
                },
                {
                    text: {
                        en: "We find the best weather conditions, success is guaranteed!",
                        gr: "Wir finden die besten Wetterbedingungen, Erfolg ist garantiert!",
                        du: "Wij vinden de beste weersomstandigheden, succes gegarandeerd!"
                    }
                },
                {
                    text: {
                        en: "No stress, we take care of everything!",
                        gr: "Kein Stress, wir kümmern uns um alles!",
                        du: "Geen stress, wij regelen alles!"
                    }
                },
                {
                    text: {
                        en: "100% Fun guaranteed",
                        gr: "100% Spaß garantiert",
                        du: "100% plezier gegarandeerd"
                    }
                },
                {
                    text: {
                        en: "Progression guaranteed",
                        gr: "Fortschritt garantiert",
                        du: "Vooruitgang gegarandeerd"
                    }
                },
            ],
            question: [
                {
                    title: {
                        en: "What lesson package should I choose?",
                        gr: "Welches Unterrichtspaket soll ich wählen?",
                        du: "Welk lespakket moet ik kiezen?"
                    },
                    para: {
                        en: "Send us an e-mail or contact us via chat and leave your phone number and we will give you extensive advice!",
                        gr: "Sende uns eine E-Mail oder kontaktiere uns über den Chat und hinterlasse deine Telefonnummer, und wir geben dir umfassende Beratung!",
                        du: "Stuur ons een e-mail of neem contact met ons op via de chat en laat je telefoonnummer achter en wij geven je uitgebreid advies!"
                    }
                },
                {
                    title: {
                        en: "How is the group?",
                        gr: "Wie ist die Gruppe?",
                        du: "Hoe is de groep?"
                    },
                    para: {
                        en: "Our groups can vary from young to old, and are often evenly distributed with both men and women. The group usually consists of ages between 20 and 45, with exceptions of course. We teach beginners and give tips to advanced kitesurfers, so you can expect every level from us. We also often offer group activities that you can participate in without obligation. On some trips you may share a large room with other KiteActive guests, but this will be indicated. You can often contact us in advance via our Facebook events or see via the WhatsApp group which people will be present on the trip.",
                        gr: "Unsere Gruppen können von jung bis alt variieren und sind oft gleichmäßig auf Männer und Frauen verteilt. Die Gruppe besteht in der Regel aus Teilnehmern zwischen 20 und 45 Jahren, mit Ausnahmen natürlich. Wir unterrichten Anfänger und geben fortgeschrittenen Kitesurfern Tipps, also kannst du bei uns jedes Niveau erwarten. Wir bieten auch oft Gruppenaktivitäten an, an denen du unverbindlich teilnehmen kannst. Auf einigen Reisen kannst du ein großes Zimmer mit anderen KiteActive-Gästen teilen, aber das wird angegeben. Oft kannst du uns im Voraus über unsere Facebook-Events kontaktieren oder über die WhatsApp-Gruppe sehen, welche Personen auf der Reise dabei sein werden.",
                        du: "Onze groepen kunnen variëren van jong tot oud en zijn vaak gelijkmatig verdeeld tussen mannen en vrouwen. De groep bestaat meestal uit leeftijden tussen 20 en 45, met natuurlijk uitzonderingen. We leren beginners en geven tips aan gevorderde kitesurfers, dus je kunt elk niveau van ons verwachten. We bieden ook vaak groepsactiviteiten aan waaraan je vrijblijvend kunt deelnemen. Op sommige trips kun je een grote kamer delen met andere KiteActive-gasten, maar dit wordt aangegeven. Je kunt ons vaak van tevoren via onze Facebook-evenementen contacteren of via de WhatsApp-groep zien welke mensen aanwezig zullen zijn op de trip."
                    }
                },
                {
                    title: {
                        en: "For which level?",
                        gr: "Für welches Niveau?",
                        du: "Voor welk niveau?"
                    },
                    para: {
                        en: "For all levels! From beginner to advanced, you can enjoy and train on this lagoon.",
                        gr: "Für alle Niveaus! Vom Anfänger bis zum Fortgeschrittenen kannst du diese Lagune genießen und trainieren.",
                        du: "Voor alle niveaus! Van beginner tot gevorderde, je kunt genieten en trainen op deze lagune."
                    }
                },
                {
                    title: {
                        en: "How is the wind? What kite material is needed?",
                        gr: "Wie ist der Wind? Welches Kite-Material wird benötigt?",
                        du: "Hoe is de wind? Welk kite-materiaal is nodig?"
                    },
                    para: {
                        en: "Normally the wind blows 13 to 30 knots so the golden rule is to bring all sizes of kites!",
                        gr: "Normalerweise weht der Wind mit 13 bis 30 Knoten, also lautet die goldene Regel: Bringe alle Kite-Größen mit!",
                        du: "Normaal gesproken waait de wind 13 tot 30 knopen, dus de gouden regel is: neem alle maten kites mee!"
                    }
                },
                {
                    title: {
                        en: "Where do we go? How far are we going to drive?",
                        gr: "Wohin gehen wir? Wie weit werden wir fahren?",
                        du: "Waar gaan we heen? Hoe ver gaan we rijden?"
                    },
                    para: {
                        en: "This depends on the weather forecast. A few days before the trips start we will do an online meeting where we discuss all the options with our weather experts. We will make sure we travel in the right direction to chase the best conditions! The amount of KM we drive during this trip is changing from 500-3000 km.",
                        gr: "Das hängt von der Wettervorhersage ab. Ein paar Tage vor Beginn der Reisen werden wir ein Online-Meeting abhalten, bei dem wir alle Optionen mit unseren Wetterexperten besprechen. Wir sorgen dafür, dass wir in die richtige Richtung reisen, um die besten Bedingungen zu finden! Die Anzahl der Kilometer, die wir während dieser Reise fahren, variiert zwischen 500 und 3000 km.",
                        du: "Dit hangt af van de weersvoorspelling. Enkele dagen voor de reis begint, houden we een online meeting waarin we alle opties bespreken met onze weerexperts. We zorgen ervoor dat we in de juiste richting reizen om de beste omstandigheden te vinden! Het aantal kilometers dat we tijdens deze trip afleggen varieert van 500 tot 3000 km."
                    }
                },
                {
                    title: {
                        en: "How much are the extra costs?",
                        gr: "Wie hoch sind die zusätzlichen Kosten?",
                        du: "Hoeveel zijn de extra kosten?"
                    },
                    para: {
                        en: "For gasoline and toll, it is between € 150-700. For camping (when we don't stay in unique, free off-grid places) around € 10-25 / night. For food and drinks you can budget roughly around € 20-40 / day.",
                        gr: "Für Benzin und Maut sind es zwischen 150-700 €. Für Camping (wenn wir nicht an einzigartigen, kostenlosen Off-Grid-Orten bleiben) etwa 10-25 € / Nacht. Für Essen und Trinken kannst du grob mit 20-40 € / Tag rechnen.",
                        du: "Voor benzine en tol is het tussen de € 150-700. Voor kamperen (wanneer we niet verblijven op unieke, gratis off-grid locaties) ongeveer € 10-25 / nacht. Voor eten en drinken kun je grofweg rekenen op € 20-40 / dag."
                    }
                },
                {
                    title: {
                        en: "Are we going off-grid?",
                        gr: "Werden wir off-grid gehen?",
                        du: "Gaan we off-grid?"
                    },
                    para: {
                        en: "Yes, we try to sleep a few nights in amazing places where you don't have to pay anything!",
                        gr: "Ja, wir versuchen, ein paar Nächte an fantastischen Orten zu schlafen, wo du nichts bezahlen musst!",
                        du: "Ja, we proberen een paar nachten te slapen op geweldige plekken waar je niets hoeft te betalen!"
                    }
                }
            ]
        },

        overview: {
            trip_heading: {
                en: "Discover the Vanlife Roadtrip: A European Journey for Kitesurfers",
                gr: "Entdecke die Vanlife-Roadtrip: Eine europäische Reise für Kitesurfer",
                du: "Ontdek de Vanlife Roadtrip: Een Europese Reis voor Kitesurfers"
            },
            trip_para: {
                en: "Join us for an unforgettable VanLife roadtrip across Europe's stunning coastlines, perfect for kitesurfers! Experience the thrill of waking up next to breaking waves, chase the wind, and continuously explore hidden gems. This is VanLife at its finest – where epic kitesurfing sessions meet the freedom of the open road. Pack your gear, hop in your van, and let's hit the road for the adventure of a lifetime! KiteActive will take you to new spots, give you coaching and progress classes and makes you meet a great new community!",
                gr: "Begleite uns auf einem unvergesslichen VanLife-Roadtrip entlang der atemberaubenden Küsten Europas, perfekt für Kitesurfer! Erlebe das aufregende Gefühl, neben brechenden Wellen aufzuwachen, jag den Wind und entdecke ständig versteckte Schätze. Das ist VanLife vom Feinsten – wo epische Kitesurf-Sessions auf die Freiheit der offenen Straße treffen. Packe dein Equipment, spring in deinen Van und lass uns aufbrechen zu einem Abenteuer deines Lebens! KiteActive bringt dich zu neuen Spots, bietet Coaching und Fortschrittskurse und ermöglicht dir, eine großartige neue Community kennenzulernen!",
                du: "Sluit je aan bij ons voor een onvergetelijke VanLife-roadtrip langs de prachtige kusten van Europa, perfect voor kitesurfers! Ervaar de opwinding van wakker worden naast brekende golven, achter de wind aanjagen en voortdurend verborgen juweeltjes verkennen. Dit is VanLife op zijn best - waar epische kitesurf-sessies samenkomen met de vrijheid van de open weg. Pak je gear, stap in je bus en laten we de weg opgaan voor het avontuur van je leven! KiteActive neemt je mee naar nieuwe plekken, biedt coaching en voortgangscursussen en helpt je een geweldige nieuwe gemeenschap te ontmoeten!"
            },
            img1: surf_img10,
            img1_head: {
                en: "Dates 2025",
                gr: "Termine 2025",
                du: "Data 2025"
            },
            img1_para: {
                en: "19 - 29 September",
                gr: "19. - 29. September",
                du: "19 - 29 september"
            },
            img2: surf_img8,
            img2_head: {
                en: "Skills",
                gr: "Fähigkeiten",
                du: "Vaardigheden"
            },
            img2_para: {
                en: "Every skill is welcome.. from beginners to advanced",
                gr: "Jede Fähigkeit ist willkommen.. von Anfängern bis Fortgeschrittenen",
                du: "Iedere vaardigheid is welkom.. van beginners tot gevorderden"
            },
            img3: surf_img12,
            img3_head: {
                en: "Join KiteActive",
                gr: "Werde Teil von KiteActive",
                du: "Word deel van KiteActive"
            },
            img3_para: {
                en: "Good vibes only. Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "Nur gute Vibes. Trete dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / dem KA-Team zu plaudern.",
                du: "Alleen goede vibes. Sluit je aan bij de groepschat om op de hoogte te blijven of chat met andere reizigers / KA Team."
            },
            img4: surf_img6,
            img4_head: {
                en: "Choose your style",
                gr: "Wähle deinen Stil",
                du: "Kies je stijl"
            },
            img4_para: {
                en: "Would you like to join, but you don't have a camper? No worries, you can rent a camper with us or join with your 'normal' car.",
                gr: "Möchtest du mitmachen, hast aber keinen Camper? Keine Sorge, du kannst bei uns einen Camper mieten oder mit deinem 'normalen' Auto mitkommen.",
                du: "Wil je meedoen, maar heb je geen camper? Geen zorgen, je kunt bij ons een camper huren of meedoen met je 'gewone' auto."
            },
            gall_img1: vanlife_gall_img1,
            gall_img2: vanlife_gall_img2,
            gall_img3: vanlife_gall_img3,
            gall_img4: vanlife_gall_img4,
        },
        kitespot: [
            {
                imgSrc: kitespotimg1,
                text: {
                    en: 'Follow the sun, wind, and waves with KiteActive',
                    du: 'Volg de zon, de wind en de golven met KiteActive',
                    gr: 'Folge der Sonne, dem Wind und den Wellen mit KiteActive'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: 'The entire trip will be a big surprise, as we will make a daily plan depending on where the best possible conditions to kite are! A few days before the trip, our team of weather experts will check where in Europe the wind will be the best for kitesurfing. Is it Denmark? Is it France? Maybe we drive to Spain? Or should we hit one of the beautiful lakes in Italy? Get ready to kitesurf Europe like never before! These routes are more or less an indication of kite spots and what to expect. Our travel and weather guides will create the perfect plan day by day to make sure you hit the spot at the right time!',
                    du: 'De hele reis zal een grote verrassing zijn, omdat we een dagelijks plan zullen maken, afhankelijk van waar de beste omstandigheden om te kitesurfen zijn! Een paar dagen voor de reis zal ons team van weerspecialisten controleren waar in Europa de wind het beste zal zijn voor kitesurfen. Is het Denemarken? Is het Frankrijk? Misschien rijden we naar Spanje? Of moeten we een van de prachtige meren in Italië bezoeken? Maak je klaar om Europa te kitesurfen als nooit tevoren! Deze routes zijn meer of minder een indicatie van kitespots en wat je kunt verwachten. Onze reis- en weergeleiders zullen dag voor dag het perfecte plan maken om ervoor te zorgen dat je op het juiste moment op de juiste plek bent!',
                    gr: 'Die gesamte Reise wird eine große Überraschung sein, da wir je nach den besten Bedingungen zum Kitesurfen einen täglichen Plan erstellen werden! Ein paar Tage vor der Reise wird unser Team von Wetterexperten überprüfen, wo in Europa der Wind am besten zum Kitesurfen ist. Ist es Dänemark? Ist es Frankreich? Vielleicht fahren wir nach Spanien? Oder sollten wir einen der schönen Seen in Italien ansteuern? Mach dich bereit, Europa beim Kitesurfen wie nie zuvor zu erleben! Diese Routen sind mehr oder weniger eine Indikation der Kitespots und was zu erwarten ist. Unsere Reise- und Wetterguides werden Tag für Tag den perfekten Plan erstellen, um sicherzustellen, dass du den Spot zur richtigen Zeit erreichst!'
                },
                imgPosition: 'right',
            },

            {
                imgSrc: kitespotimg2,
                text: {
                    en: 'Variety of unique kite spots',
                    du: 'Verscheidenheid aan unieke kitespots',
                    gr: 'Vielfalt einzigartiger Kitespots'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: "We will choose the best kite spots according to the levels of the group and the forecast. You will see a big variety of different kite spots, or maybe we will stay at one spot all week because the wind is good there. From flat water lagoons to beautiful mountain lakes or just a rough session on the North Sea, there are so many options! Of course, during the trip, there is always room for input, and we will work out the perfect plan together with you.",
                    du: "We zullen de beste kitespots kiezen op basis van de niveaus van de groep en de voorspelling. Je zult een grote verscheidenheid aan verschillende kitespots zien, of misschien blijven we de hele week op één plek omdat de wind daar goed is. Van vlakke waterlagunes tot prachtige bergmeren of gewoon een ruige sessie op de Noordzee, er zijn zoveel opties! Natuurlijk is er tijdens de reis altijd ruimte voor inbreng, en we zullen samen met jou het perfecte plan uitwerken.",
                    gr: "Wir werden die besten Kitespots entsprechend den Fähigkeiten der Gruppe und der Vorhersage auswählen. Du wirst eine große Vielfalt an verschiedenen Kitespots sehen, oder vielleicht bleiben wir die ganze Woche an einem Spot, weil der Wind dort gut ist. Von flachen Wasserlagunen bis zu schönen Bergseen oder einfach einer rauen Session auf der Nordsee, es gibt so viele Optionen! Natürlich gibt es während der Reise immer Raum für Input, und wir werden den perfekten Plan gemeinsam mit dir ausarbeiten."
                },
                imgPosition: 'left',
            },
        ],
        packages: {
            levelsData: [
                {
                    levelName: 'Level 1 - Beginner',
                    desc: {
                        en: 'This is the perfect package if you have no experience with kitesurfing and want to learn this beautiful sport. Kitesurfing is not a sport that you can just learn in 1 afternoon. That’s why our trips are perfect! You are at a perfect destination with ideal conditions for a week with people who also want to learn as much as possible. Hey ho, let’s go!',
                        du: 'Dit is het perfecte pakket als je geen ervaring hebt met kitesurfen en deze mooie sport wilt leren. Kitesurfen is geen sport die je in 1 middag kunt leren. Daarom zijn onze reizen perfect! Je bevindt je op een perfecte bestemming met ideale omstandigheden voor een week samen met mensen die ook zoveel mogelijk willen leren. Hey ho, laten we gaan!',
                        gr: 'Dies ist das perfekte Paket, wenn du keine Erfahrung mit Kitesurfen hast und diesen schönen Sport erlernen möchtest. Kitesurfen ist kein Sport, den man in einem Nachmittag erlernen kann. Deshalb sind unsere Reisen perfekt! Du bist an einem perfekten Reiseziel mit idealen Bedingungen für eine Woche mit Menschen, die ebenfalls so viel wie möglich lernen wollen. Hey ho, los geht’s!'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Designed for beginners',
                                du: 'Ontworpen voor beginners',
                                gr: 'Für Anfänger entwickelt'
                            },
                            para: {
                                en: 'This Level 1 – Beginner package is the ideal start to your kitesurfing career. We will help you get started and ensure that you can get on the water independently as a real kiter under good conditions, safely, and with great pleasure. A complete kite training in 1 week from Zero to Hero! 9 hours of lessons from our team. After the 9 hours you can practice independently with other kiters from your level for the rest of the week with our kite material. A complete package including kite lessons, (shared) equipment rental, supervision, and coaching for 6 days!',
                                du: 'Dit Level 1 - Beginner pakket is de ideale start voor je kitesurfcarrière. We helpen je op weg en zorgen ervoor dat je zelfstandig het water op kunt als een echte kiter onder goede omstandigheden, veilig en met veel plezier. Een complete kitesurftraining in 1 week van Zero tot Hero! 9 uur les van ons team. Na de 9 uur kun je de rest van de week zelfstandig oefenen met andere kiters van jouw niveau met ons kitemateriaal. Een compleet pakket inclusief kitesurfles, (gedeelde) materiaalhuur, begeleiding en coaching voor 6 dagen!',
                                gr: 'Dieses Level 1 – Anfängerpaket ist der ideale Start in deine Kitesurf-Karriere. Wir helfen dir beim Einstieg und stellen sicher, dass du unter guten Bedingungen, sicher und mit großer Freude als echter Kiter selbstständig auf dem Wasser sein kannst. Ein komplettes Kitesurf-Training in 1 Woche von Null auf Held! 9 Stunden Unterricht von unserem Team. Nach den 9 Stunden kannst du die restliche Woche mit anderen Kitern deines Levels mit unserem Kite-Material selbstständig üben. Ein komplettes Paket inklusive Kitesurf-Unterricht, (geteilte) Ausrüstungsmiete, Betreuung und Coaching für 6 Tage!'
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je van deze cursus verwachten?',
                                gr: 'Was kannst du von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'After this week you can go on the water independently with your kite set. To ensure that you make optimal progress, we teach with 1 instructor on a maximum of 2 students. You share a kite set together for the entire week so that you can help each other and learn together. Kitesurfing can be quite intensive, especially in the beginning. It is nice to be on the water together. You will get lessons from our certified instructors! Real kite professionals who have been active in kite industry for over 10 years!',
                                du: 'Na deze week kun je zelfstandig het water op met je kiteset. Om ervoor te zorgen dat je optimaal vooruitgang boekt, geven we les met 1 instructeur voor maximaal 2 studenten. Je deelt de hele week samen een kiteset zodat je elkaar kunt helpen en samen kunt leren. Kitesurfen kan behoorlijk intensief zijn, vooral in het begin. Het is fijn om samen op het water te zijn. Je krijgt les van onze gecertificeerde instructeurs! Echte kiteprofessionals die al meer dan 10 jaar actief zijn in de kite-industrie!',
                                gr: 'Nach dieser Woche kannst du mit deinem Kiteset selbstständig aufs Wasser gehen. Um sicherzustellen, dass du optimal vorankommst, unterrichten wir mit 1 Instruktor für maximal 2 Schüler. Ihr teilt euch eine Kiteset für die gesamte Woche, sodass ihr euch gegenseitig helfen und zusammen lernen könnt. Kitesurfen kann recht intensiv sein, besonders am Anfang. Es ist schön, gemeinsam auf dem Wasser zu sein. Du wirst von unseren zertifizierten Instruktoren unterrichtet! Echte Kite-Profis, die seit über 10 Jahren in der Kite-Branche tätig sind!'
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Building up your kite and knowledge about kitematerials',
                                du: 'Opbouwen van je kite en kennis over kitematerialen',
                                gr: 'Aufbau deines Kites und Wissen über Kite-Materialien'
                            },
                            info: {
                                en: 'Learn how to rig the kite, learn all about your material, bar extensions, line lengths, different systems, kite types, etc.',
                                du: 'Leer hoe je de kite opzet, leer alles over je materiaal, stangverlengingen, lijnlengtes, verschillende systemen, kitetypes, enz.',
                                gr: 'Lerne, wie man den Kite aufbaut, lerne alles über dein Material, Stangenverlängerungen, Leinenlängen, verschiedene Systeme, Kite-Typen, usw.'
                            }
                        },
                        {
                            title: {
                                en: 'Safe starting and landing',
                                du: 'Veilig starten en landen',
                                gr: 'Sicheres Starten und Landen'
                            },
                            info: {
                                en: 'Perhaps the most important thing during your course. Learn to take off/land independently and safely on the beach.',
                                du: 'Misschien wel het belangrijkste tijdens je cursus. Leer zelfstandig en veilig op het strand op te stijgen en te landen.',
                                gr: 'Vielleicht das Wichtigste während deines Kurses. Lerne, selbstständig und sicher am Strand zu starten und zu landen.'
                            }
                        }
                    ],

                    faq_head: {
                        en: "FAQ",
                        du: "Veelgestelde vragen",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'What happens after the 9 hour course?',
                                du: "Wat gebeurt er na de 9 uur durende cursus?",
                                gr: "Was passiert nach dem 9-stündigen Kurs?"
                            },
                            info: {
                                en: 'You will practice as much as possible independently, together with another kiter. The wind often blows all day at our destinations, so there is plenty of time for everyone to spend hours on the water. Our team will supervise you during your practise and give you tips where needed.',
                                du: "Je zult zoveel mogelijk zelfstandig oefenen, samen met een andere kitesurfer. De wind waait vaak de hele dag op onze bestemmingen, dus er is voldoende tijd voor iedereen om uren op het water door te brengen. Ons team zal je tijdens je oefening begeleiden en waar nodig tips geven.",
                                gr: "Du wirst so viel wie möglich unabhängig üben, zusammen mit einem anderen Kitesurfer. Der Wind weht an unseren Zielen oft den ganzen Tag, sodass jeder genug Zeit hat, Stunden auf dem Wasser zu verbringen. Unser Team wird dich während des Übens beaufsichtigen und dir bei Bedarf Tipps geben."
                            },
                        },
                        {
                            title: {
                                en: 'What happens if I have or want private lessons?',
                                du: "Wat gebeurt er als ik privélessen heb of wil?",
                                gr: "Was passiert, wenn ich Privatunterricht habe oder möchte?"
                            },
                            info: {
                                en: 'If it happens that the group lesson doesn’t suit you in terms of your level, you may receive private lessons. If you take private lessons, you will complete the course a little faster, but it is also a little more intensive. Our rule is that 7 hours of private lessons are equal to the nominal 9 hours of shared lessons. Do you really want private lessons? Please let us know in advance so that we can see what is possible.',
                                du: "Als de groepsles niet bij je past qua niveau, kun je privélessen krijgen. Als je privélessen volgt, zul je de cursus iets sneller afronden, maar het is ook iets intensiever. Onze regel is dat 7 uur privélessen gelijk zijn aan de nominale 9 uur gedeelde lessen. Wil je echt privélessen? Laat het ons van tevoren weten, zodat we kunnen kijken wat mogelijk is.",
                                gr: "Wenn es passiert, dass der Gruppenunterricht deinem Niveau nicht entspricht, kannst du Privatunterricht erhalten. Wenn du Privatunterricht nimmst, wirst du den Kurs etwas schneller abschließen, aber er ist auch etwas intensiver. Unsere Regel lautet, dass 7 Stunden Privatunterricht den nominalen 9 Stunden Gruppenunterricht entsprechen. Möchtest du wirklich Privatunterricht? Lass es uns im Voraus wissen, damit wir sehen können, was möglich ist."
                            },
                        },
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: "Professioneel team tot uw dienst",
                                gr: "Professionelles Team zu Ihren Diensten"
                            },
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            },
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: "Boek vooruitgang in ideale omstandigheden!",
                                gr: "Mache Fortschritte unter idealen Bedingungen!"
                            },
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: "Vraag advies aan reisspecialist AJ",
                                gr: "Frage AJ, den Reiseexperten, um Rat"
                            },
                        },
                    ]
                },

                {
                    levelName: 'Level 2 - Waterstart',
                    desc: {
                        en: 'This is the perfect package if you have already had a kite course, but it has been a long time ago and you need to be refreshed. Or you need an extra eye on you and some waterstart coaching. Kitesurfing is not a sport that you can learn in 1 afternoon. That’s why our trips are perfect! You are at a beginner friendly destination for a week with people who also want to learn as much as possible. Hey ho, let’s go!',
                        du: 'Dit is het perfecte pakket als je al eerder een kitesurfles hebt gehad, maar het lang geleden is en je je kennis moet opfrissen. Of je hebt een extra oog nodig op jou en wat coaching voor waterstart. Kitesurfen is geen sport die je in één middag kunt leren. Daarom zijn onze reizen perfect! Je bent een week op een beginnersvriendelijke bestemming met mensen die ook zoveel mogelijk willen leren. Hey ho, laten we gaan!',
                        gr: 'Dies ist das perfekte Paket, wenn Sie bereits einen Kite-Kurs absolviert haben, dieser aber lange zurückliegt und Sie eine Auffrischung benötigen. Oder Sie benötigen ein zusätzliches Auge auf sich und ein bisschen Waterstart-Coaching. Kitesurfen ist kein Sport, den man an einem Nachmittag erlernen kann. Deshalb sind unsere Reisen perfekt! Sie sind eine Woche lang an einem anfängerfreundlichen Zielort mit Leuten, die ebenfalls so viel wie möglich lernen wollen. Hey ho, los geht’s!'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'A lot of practise with supervision',
                                du: 'Veel oefenen onder toezicht',
                                gr: 'Viel Übung mit Aufsicht'
                            },
                            para: {
                                en: 'This intermediate package is the ideal package for kiters who need to get on board. We will help you get started and ensure that you can get on the water independently as a real kiter under good conditions, safely, and with great pleasure. A complete kite training to make you a real independent kiter in 1 week!',
                                du: 'Dit tussentijdse pakket is het ideale pakket voor kiters die aan boord moeten komen. We helpen je op weg en zorgen ervoor dat je onder goede omstandigheden zelfstandig op het water kunt, veilig en met veel plezier. Een complete kitesurftraining om je in 1 week tot een echte zelfstandige kiter te maken!',
                                gr: 'Dieses Fortgeschrittenen-Paket ist ideal für Kiter, die auf das Brett kommen müssen. Wir helfen Ihnen beim Einstieg und stellen sicher, dass Sie unter guten Bedingungen, sicher und mit großem Vergnügen unabhängig auf dem Wasser kiten können. Ein vollständiges Kitesurf-Training, das Sie in einer Woche zu einem echten, unabhängigen Kiter macht!'
                            },
                            imgPosition: 'left',
                        },
                        {
                            imgSrc: tab2_2,
                            text: {
                                en: 'What do you learn?',
                                du: 'Wat leer je?',
                                gr: 'Was lernst du?'
                            },
                            para: {
                                en: 'After this week you can go on the water independently with your own kite set and the goal is that you can kite upwind so that you no longer have to walk! Our instructors ensure that you are in the right place with the right conditions, help you with tips and tricks, coach you, and keep an eye on things while you are practicing.',
                                du: 'Na deze week kun je zelfstandig met je eigen kiteset het water op en is het doel dat je tegen de wind in kunt kiten, zodat je niet meer hoeft te lopen! Onze instructeurs zorgen ervoor dat je op de juiste plek met de juiste omstandigheden bent, helpen je met tips en trucs, coachen je en houden toezicht terwijl je oefent.',
                                gr: 'Nach dieser Woche können Sie mit Ihrem eigenen Kiteset unabhängig aufs Wasser gehen und das Ziel ist es, gegen den Wind zu kiten, sodass Sie nicht mehr laufen müssen! Unsere Instruktoren sorgen dafür, dass Sie am richtigen Ort unter den richtigen Bedingungen sind, helfen Ihnen mit Tipps und Tricks, coachen Sie und behalten während des Trainings alles im Auge.'
                            },
                            imgPosition: 'right',
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Good spot analysis and knowledge of kite material',
                                du: 'Goede spotanalyse en kennis van kitemateriaal',
                                gr: 'Gute Spot-Analyse und Wissen über Kite-Material'
                            },
                            info: {
                                en: 'Learn everything you need to know about the spot, forecast and your kite material',
                                du: 'Leer alles wat je moet weten over de spot, de weersvoorspelling en je kitemateriaal',
                                gr: 'Lernen Sie alles, was Sie über den Spot, die Vorhersage und Ihr Kite-Material wissen müssen'
                            },
                        },
                        {
                            title: {
                                en: 'Refresh your safety skills',
                                du: 'Vernieuw je veiligheidsvaardigheden',
                                gr: 'Auffrischen deiner Sicherheitskenntnisse'
                            },
                            info: {
                                en: 'Do you know how to do a self rescue?',
                                du: 'Weet jij hoe je een zelfredding uitvoert?',
                                gr: 'Weißt du, wie du eine Selbstrettung durchführst?'
                            },
                        },
                        {
                            title: {
                                en: 'Safe starting and landing',
                                du: 'Veilig starten en landen',
                                gr: 'Sicheres Starten und Landen'
                            },
                            info: {
                                en: 'The most important about your kite career!',
                                du: 'Het belangrijkste over je kitecarrière!',
                                gr: 'Das Wichtigste für deine Kite-Karriere!'
                            },
                        },
                        {
                            title: {
                                en: 'Theory and Rules',
                                du: 'Theorie en regels',
                                gr: 'Theorie und Regeln'
                            },
                            info: {
                                en: 'Everything you need to know',
                                du: 'Alles wat je moet weten',
                                gr: 'Alles, was du wissen musst'
                            },
                        },
                        {
                            title: {
                                en: 'Waterstart',
                                du: 'Waterstart',
                                gr: 'Wasserstart'
                            },
                            info: {
                                en: 'How do you perform the perfect waterstart?',
                                du: 'Hoe voer je de perfecte waterstart uit?',
                                gr: 'Wie führst du den perfekten Wasserstart aus?'
                            },
                        },
                        {
                            title: {
                                en: 'Upwind riding and turns!',
                                du: 'Upwind rijden en bochten!',
                                gr: 'Aufwindfahren und Wendungen!'
                            },
                            info: {
                                en: 'When riding upwind, you are finally an independent kiter!',
                                du: 'Bij upwind rijden ben je eindelijk een zelfstandige kiter!',
                                gr: 'Beim Aufwindfahren bist du endlich ein unabhängiger Kiter!'
                            },
                        }
                    ],
                    faq_head: "FAQ",
                    faq_data: [
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: 'Is kitemateriaal inbegrepen in dit pakket?',
                                gr: 'Ist Kite-Ausrüstung in diesem Paket enthalten?'
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 399 / week',
                                du: 'Nee, je moet je eigen kitemateriaal meenemen of een complete set huren voor € 399 / week',
                                gr: 'Nein, du musst deine eigene Kite-Ausrüstung mitbringen oder ein komplettes Set für 399 €/Woche mieten'
                            },
                        },
                        {
                            title: {
                                en: 'What happens if my level doesn’t match with this course?',
                                du: 'Wat gebeurt er als mijn niveau niet overeenkomt met deze cursus?',
                                gr: 'Was passiert, wenn mein Niveau nicht zu diesem Kurs passt?'
                            },
                            info: {
                                en: 'One person picks it up faster than the other and that is really no problem at all. If we see that you need extra lessons after the 9 hours, our instructor will take you aside and discuss with you how many hours he thinks you still need. Depending on whether other people in the group need extra lessons, the extra costs are: €60/hour for private lessons and €35/hour for shared lessons. You can also decide to exchange your rental for the rest of the week for 3 hours of extra lessons so that it does not cost you anything extra that week.',
                                du: 'De één pakt het sneller op dan de ander en dat is echt geen probleem. Als we zien dat je na de 9 uur extra lessen nodig hebt, zal onze instructeur je apart nemen en met je bespreken hoeveel uren hij denkt dat je nog nodig hebt. Afhankelijk van of andere mensen in de groep extra lessen nodig hebben, zijn de extra kosten: €60/uur voor privelessen en €35/uur voor gedeelde lessen. Je kunt er ook voor kiezen om je huur voor de rest van de week in te wisselen voor 3 uur extra lessen, zodat het je die week niets extra kost.',
                                gr: 'Die eine Person lernt schneller als die andere, und das ist überhaupt kein Problem. Wenn wir sehen, dass du nach den 9 Stunden noch zusätzliche Lektionen brauchst, wird unser Instruktor dich beiseite nehmen und mit dir besprechen, wie viele Stunden er denkt, dass du noch benötigst. Abhängig davon, ob andere Personen in der Gruppe zusätzliche Lektionen benötigen, betragen die zusätzlichen Kosten: 60 €/Stunde für Einzelunterricht und 35 €/Stunde für Gruppenunterricht. Du kannst dich auch dafür entscheiden, deine Miete für den Rest der Woche gegen 3 Stunden zusätzlichen Unterricht einzutauschen, sodass dich das in dieser Woche nichts extra kostet.'
                            },
                        }
                    ],

                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: "Professioneel team tot uw dienst",
                                gr: "Professionelles Team zu Ihren Diensten"
                            },
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            },
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: "Boek vooruitgang in ideale omstandigheden!",
                                gr: "Mache Fortschritte unter idealen Bedingungen!"
                            },
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: "Vraag advies aan reisspecialist AJ",
                                gr: "Frage AJ, den Reiseexperten, um Rat"
                            },
                        },
                    ]




                },

                {
                    levelName: 'Level 3 - Independend',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go!',
                        du: "",
                        gr: ""
                    },
                    imgsect: [
                        {
                            imgSrc: tab3_1,
                            text: {
                                en: 'Learn new tricks',
                                du: "",
                                gr: ""
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: "",
                                gr: ""
                            },
                            imgPosition: 'left',
                        },
                        {
                            imgSrc: tab3_2,
                            text: {
                                en: 'What to expect from this course',
                                du: "",
                                gr: ""
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: "",
                                gr: ""
                            },
                            imgPosition: 'right',
                        },
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: "",
                        gr: ""
                    },
                    acordation_data: [
                        {
                            title: {
                                en: '  Learn the basics of pop ',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: ' Learn how to pop. This is an very important skill which you need for your entire kite career!',
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            title: {
                                en: '  Your first jump   ',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: '  You want to learn how to go skyhigh?  ',
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            title: {
                                en: ' Back & Frontroll  ',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: ' Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!   ',
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            title: {
                                en: '  board grabs, onefooters and board-offs ',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: '  Grab the board in front of the camera!  ',
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            title: {
                                en: ' Your first kiteloop (downloop)  ',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: '  Fly like an rollercoaster :)  ',
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            title: {
                                en: ' Your first downwinder  ',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: '   With a downwinder your are doing a kite tour along the coast! ',
                                du: "",
                                gr: ""
                            },
                        },
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "",
                        gr: ""
                    },
                    faq_data: [
                        {
                            title: {
                                en: '  How many hours include this course? ',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: 'We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.    ',
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            title: {
                                en: '   Is kitegear included in this package?',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: ' No, you need to bring your own kitegear or rent a complete set at € 399 / week   ',
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            title: {
                                en: '  Am I Insured? ',
                                du: "",
                                gr: ""
                            },
                            info: {
                                en: '  You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!  ',
                                du: "",
                                gr: ""
                            },
                        },
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: "",
                                gr: ""
                            },
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: "",
                                gr: ""
                            },
                        },
                    ]
                },
            ],
            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "",
                    gr: ""
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "",
                    gr: ""
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "",
                    gr: ""
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "",
                    gr: ""
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Make sure you get your insurance in order if you break your own or our material",
                    du: "",
                    gr: ""
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "",
                    gr: ""
                }
            }

        },
        accommodation: {
            main_title: {
                en: "A real #VanLife experience with us!",
                gr: "",
                du: ""
            },
            main_desc: {
                en: "There is nothing better than sleeping in your van directly at the kite spots. The ultimate feeling of freedom is waking up with a view of the waves, checking the forecast, baking an omelet, and rocking it out all day with us on the water! We have something for every price category! At KiteActive, we love building vans. In the meantime, we have built an amazing VanLife car park, perfect for your next kitesurfing camper roadtrip, and we are more than happy to help you find the right fit for your trip! Do you have your own van? Amazing! Don't have a van? No problem! We have everything for you, from a budget-friendly Caddy to the Lord (a luxury camper for 4-6 people). You can also always join with your own (day-to-day) car and we will find a place for your gear",
                gr: "",
                du: ""
            },
            card_details: [
                {
                    imgSrc: van_hotel_img1,
                    title: {
                        en: "SHARED ROOM",
                        gr: "",
                        du: ""
                    },
                    description: {
                        en: "Sleep in a room with a maximum of 2 other kiters. We try to keep ladies and gentlemen separate.",
                        gr: "",
                        du: ""
                    }
                },
                {
                    imgSrc: van_hotel_img2,
                    title: {
                        en: "PRIVATE ROOM",
                        gr: "",
                        du: ""
                    },
                    description: {
                        en: "Would you like to enjoy a little more privacy? Book a private room for a surcharge of €30/night.",
                        gr: "",
                        du: ""
                    }
                },
                {
                    imgSrc: van_hotel_img3,
                    title: {
                        en: "DELUXE SUITE",
                        gr: "",
                        du: ""
                    },
                    description: {
                        en: "You can share a room with your friend at no extra cost. Please let us know when you make your booking.",
                        gr: "",
                        du: ""
                    }
                },
                {
                    imgSrc: van_hotel_img4,
                    title: {
                        en: "ROOFTOP TERRACE",
                        gr: "",
                        du: ""
                    },
                    description: {
                        en: "Sleep in a room with a maximum of 2 other kiters. We try to keep ladies and gentlemen separate.",
                        gr: "",
                        du: ""
                    }
                },
                {
                    imgSrc: van_hotel_img5,
                    title: {
                        en: "KITE SERVICE IN OUR CENTER",
                        gr: "",
                        du: ""
                    },
                    description: {
                        en: "Would you like to enjoy a little more privacy? Book a private room for a surcharge of €30/night.",
                        gr: "",
                        du: ""
                    }
                },
                {
                    imgSrc: van_hotel_img6,
                    title: {
                        en: "PERFECT LOCATION",
                        gr: "",
                        du: ""
                    },
                    description: {
                        en: "You can share a room with your friend at no extra cost. Please let us know when you make your booking..",
                        gr: "",
                        du: ""
                    }
                }
            ],
            location_title: {
                en: "How to get here",
                gr: "",
                du: ""
            },
            location_subtitle: {
                en: "The Caribbean of Africa",
                gr: "",
                du: ""
            },
            location_desc: {
                en: "We proudly present you our own KiteActive Hotel on the beach in Paje. The ideal set-up for kite surfers. The atmosphere and our team ensure a top stay with everything you need within reach!",
                gr: "",
                du: ""
            }
        },

        reviews: {
            h2: {
                en: "location / vanlife",
                gr: "Nither location / vanlife",
                du: "Dutch location / vanlife"
            }
        },

        hosted: [
            {
                hosted_img: hosted_img1,
                hosted_name: {
                    en: "Roderick Pijls",
                    gr: "Roderick Pijls",
                    du: "Roderick Pijls"
                },
                hosted_position: {
                    en: "",
                    gr: "",
                    du: ""
                },
                kite_exp: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_para: {
                    en: "Having a background of professional kitesurfer for over 12 years has taught me a lot. Seeing many cultures, meeting great people and knowing everything from the best spots in the world has convinced me to start sharing this with everyone who has that little 'Columbus' inside him/ herself.",
                    gr: "Mit einer mehr als 12-jährigen Erfahrung als professioneller Kitesurfer habe ich viel gelernt. Die Begegnung mit vielen Kulturen, großartigen Menschen und das Wissen um die besten Spots der Welt haben mich überzeugt, dies mit jedem zu teilen, der ein kleines 'Kolumbus' in sich trägt.",
                    du: "Met meer dan 12 jaar ervaring als professionele kitesurfer heb ik veel geleerd. Het zien van veel culturen, het ontmoeten van geweldige mensen en het kennen van de beste spots ter wereld heeft me overtuigd om dit te delen met iedereen die dat kleine 'Columbus' in zichzelf heeft."
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "",
                    gr: "",
                    du: ""
                }
            },
            {
                hosted_img: hosted_img2,
                hosted_name: {
                    en: "Felix Maks",
                    gr: "Felix Maks",
                    du: "Felix Maks"
                },
                hosted_position: {
                    en: "Camp manager & Instructor",
                    gr: "Campleiter & Ausbilder",
                    du: "Campmanager & Instructeur"
                },
                kite_exp: {
                    en: "16 years kite experience",
                    gr: "16 Jahre Kiterfahrung",
                    du: "16 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "After competing in the world tour I decided that I wanted to share my passion with other people. Now we travel the world and enjoy the ride!",
                    gr: "Nach meiner Teilnahme an der Weltmeisterschaft entschied ich, dass ich meine Leidenschaft mit anderen Menschen teilen wollte. Jetzt reisen wir um die Welt und genießen die Fahrt!",
                    du: "Na mijn deelname aan de wereldtour besloot ik dat ik mijn passie met andere mensen wilde delen. Nu reizen we de wereld rond en genieten we van de rit!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favorite Destination",
                    gr: "Lieblingsziel",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "Zanzibar and the Dutch islands",
                    gr: "Zanzibar und die niederländischen Inseln",
                    du: "Zanzibar en de Nederlandse eilanden"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "16 years",
                    gr: "16 Jahre",
                    du: "16 jaar"
                }
            },
        ],

        // reviews: {} 

    },




    // Zanzibar trip data ......................
    {
        name: 'Zanzibar',
        vedio: Zanzibar_vedio,
        backcover: zanzibarcover,
        alltabs: {
            highlight:[
                {
                    text: {
                        en: "Stay at our beachside KiteActive hotel, just steps from the best kitespots.The perfect beginner spot with huge lagoon and shallow water",
                        gr: "Übernachten Sie in unserem KiteActive-Hotel am Strand, nur wenige Schritte von den besten Kitespots entfernt. Der perfekte Anfängerort mit großer Lagune und flachem Wasser.",
                        du: "Verblijf in ons KiteActive hotel aan het strand, op slechts een paar stappen van de beste kitespots. De perfecte beginnersplek met een grote lagune en ondiep water."
                    }
                },
                {
                    text: {
                        en: "Expert Guidance: Learn or progress with our experienced instructors in a stunning, safe environment.",
                        gr: "Expertenanleitung: Lernen oder entwickeln Sie sich mit unseren erfahrenen Ausbildern in einer atemberaubenden und sicheren Umgebung weiter.",
                        du: "Deskundige begeleiding: Leer of maak vooruitgang met onze ervaren instructeurs in een prachtige, veilige omgeving."
                    }
                },
                {
                    text: {
                        en: "Community Vibes Connect with like-minded kiters and make lifelong friends on an adventure-filled holiday.",
                        gr: "Gemeinschaftsgefühl: Verbinden Sie sich mit Gleichgesinnten und schließen Sie lebenslange Freundschaften auf einem abenteuerreichen Urlaub.",
                        du: "Gemeenschapsgevoel: Maak contact met gelijkgestemde kiters en maak levenslange vrienden tijdens een avontuurlijke vakantie."
                    }
                },
                {
                    text: {
                        en: "Seamless Experience We take care of everything—from accommodation to daily activities—so you can focus on kiting and having fun!",
                        gr: "Nahtloses Erlebnis: Wir kümmern uns um alles – von der Unterkunft bis zu den täglichen Aktivitäten – sodass Sie sich auf das Kiten und Spaß haben konzentrieren können!",
                        du: "Naadloze ervaring: Wij zorgen voor alles - van accommodatie tot dagelijkse activiteiten - zodat je je kunt concentreren op kiten en plezier hebben!"
                    }
                }
            ],
            question: [
                {
                    "title": {
                        "en": "What equipment should I take with me to Zanzibar?",
                        "gr": "Welche Ausrüstung sollte ich nach Sansibar mitnehmen?",
                        "du": "Welke uitrusting moet ik meenemen naar Zanzibar?"
                    },
                    "para": {
                        "en": "Always try to take large sizes with you and, if you can choose, a larger board. The ideal kite set for an average weight of 75kg is a 15-12-9 m2 with a 140 board. Skimboards, strapless boards and foils are really cool toys in Zanzibar!",
                        "gr": "Versuchen Sie immer, große Größen mitzunehmen, und wenn Sie die Wahl haben, ein größeres Board. Das ideale Kite-Set für ein durchschnittliches Gewicht von 75 kg besteht aus 15-12-9 m2 mit einem 140er Board. Skimboards, strapless Boards und Foils sind wirklich coole Spielzeuge auf Sansibar!",
                        "du": "Probeer altijd grote maten mee te nemen en, indien mogelijk, een groter board. Het ideale kiteset voor een gemiddeld gewicht van 75 kg is 15-12-9 m2 met een 140 board. Skimboards, strapless boards en foils zijn echt coole speeltjes op Zanzibar!"
                    }
                },
                {
                    "title": {
                        "en": "What is the wind like in Zanzibar?",
                        "gr": "Wie ist der Wind auf Sansibar?",
                        "du": "Hoe is de wind op Zanzibar?"
                    },
                    "para": {
                        "en": "The wind on Zanzibar is a much discussed topic in the kite world. The wind seasons are from June to September and from December to early March. Every season is different on Zanzibar, we have experienced seasons when you were working hard every day on your 9m2, but also seasons when we had no wind for a number of days or only for large kites (14m2 +). It remains a sport that depends on mother nature, but in general you will certainly enjoy jet kitesurfing!!!!!",
                        "gr": "Der Wind auf Sansibar ist ein viel diskutiertes Thema in der Kiteszene. Die Windsaisons dauern von Juni bis September und von Dezember bis Anfang März. Jede Saison ist anders auf Sansibar; wir haben Saisons erlebt, in denen man täglich mit einem 9m2-Schirm unterwegs war, aber auch Saisons, in denen es mehrere Tage lang keinen Wind gab oder nur für große Schirme (14m2 +). Es bleibt ein Sport, der von Mutter Natur abhängt, aber im Allgemeinen wird man das Kitesurfen genießen!",
                        "du": "De wind op Zanzibar is een veelbesproken onderwerp in de kitescene. De windseizoenen zijn van juni tot september en van december tot begin maart. Elk seizoen is anders op Zanzibar; we hebben seizoenen meegemaakt waarin je elke dag met je 9m2 aan het werk was, maar ook seizoenen waarin er een aantal dagen geen wind was of alleen voor grote kites (14m2+). Het blijft een sport die afhankelijk is van moeder natuur, maar over het algemeen zul je zeker genieten van het kitesurfen!"
                    }
                },
                {
                    "title": {
                        "en": "Are vaccinations necessary?",
                        "gr": "Sind Impfungen notwendig?",
                        "du": "Zijn vaccinaties noodzakelijk?"
                    },
                    "para": {
                        "en": "Make sure you have completed all vaccinations with your doctor. If you come from the EU, a yellow fever vaccine is not mandatory, but it is if you come from other destinations. Regardless of length of stay, the following vaccinations are recommended for Tanzania: vaccination against DTP (Diphtheria, Tetanus and Polio), vaccination against hepatitis A (contagious jaundice) and a yellow fever vaccination. If you have not had measles or a measles vaccination, an MMR vaccination is recommended for Tanzania. tip: Make an appointment early with the local GGD or GP (some vaccinations need a week before they work). More information can be found athttp://www.vaccinatiesopreis.nl/inentingen-tanzania/",
                        "gr": "Stellen Sie sicher, dass alle Impfungen bei Ihrem Arzt abgeschlossen sind. Wenn Sie aus der EU kommen, ist eine Gelbfieberimpfung nicht obligatorisch, es sei denn, Sie kommen aus anderen Zielen. Unabhängig von der Aufenthaltsdauer werden für Tansania folgende Impfungen empfohlen: Impfung gegen DTP (Diphtherie, Tetanus und Polio), Impfung gegen Hepatitis A (infektiöse Gelbsucht) und Gelbfieberimpfung. Wenn Sie keine Masern hatten oder keine Masernimpfung, wird eine MMR-Impfung für Tansania empfohlen. Tipp: Vereinbaren Sie frühzeitig einen Termin mit dem örtlichen Gesundheitsamt oder Hausarzt (einige Impfungen benötigen eine Woche, bevor sie wirken). Weitere Informationen finden Sie unter http://www.vaccinatiesopreis.nl/inentingen-tanzania/",
                        "du": "Zorg ervoor dat u alle vaccinaties bij uw arts heeft voltooid. Als u uit de EU komt, is een vaccinatie tegen gele koorts niet verplicht, tenzij u uit andere bestemmingen komt. Ongeacht de verblijfsduur worden de volgende vaccinaties voor Tanzania aanbevolen: vaccinatie tegen DTP (difterie, tetanus en polio), vaccinatie tegen hepatitis A (besmettelijke geelzucht) en een vaccinatie tegen gele koorts. Als u geen mazelen heeft gehad of geen mazelenvaccinatie, wordt een BMR-vaccinatie voor Tanzania aanbevolen. Tip: Maak tijdig een afspraak bij de lokale GGD of huisarts (sommige vaccinaties hebben een week nodig voordat ze werken). Meer informatie vindt u op http://www.vaccinatiesopreis.nl/inentingen-tanzania/"
                    }
                }
            ]
        },

        included :{},


        overview: {
            trip_heading: {
                en: "Kitesurf heaven on earth",
                gr: "Kitesurf-Paradies auf Erden",
                du: "Kitesurf hemel op aarde"
            },
            trip_para: {
                en: "Discover Africa with KiteActive during a kitesurf Zanzibar holiday. A tropical island in the middle of the azure-blue Indian Ocean, Zanzibar kitesurf holiday is a world-class kite spot. Mega is accessible for all levels with wonderful, warm, knee-deep water and a beautiful reef outside the lagoon with fantastic waves. Since 2015, KiteActive has had its own kitesurfing hotel and kite school on the beach. We can recommend this wonderful place with its friendly locals to everyone. Hakuna Matata!",
                gr: "Entdecke Afrika mit KiteActive während eines Kitesurf-Urlaubs auf Sansibar. Eine tropische Insel mitten im azurblauen Indischen Ozean, der Kitesurf-Urlaub auf Sansibar ist ein erstklassiger Kite-Spot. Mega ist für alle Niveaus zugänglich, mit wunderbarem, warmem, knietiefem Wasser und einem wunderschönen Riff außerhalb der Lagune mit fantastischen Wellen. Seit 2015 hat KiteActive sein eigenes Kitesurf-Hotel und seine Kiteschule am Strand. Wir können diesen wunderbaren Ort mit seinen freundlichen Einheimischen jedem empfehlen. Hakuna Matata!",
                du: "Ontdek Afrika met KiteActive tijdens een kitesurfvakantie in Zanzibar. Een tropisch eiland midden in de azuurblauwe Indische Oceaan, Zanzibar kitesurf vakantie is een wereldklasse kitespot. Mega is toegankelijk voor alle niveaus met prachtig, warm, kniediep water en een prachtig rif buiten de lagune met fantastische golven. Sinds 2015 heeft KiteActive een eigen kitesurfschool en kitesurfhotel aan het strand. We kunnen deze prachtige plek met zijn vriendelijke lokale bevolking aan iedereen aanraden. Hakuna Matata!"
            },
            img1: surf_img1,
            img1_head: {
                en: "Early bird discount",
                gr: "Frühbucherrabatt",
                du: "Vroegboekkorting"
            },
            img1_para: {
                en: "Book before 1 November and get 10% discount",
                gr: "Buche vor dem 1. November und erhalte 10% Rabatt",
                du: "Boek voor 1 november en ontvang 10% korting"
            },
            img2: surf_img2,
            img2_head: {
                en: "Join our community",
                gr: "Werde Teil unserer Community",
                du: "Word lid van onze gemeenschap"
            },
            img2_para: {
                en: "7-15 kiters / week Suited for: couples, solo, family, singles",
                gr: "7-15 Kiter / Woche Geeignet für: Paare, Alleinreisende, Familien, Singles",
                du: "7-15 kiters / week Geschikt voor: koppels, solo, familie, singles"
            },
            img3: surf_img3,
            img3_head: {
                en: "Surf skill",
                gr: "Surffähigkeiten",
                du: "Surfniveau"
            },
            img3_para: {
                en: "Suited for all levels. Especially Level 1 - beginners!",
                gr: "Geeignet für alle Niveaus. Besonders Level 1 - Anfänger!",
                du: "Geschikt voor alle niveaus. Vooral Niveau 1 - beginners!"
            },
            img4: surf_img4,
            img4_head: {
                en: "Only stars",
                gr: "Nur Sterne",
                du: "Alleen sterren"
            },
            img4_para: {
                en: "Read the reviews of our travellers",
                gr: "Lies die Bewertungen unserer Reisenden",
                du: "Lees de beoordelingen van onze reizigers"
            },
            gall_img1: zan_OW_gall_img1,
            gall_img2: zan_OW_gall_img2,
            gall_img3: zan_OW_gall_img3,
            gall_img4: zan_OW_gall_img4,
        },

        kitespot: [
            {
                imgSrc: zan_KS_img1,
                text: {
                    en: 'location / kite spot',
                    du: 'locatie / kitespot',
                    gr: 'Ort / Kitespot'
                },
                heading: {
                    en: 'feel the warm wind and kite in your board shorts',
                    du: 'voel de warme wind en kite in je boardshorts',
                    gr: 'spüre den warmen Wind und kite in deiner Badehose'
                },
                para: {
                    en: 'The gigantic lagoon on our doorstep is a world-class kite spot. Downwinders along the reef, where we race with the dolphins. Wavekiting on the best waves in Zanzibar. Trick clinics in the most beautiful lagoons. Kilometers of long, snow-white palm beaches. Discover the surprises of the tropical kitesurfing island of Zanzibar.',
                    du: 'De gigantische lagune voor onze deur is een kitespot van wereldklasse. Downwinders langs het rif, waar we racen met de dolfijnen. Golfrijden op de beste golven van Zanzibar. Trucclinics in de mooiste lagunes. Kilometers lange, sneeuwwitte palmenstranden. Ontdek de verrassingen van het tropische kitesurfeiland Zanzibar.',
                    gr: 'Die gigantische Lagune direkt vor unserer Haustür ist ein Kitespot von Weltklasse. Downwinders entlang des Riffs, wo wir mit den Delfinen Rennen fahren. Wellenreiten auf den besten Wellen Sansibars. Trick-Kliniken in den schönsten Lagunen. Kilometerlange, schneeweiße Palmenstrände. Entdecke die Überraschungen der tropischen Kitesurf-Insel Sansibar.'
                },
                imgPosition: 'right',
            },

            {
                imgSrc: zan_KS_img2,
                text: {
                    en: 'The WIND',
                    du: 'De WIND',
                    gr: 'Der WIND'
                },
                heading: {
                    en: 'feel the warm wind and kite in your board shorts',
                    du: 'voel de warme wind en kite in je boardshorts',
                    gr: 'spüre den warmen Wind und kite in deiner Badehose'
                },
                para: {
                    en: "The gigantic lagoon on our doorstep is a world-class kite spot. Downwinders along the reef, where we race with the dolphins. Wavekiting on the best waves in Zanzibar. Trick clinics in the most beautiful lagoons. Kilometers of long, snow-white palm beaches. Discover the surprises of the tropical kitesurfing island of Zanzibar.",
                    du: 'De gigantische lagune voor onze deur is een kitespot van wereldklasse. Downwinders langs het rif, waar we racen met de dolfijnen. Golfrijden op de beste golven van Zanzibar. Trucclinics in de mooiste lagunes. Kilometers lange, sneeuwwitte palmenstranden. Ontdek de verrassingen van het tropische kitesurfeiland Zanzibar.',
                    gr: 'Die gigantische Lagune direkt vor unserer Haustür ist ein Kitespot von Weltklasse. Downwinders entlang des Riffs, wo wir mit den Delfinen Rennen fahren. Wellenreiten auf den besten Wellen Sansibars. Trick-Kliniken in den schönsten Lagunen. Kilometerlange, schneeweiße Palmenstrände. Entdecke die Überraschungen der tropischen Kitesurf-Insel Sansibar.'
                },
                imgPosition: 'left',
            },

            {
                imgSrc: zan_KS_img3,
                text: {
                    en: 'weather & wind',
                    du: 'weer & wind',
                    gr: 'Wetter & Wind'
                },
                heading: {
                    en: 'feel the warm wind and kite in your board shorts',
                    du: 'voel de warme wind en kite in je boardshorts',
                    gr: 'spüre den warmen Wind und kite in deiner Badehose'
                },
                para: {
                    en: "The gigantic lagoon on our doorstep is a world-class kite spot. Downwinders along the reef, where we race with the dolphins. Wavekiting on the best waves in Zanzibar. Trick clinics in the most beautiful lagoons. Kilometers of long, snow-white palm beaches. Discover the surprises of the tropical kitesurfing island of Zanzibar.",
                    du: 'De gigantische lagune voor onze deur is een kitespot van wereldklasse. Downwinders langs het rif, waar we racen met de dolfijnen. Golfrijden op de beste golven van Zanzibar. Trucclinics in de mooiste lagunes. Kilometers lange, sneeuwwitte palmenstranden. Ontdek de verrassingen van het tropische kitesurfeiland Zanzibar.',
                    gr: 'Die gigantische Lagune direkt vor unserer Haustür ist ein Kitespot von Weltklasse. Downwinders entlang des Riffs, wo wir mit den Delfinen Rennen fahren. Wellenreiten auf den besten Wellen Sansibars. Trick-Kliniken in den schönsten Lagunen. Kilometerlange, schneeweiße Palmenstrände. Entdecke die Überraschungen der tropischen Kitesurf-Insel Sansibar.'
                },
                imgPosition: 'right',
            }
        ],

        packages: {
            levelsData: [
                {
                    levelName: 'Level 1 - Beginner',
                    desc: {
                        en: 'This is the perfect package if you have no experience with kitesurfing and want to learn this beautiful sport. Kitesurfing is not a sport that you can just learn in 1 afternoon. That’s why our trips are perfect! You are at a perfect destination with ideal conditions for a week with people who also want to learn as much as possible. Hey ho, let’s go!',
                        du: 'Dit is het perfecte pakket als je geen ervaring hebt met kitesurfen en deze prachtige sport wilt leren. Kitesurfen is geen sport die je in een middag kunt leren. Daarom zijn onze reizen perfect! Je bevindt je op een perfecte bestemming met ideale omstandigheden voor een week samen met mensen die ook zoveel mogelijk willen leren. Hey ho, laten we gaan!',
                        gr: 'Dies ist das perfekte Paket, wenn Sie keine Erfahrung mit dem Kitesurfen haben und diesen schönen Sport erlernen möchten. Kitesurfen ist kein Sport, den man an einem Nachmittag lernen kann. Deshalb sind unsere Reisen perfekt! Sie sind an einem perfekten Ort mit idealen Bedingungen für eine Woche mit Leuten, die ebenfalls so viel wie möglich lernen möchten. Hey ho, los geht’s!'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Designed for beginners',
                                du: 'Ontworpen voor beginners',
                                gr: 'Entworfen für Anfänger'
                            },
                            para: {
                                en: 'This Level 1 – Beginner package is the ideal start to your kitesurfing career. We will help you get started and ensure that you can get on the water independently as a real kiter under good conditions, safely, and with great pleasure. A complete kite training in 1 week from Zero to Hero! 9 hours of lessons from our team. After the 9 hours you can practice independently with other kiters from your level for the rest of the week with our kite material. A complete package including kite lessons, (shared) equipment rental, supervision, and coaching for 6 days!',
                                du: 'Dit Level 1 – Beginner pakket is het ideale begin van je kitesurfcarrière. We helpen je op weg en zorgen ervoor dat je onder goede omstandigheden zelfstandig het water op kunt, veilig en met veel plezier. Een complete kitesurftraining in 1 week van Zero tot Hero! 9 uur les van ons team. Na de 9 uur kun je de rest van de week zelfstandig oefenen met andere kiters van jouw niveau met ons kitemateriaal. Een compleet pakket inclusief kitesurfles, (gedeeld) materiaalhuur, toezicht en coaching voor 6 dagen!',
                                gr: 'Dieses Level 1 – Beginner-Paket ist der ideale Start für Ihre Kitesurf-Karriere. Wir helfen Ihnen beim Einstieg und sorgen dafür, dass Sie unter guten Bedingungen, sicher und mit viel Freude, eigenständig auf das Wasser gehen können. Ein komplettes Kite-Training in 1 Woche von Zero zu Hero! 9 Stunden Unterricht von unserem Team. Nach den 9 Stunden können Sie den Rest der Woche eigenständig mit anderen Kitern Ihres Niveaus mit unserem Kite-Material üben. Ein komplettes Paket inklusive Kitesurf-Unterricht, (geteiltem) Materialverleih, Aufsicht und Coaching für 6 Tage!'
                            },
                            imgPosition: 'left',
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je verwachten van deze cursus?',
                                gr: 'Was können Sie von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'After this week you can go on the water independently with your kite set. To ensure that you make optimal progress, we teach with 1 instructor on a maximum of 2 students. You share a kite set together for the entire week so that you can help each other and learn together. Kitesurfing can be quite intensive, especially in the beginning. It is nice to be on the water together. You will get lessons from our certified instructors! Real kite professionals who have been active in kite industry for over 10 years!',
                                du: 'Na deze week kun je zelfstandig het water op met je kiteset. Om ervoor te zorgen dat je optimaal vooruitgang boekt, geven we les met 1 instructeur op maximaal 2 studenten. Je deelt de hele week een kiteset zodat je elkaar kunt helpen en samen kunt leren. Kitesurfen kan behoorlijk intensief zijn, vooral in het begin. Het is fijn om samen op het water te zijn. Je krijgt les van onze gecertificeerde instructeurs! Echte kitesurfprofessionals die al meer dan 10 jaar actief zijn in de kitesurfindustrie!',
                                gr: 'Nach dieser Woche können Sie eigenständig mit Ihrem Kite-Set aufs Wasser gehen. Um sicherzustellen, dass Sie optimal vorankommen, unterrichten wir mit 1 Ausbilder auf maximal 2 Schüler. Sie teilen sich die ganze Woche über ein Kite-Set, damit Sie sich gegenseitig helfen und zusammen lernen können. Kitesurfen kann sehr intensiv sein, besonders am Anfang. Es ist schön, zusammen auf dem Wasser zu sein. Sie werden von unseren zertifizierten Ausbildern unterrichtet! Echte Kite-Profis, die seit über 10 Jahren in der Kite-Branche tätig sind!'
                            },
                            imgPosition: 'right',
                        },
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: ' ',
                                du: 'Leer de basis van pop',
                                gr: 'Lerne die Grundlagen des Pops'
                            },
                            info: {
                                en: '  !',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitesurfcarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die Sie für Ihre gesamte Kitesurf-Karriere benötigen!'
                            },
                        },
                        // Additional repeated entries for 'acordation_data' and 'faq_data'
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "Veelgestelde vragen",
                        gr: "FAQ"
                    },
                    faq_data: [
                        {
                            title: {
                                en: ' ',
                                du: 'Leer de basis van pop',
                                gr: 'Lerne die Grundlagen des Pops'
                            },
                            info: {
                                en: '  !',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitesurfcarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die Sie für Ihre gesamte Kitesurf-Karriere benötigen!'
                            },
                        },
                        // Additional repeated entries for 'faq_data'
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: 'Professioneel team tot uw dienst',
                                gr: 'Professionelles Team zu Ihren Diensten'
                            },
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: 'Headset-coaching',
                                gr: 'Headset-Coaching'
                            },
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: 'Boek vooruitgang in ideale omstandigheden!',
                                gr: 'Machen Sie Fortschritte unter idealen Bedingungen!'
                            },
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: 'Vraag advies aan reisspecialist AJ',
                                gr: 'Fragen Sie Reiseexperte AJ um Rat'
                            },
                        },
                    ]
                },

                {
                    levelName: 'Level 2 - Waterstart',
                    desc: {
                        en: 'This is the perfect package if you have already had a kite course, but it has been a long time ago and you need to be refreshed. Or you need an extra eye on you and some waterstart coaching. Kitesurfing is not a sport that you can learn in 1 afternoon. That’s why our trips are perfect! You are at a beginner friendly destination for a week with people who also want to learn as much as possible. Hey ho, let’s go!',
                        du: 'Dit is het perfecte pakket als je al een kitesurfles hebt gevolgd, maar dat lang geleden is en je een opfrisser nodig hebt. Of als je een extra oog op je nodig hebt en wat hulp bij het starten in het water. Kitesurfen is geen sport die je in één middag kunt leren. Daarom zijn onze reizen perfect! Je bent op een bestemming die geschikt is voor beginners voor een week, samen met mensen die ook zoveel mogelijk willen leren. Hey ho, laten we gaan!',
                        gr: 'Dies ist das perfekte Paket, wenn Sie bereits einen Kitekurs absolviert haben, dies aber lange her ist und Sie eine Auffrischung benötigen. Oder Sie brauchen ein extra Auge auf sich und etwas Waterstart-Coaching. Kitesurfen ist kein Sport, den man an einem Nachmittag erlernen kann. Deshalb sind unsere Reisen perfekt! Sie befinden sich eine Woche lang an einem anfängerfreundlichen Zielort mit Menschen, die ebenfalls so viel wie möglich lernen wollen. Hey ho, los geht\'s!'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'A lot of practise with supervision',
                                du: 'Veel oefenen onder toezicht',
                                gr: 'Viel Übung unter Aufsicht'
                            },
                            para: {
                                en: 'This intermediate package is the ideal package for kiters who need to get on board. We will help you get started and ensure that you can get on the water independently as a real kiter under good conditions, safely, and with great pleasure. A complete kite training to make you a real independent kiter in 1 week!',
                                du: 'Dit tussentijdse pakket is het ideale pakket voor kitesurfers die aan boord moeten komen. Wij helpen je op weg en zorgen ervoor dat je zelfstandig het water op kunt gaan als een echte kitesurfer onder goede omstandigheden, veilig en met veel plezier. Een complete kitesurftraining om je in 1 week een echt onafhankelijke kitesurfer te maken!',
                                gr: 'Dieses Fortgeschrittenen-Paket ist das ideale Paket für Kitesurfer, die auf das Board kommen müssen. Wir helfen Ihnen beim Einstieg und stellen sicher, dass Sie bei guten Bedingungen, sicher und mit großem Vergnügen eigenständig aufs Wasser gehen können. Ein komplettes Kitesurftraining, um Sie in 1 Woche zu einem unabhängigen Kiter zu machen!'
                            },
                            imgPosition: 'left',
                        },
                        {
                            imgSrc: tab2_2,
                            text: {
                                en: 'What do you learn?',
                                du: 'Wat leer je?',
                                gr: 'Was lernst du?'
                            },
                            para: {
                                en: 'After this week you can go on the water independently with your own kite set and the goal is that you can kite upwind so that you no longer have to walk! Our instructors ensure that you are in the right place with the right conditions, help you with tips and tricks, coach you, and keep an eye on things while you are practicing.',
                                du: 'Na deze week kun je zelfstandig het water op met je eigen kite set en het doel is dat je tegen de wind in kunt kiten, zodat je niet meer hoeft te lopen! Onze instructeurs zorgen ervoor dat je op de juiste plek bent met de juiste omstandigheden, helpen je met tips en trucs, coachen je en houden je in de gaten terwijl je oefent.',
                                gr: 'Nach dieser Woche können Sie eigenständig mit Ihrem eigenen Kite-Set aufs Wasser gehen, und das Ziel ist, dass Sie gegen den Wind kiten können, sodass Sie nicht mehr laufen müssen! Unsere Lehrer stellen sicher, dass Sie am richtigen Ort unter den richtigen Bedingungen sind, geben Ihnen Tipps und Tricks, coachen Sie und behalten Sie während des Übens im Auge.'
                            },
                            imgPosition: 'right',
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: ' ',
                                du: 'Leer de basis van poppen',
                                gr: 'Lerne die Grundlagen des Pop'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitesurfcarrière!',
                                gr: 'Lerne, wie man popt. Dies ist eine sehr wichtige Fähigkeit, die Sie für Ihre gesamte Kitesurf-Karriere benötigen!'
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "FAQ"
                    },
                    faq_data: [
                        {
                            title: {
                                en: ' ',
                                du: 'Leer de basis van poppen',
                                gr: 'Lerne die Grundlagen des Pop'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitesurfcarrière!',
                                gr: 'Lerne, wie man popt. Dies ist eine sehr wichtige Fähigkeit, die Sie für Ihre gesamte Kitesurf-Karriere benötigen!'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: "Professioneel team tot uw dienst",
                                gr: "Professionelles Team zu Ihren Diensten"
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset-coaching",
                                gr: "Headset-Coaching"
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: "Boek vooruitgang onder ideale omstandigheden!",
                                gr: "Machen Sie Fortschritte unter idealen Bedingungen!"
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: "Vraag advies aan reisexpert AJ",
                                gr: "Fragen Sie Reiseexperte AJ um Rat"
                            }
                        }
                    ]
                },


                {
                    levelName: 'Level 3 - Independend',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go!',
                        du: "Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en daagt jezelf uit om nieuwe dingen te leren! In deze cursus word je zo veel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan!",
                        gr: "Wenn du mit Kitern auf dem Wasser bist, die das gleiche Niveau haben, machst du immer mehr Fortschritte. Ihr lernt voneinander und fordert euch heraus, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gefordert und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, los geht’s!"
                    },
                    imgsect: [
                        {
                            imgSrc: tab3_1,
                            text: {
                                en: 'Learn new tricks',
                                du: "Leer nieuwe trucs",
                                gr: "Lerne neue Tricks"
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: "Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste sprong tot een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!",
                                gr: "Dies ist das ultimative Paket für jeden, der bereits kitesurfen kann, aber Fortschritte machen und neue Tricks lernen möchte. Vom ersten Sprung bis zum Kiteloop Handle Pass. Unser Team ist bereit, dir mit den richtigen Tipps und Tricks zu helfen, deine Ziele zu erreichen!"
                            },
                            imgPosition: 'left',
                        },
                        {
                            imgSrc: tab3_2,
                            text: {
                                en: 'What to expect from this course',
                                du: "Wat kun je verwachten van deze cursus",
                                gr: "Was du von diesem Kurs erwarten kannst"
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: "Tijdens de cursus kijken we naar wat jouw persoonlijke doelen zijn en wat haalbaar is. We gaan samen door de theorie en tijdens de week proberen we je zo veel mogelijk te pushen, coachen en motiveren om op een veilige manier nieuwe dingen te proberen.",
                                gr: "Im Kurs schauen wir, was deine persönlichen Ziele sind und was machbar ist. Wir gehen gemeinsam die Theorie durch und versuchen, dich in der Woche so gut wie möglich zu fördern, zu coachen und zu motivieren, neue Dinge auf sichere Weise auszuprobieren."
                            },
                            imgPosition: 'right',
                        },
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: "Wat leer je?",
                        gr: "Was lernst du?"
                    },
                    acordation_data: [
                        {
                            title: {
                                en: ' ',
                                du: "Leer de basis van pop",
                                gr: "Lerne die Grundlagen des Pop"
                            },
                            info: {
                                en: '  !',
                                du: "Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kite carrière!",
                                gr: "Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kite-Karriere brauchst!"
                            },
                        },
                        {
                            title: {
                                en: ' ',
                                du: "Leer de basis van pop",
                                gr: "Lerne die Grundlagen des Pop"
                            },
                            info: {
                                en: '  !',
                                du: "Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kite carrière!",
                                gr: "Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kite-Karriere brauchst!"
                            },
                        },
                        // Repeated sections are translated similarly
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "FAQ"
                    },
                    faq_data: [
                        {
                            title: {
                                en: ' ',
                                du: "Leer de basis van pop",
                                gr: "Lerne die Grundlagen des Pop"
                            },
                            info: {
                                en: '  !',
                                du: "Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kite carrière!",
                                gr: "Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kite-Karriere brauchst!"
                            },
                        },
                        // Repeated sections are translated similarly
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: "Professioneel team tot uw dienst",
                                gr: "Professionelles Team zu Ihren Diensten"
                            },
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            },
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: "Boek vooruitgang in ideale omstandigheden!",
                                gr: "Mache Fortschritte unter idealen Bedingungen!"
                            },
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: "Vraag advies aan reisspecialist AJ",
                                gr: "Frage AJ, den Reiseexperten, um Rat"
                            },
                        },
                    ]
                }


            ],

            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kite-uitrusting verhuur",
                    gr: "Kiteausrüstung Verleih"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en we zorgen ervoor dat je altijd met de juiste maat kite op het water bent. Geen zin om al je spullen mee te nemen? Geen probleem!",
                    gr: "Bei uns findest du das neueste Kitematerial. Wir haben die meisten Marken verfügbar und sorgen dafür, dass du immer mit der richtigen Größe auf dem Wasser bist. Keine Lust, all dein Material mitzubringen? Kein Problem!"
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree"
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Make sure you get your insurance in order if you break your own or our material",
                    du: "Je zorgt ervoor dat je je juiste niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt voltooien, kun je het openstaande bedrag op verschillende manieren met ons verrekenen. - Je kunt het openstaande bedrag gebruiken in onze webshop voor (kite) uitrusting – Je kunt het gebruiken voor andere KiteActive-reizen of het als cadeaukaart aan vrienden geven – Je kunt je pakket afmaken in een van onze scholen in Nederland. Zorg ervoor dat je verzekering in orde is als je je eigen of ons materiaal beschadigt.",
                    gr: "Du sorgst dafür, dass du während deiner Buchung dein korrektes Niveau angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den offenen Betrag auf verschiedene Weise mit uns begleichen. - Du kannst den offenen Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden – Du kannst ihn für andere KiteActive-Reisen verwenden oder ihn als Geschenkkarte an Freunde weitergeben – Du kannst dein Paket in einer unserer Schulen in den Niederlanden abschließen. Stelle sicher, dass du deine Versicherung in Ordnung bringst, falls du dein eigenes oder unser Material beschädigst."
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je bij ons op de veiligste manier kitesurfen leert, moet je altijd bewust zijn van jezelf en anderen. Luister altijd goed naar de instructies van ons team.",
                    gr: "Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport und obwohl wir sicherstellen, dass du das Kitesurfen bei uns auf die sicherste Weise lernst, musst du dir immer deiner selbst und anderer bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }


        },

        accommodation: {
            main_title: {
                en: "Feel at Home in our Kiteactive Hotel",
                gr: "Fühlen Sie sich wie zu Hause in unserem Kiteactive Hotel",
                du: "Voel je thuis in ons Kiteactive Hotel"
            },
            main_desc: {
                en: "We proudly present you our own KiteActive Hotel on the beach in Paje. The ideal set-up for kite surfers. The atmosphere and our team ensure a top stay with everything you need within reach!",
                gr: "Wir präsentieren stolz unser eigenes KiteActive Hotel am Strand von Paje. Das ideale Setup für Kitesurfer. Die Atmosphäre und unser Team sorgen für einen erstklassigen Aufenthalt mit allem, was Sie brauchen, in greifbarer Nähe!",
                du: "We presenteren met trots ons eigen KiteActive Hotel op het strand in Paje. De ideale opstelling voor kitesurfers. De sfeer en ons team zorgen voor een topverblijf met alles wat je nodig hebt binnen handbereik!"
            },
            card_details: [
                {
                    imgSrc: hotel1,
                    title: {
                        en: "SHARED ROOM",
                        gr: "GETEILTES ZIMMER",
                        du: "GEDEELDE KAMER"
                    },
                    description: {
                        en: "Sleep in a room with a maximum of 2 other kiters. We try to keep ladies and gentlemen separate.",
                        gr: "Schlafen Sie in einem Zimmer mit maximal 2 anderen Kitern. Wir versuchen, Damen und Herren getrennt zu halten.",
                        du: "Slaap in een kamer met maximaal 2 andere kiters. We proberen dames en heren gescheiden te houden."
                    }
                },
                {
                    imgSrc: hotel2,
                    title: {
                        en: "PRIVATE ROOM",
                        gr: "PRIVATZIMMER",
                        du: "PRIVÉKAMER"
                    },
                    description: {
                        en: "Would you like to enjoy a little more privacy? Book a private room for a surcharge of €30/night.",
                        gr: "Möchten Sie etwas mehr Privatsphäre genießen? Buchen Sie ein Privatzimmer gegen einen Aufpreis von 30 €/Nacht.",
                        du: "Wil je genieten van wat meer privacy? Boek een privékamer voor een toeslag van €30 per nacht."
                    }
                },
                {
                    imgSrc: hotel3,
                    title: {
                        en: "DELUXE SUITE",
                        gr: "DELUXE SUITE",
                        du: "DELUXE SUITE"
                    },
                    description: {
                        en: "You can share a room with your friend at no extra cost. Please let us know when you make your booking.",
                        gr: "Sie können ein Zimmer mit Ihrem Freund teilen, ohne zusätzliche Kosten. Bitte teilen Sie uns dies bei Ihrer Buchung mit.",
                        du: "Je kunt een kamer delen met je vriend zonder extra kosten. Laat het ons weten bij het boeken."
                    }
                },
                {
                    imgSrc: service1,
                    title: {
                        en: "ROOFTOP TERRACE",
                        gr: "DACHTERRASSE",
                        du: "DAKTERRAS"
                    },
                    description: {
                        en: "Sleep in a room with a maximum of 2 other kiters. We try to keep ladies and gentlemen separate.",
                        gr: "Schlafen Sie in einem Zimmer mit maximal 2 anderen Kitern. Wir versuchen, Damen und Herren getrennt zu halten.",
                        du: "Slaap in een kamer met maximaal 2 andere kiters. We proberen dames en heren gescheiden te houden."
                    }
                },
                {
                    imgSrc: service2,
                    title: {
                        en: "KITE SERVICE IN OUR CENTER",
                        gr: "KITE-SERVICE IN UNSEREM ZENTRUM",
                        du: "KITESERVICE IN ONS CENTRUM"
                    },
                    description: {
                        en: "Would you like to enjoy a little more privacy? Book a private room for a surcharge of €30/night.",
                        gr: "Möchten Sie etwas mehr Privatsphäre genießen? Buchen Sie ein Privatzimmer gegen einen Aufpreis von 30 €/Nacht.",
                        du: "Wil je genieten van wat meer privacy? Boek een privékamer voor een toeslag van €30 per nacht."
                    }
                },
                {
                    imgSrc: service3,
                    title: {
                        en: "PERFECT LOCATION",
                        gr: "PERFEKTE LAGE",
                        du: "PERFECTE LOCATIE"
                    },
                    description: {
                        en: "You can share a room with your friend at no extra cost. Please let us know when you make your booking.",
                        gr: "Sie können ein Zimmer mit Ihrem Freund teilen, ohne zusätzliche Kosten. Bitte teilen Sie uns dies bei Ihrer Buchung mit.",
                        du: "Je kunt een kamer delen met je vriend zonder extra kosten. Laat het ons weten bij het boeken."
                    }
                }
            ],
            location_title: {
                en: "How to get here",
                gr: "Wie Sie hierher kommen",
                du: "Hoe kom je hier"
            },
            location_subtitle: {
                en: "The Caribbean of Africa",
                gr: "Die Karibik Afrikas",
                du: "De Caraïben van Afrika"
            },
            location_desc: {
                en: "We proudly present you our own KiteActive Hotel on the beach in Paje. The ideal set-up for kite surfers. The atmosphere and our team ensure a top stay with everything you need within reach!",
                gr: "Wir präsentieren stolz unser eigenes KiteActive Hotel am Strand von Paje. Das ideale Setup für Kitesurfer. Die Atmosphäre und unser Team sorgen für einen erstklassigen Aufenthalt mit allem, was Sie brauchen, in greifbarer Nähe!",
                du: "We presenteren met trots ons eigen KiteActive Hotel op het strand in Paje. De ideale opstelling voor kitesurfers. De sfeer en ons team zorgen voor een topverblijf met alles wat je nodig hebt binnen handbereik!"
            },
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6486.035624855454!2d39.53296055!3d-6.2658911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185d3d0a989c2b8f%3A0xd23378fca6a1a8db!2sPaje%2C%20Tanzania!5e1!3m2!1sen!2sin!4v1729937684338!5m2!1sen!2sin"
         
        },

        activities: [
            {
                img: zanzibar_activity_img1,
                title: {
                    en: 'Spice Tour',
                    du: 'Specerijentour',
                    gr: 'Gewürztour'
                }
            },
            {
                img: zanzibar_activity_img2,
                title: {
                    en: 'Kuza Cave',
                    du: 'Kuza-grot',
                    gr: 'Kuza-Höhle'
                }
            },
            {
                img: zanzibar_activity_img3,
                title: {
                    en: 'Blue Safari',
                    du: 'Blauwe safari',
                    gr: 'Blaue Safari'
                }
            },
            {
                img: zanzibar_activity_img4,
                title: {
                    en: 'Local Dhow Sailing',
                    du: 'Lokale dhow-zeilen',
                    gr: 'Lokales Dhow-Segeln'
                }
            },
            {
                img: zanzibar_activity_img5,
                title: {
                    en: 'Snorkeling',
                    du: 'Snorkelen',
                    gr: 'Schnorcheln'
                }
            }
        ],

        hosted: [
            {
                hosted_img: zan_hosted_img1,
                hosted_name: {
                    en: "Ellis Muiser",
                    gr: "Ellis Muiser",
                    du: "Ellis Muiser"
                },
                hosted_position: {
                    en: "Kitecamp Host",
                    gr: "Kitecamp Gastgeber",
                    du: "Kitecamp Gastheer"
                },
                kite_exp: {
                    en: "2 years kite experience",
                    gr: "2 Jahre Kiterfahrung",
                    du: "2 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "I will show you all the hidden gems of Zanzibar! Together we go on kitesurf adventures and amazing parties!",
                    gr: "Ich werde dir all die versteckten Schätze Zanzibars zeigen! Gemeinsam gehen wir auf Kitesurf-Abenteuer und haben unglaubliche Partys!",
                    du: "Ik laat je alle verborgen juweeltjes van Zanzibar zien! Samen gaan we op kitesurfavonturen en geweldige feesten!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "Zanzibar & Waddensea!",
                    gr: "Zanzibar & Wattenmeer!",
                    du: "Zanzibar & Waddensea!"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_year: {
                    en: "4 years",
                    gr: "4 Jahre",
                    du: "4 jaar"
                }
            },
            {
                hosted_img: zan_hosted_img2,
                hosted_name: {
                    en: "Alain ”the Magician”",
                    gr: "Alain „der Magier“",
                    du: "Alain 'de Magiër'"
                },
                hosted_position: {
                    en: "Instructor",
                    gr: "Instruktor",
                    du: "Instructeur"
                },
                kite_exp: {
                    en: "9 years kite experience",
                    gr: "9 Jahre Kiterfahrung",
                    du: "9 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "Together we make an awesome time in Zanzibar! I am an experienced kite instructor for years and I will teach you everything I know.",
                    gr: "Gemeinsam haben wir eine tolle Zeit in Sansibar! Ich bin seit Jahren ein erfahrener Kitesurf-Instruktor und ich werde dir alles beibringen, was ich weiß.",
                    du: "Samen maken we een geweldige tijd in Zanzibar! Ik ben al jaren een ervaren kitesurfinstructeur en ik zal je alles leren wat ik weet."
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favorite Destination",
                    gr: "Lieblingsziel",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "Paje beach, ZANZIBAR!",
                    gr: "Paje Strand, ZANZIBAR!",
                    du: "Paje strand, ZANZIBAR!"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "6 years",
                    gr: "6 Jahre",
                    du: "6 jaar"
                }
            },
            {
                hosted_img: zan_hosted_img3,
                hosted_name: {
                    en: "Chollo ”Zanzibari”",
                    gr: "Chollo „Zanzibari“",
                    du: "Chollo 'Zanzibari'"
                },
                hosted_position: {
                    en: "Instructor",
                    gr: "Instruktor",
                    du: "Instructeur"
                },
                kite_exp: {
                    en: "9 years kite experience",
                    gr: "9 Jahre Kiterfahrung",
                    du: "9 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "Kitesurfing and teaching is my biggest passion. I am already with KiteActive for more than 8 years and I enjoy every minute on the water with you!",
                    gr: "Kitesurfen und Unterrichten ist meine größte Leidenschaft. Ich bin schon seit mehr als 8 Jahren bei KiteActive und genieße jede Minute im Wasser mit dir!",
                    du: "Kitesurfen en lesgeven is mijn grootste passie. Ik ben al meer dan 8 jaar bij KiteActive en ik geniet van elke minuut op het water met jou!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favorite Destination",
                    gr: "Lieblingsziel",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "Paje beach, ZANZIBAR!",
                    gr: "Paje Strand, ZANZIBAR!",
                    du: "Paje strand, ZANZIBAR!"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "8 years",
                    gr: "8 Jahre",
                    du: "8 jaar"
                }
            },
            {
                hosted_img: zan_hosted_img4,
                hosted_name: {
                    en: "Furaha ”Happy”",
                    gr: "Furaha „Glücklich“",
                    du: "Furaha 'Blij'"
                },
                hosted_position: {
                    en: "Camp manager",
                    gr: "Camp-Leiter",
                    du: "Campmanager"
                },
                kite_exp: {
                    en: "8 years experience in KiteActive hotel",
                    gr: "8 Jahre Erfahrung im KiteActive-Hotel",
                    du: "8 jaar ervaring in het KiteActive hotel"
                },
                hosted_para: {
                    en: "Karibu Zanzibar! I will make sure you have an amazing stay. I am in charge of the bar, reception, and I organize amazing trips for you when there is no wind.",
                    gr: "Karibu Sansibar! Ich werde dafür sorgen, dass du einen fantastischen Aufenthalt hast. Ich bin für die Bar, die Rezeption zuständig und organisiere tolle Ausflüge für dich, wenn kein Wind weht.",
                    du: "Karibu Zanzibar! Ik zorg ervoor dat je een geweldig verblijf hebt. Ik ben verantwoordelijk voor de bar, de receptie en ik organiseer geweldige uitjes voor je als er geen wind is."
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favorite Destination",
                    gr: "Lieblingsziel",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "I love Zanzibar",
                    gr: "Ich liebe Sansibar",
                    du: "Ik hou van Zanzibar"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "7 years",
                    gr: "7 Jahre",
                    du: "7 jaar"
                }
            },
        ],

        reviews: {}

    },



    // Snowkite trip data .....................

    {
        name: 'Snowkite',
        vedio: Snowkite_vedio,
        backcover: snowkitecover,
        alltabs: {
            highlight: [
                {
                    text: {
                        en: "Stay at our beachside KiteActive hotel, just steps from the best kitespots. The perfect beginner spot with huge lagoon and shallow water",
                        gr: "Übernachten Sie in unserem KiteActive-Hotel am Strand, nur wenige Schritte von den besten Kitespots entfernt. Der perfekte Ort für Anfänger mit einer großen Lagune und flachem Wasser",
                        du: "Verblijf in ons KiteActive-hotel aan het strand, op slechts een paar stappen van de beste kitespots. De perfecte plek voor beginners met een grote lagune en ondiep water"
                    }
                },
                {
                    text: {
                        en: "Expert Guidance: Learn or progress with our experienced instructors in a stunning, safe environment.",
                        gr: "Expertenanleitung: Lernen oder machen Sie Fortschritte mit unseren erfahrenen Lehrern in einer atemberaubenden, sicheren Umgebung.",
                        du: "Expertbegeleiding: Leer of vorder met onze ervaren instructeurs in een prachtige, veilige omgeving."
                    }
                },
                {
                    text: {
                        en: "Community Vibes: Connect with like-minded kiters and make lifelong friends on an adventure-filled holiday.",
                        gr: "Gemeinschaftsgefühl: Knüpfen Sie Kontakte mit gleichgesinnten Kitern und schließen Sie lebenslange Freundschaften auf einem abenteuerlichen Urlaub.",
                        du: "Gemeenschapsgevoel: Verbind met gelijkgestemde kiters en maak levenslange vrienden tijdens een avontuurlijke vakantie."
                    }
                },
                {
                    text: {
                        en: "Seamless Experience: We take care of everything—from accommodation to daily activities—so you can focus on kiting and having fun!",
                        gr: "Nahtloses Erlebnis: Wir kümmern uns um alles – von der Unterkunft bis zu den täglichen Aktivitäten – damit Sie sich aufs Kiten und Spaßhaben konzentrieren können!",
                        du: "Naadloze ervaring: Wij zorgen voor alles - van accommodatie tot dagelijkse activiteiten - zodat jij je kunt concentreren op kiten en plezier maken!"
                    }
                }
            ],
            question: [
                {
                    title: {
                        en: "What equipment should I take with me to Zanzibar?",
                        gr: "Welche Ausrüstung sollte ich nach Sansibar mitnehmen?",
                        du: "Welke uitrusting moet ik meenemen naar Zanzibar?"
                    },
                    para: {
                        en: "Always try to take large sizes with you and, if you can choose, a larger board. The ideal kite set for an average weight of 75kg is a 15-12-9 m2 with a 140 board. Skimboards, strapless boards and foils are really cool toys in Zanzibar!",
                        gr: "Versuchen Sie immer, große Größen mitzunehmen und, wenn möglich, ein größeres Board zu wählen. Das ideale Kite-Set für ein Durchschnittsgewicht von 75 kg ist ein 15-12-9 m2 mit einem 140er Board. Skimboards, strapless Boards und Foils sind in Sansibar wirklich coole Spielzeuge!",
                        du: "Probeer altijd grote maten mee te nemen en, als je kunt kiezen, een groter board. De ideale kite set voor een gemiddeld gewicht van 75 kg is een 15-12-9 m2 met een 140 board. Skimboards, strapless boards en foils zijn echt coole speeltjes in Zanzibar!"
                    }
                },
                {
                    title: {
                        en: "What is the wind like in Zanzibar?",
                        gr: "Wie ist der Wind auf Sansibar?",
                        du: "Hoe is de wind op Zanzibar?"
                    },
                    para: {
                        en: "The wind on Zanzibar is a much discussed topic in the kite world. The wind seasons are from June to September and from December to early March. Every season is different on Zanzibar, we have experienced seasons when you were working hard every day on your 9m2, but also seasons when we had no wind for a number of days or only for large kites (14m2 +). It remains a sport that depends on mother nature, but in general you will certainly enjoy jet kitesurfing!!!!!",
                        gr: "Der Wind auf Sansibar ist ein viel diskutiertes Thema in der Kite-Welt. Die Windsaison dauert von Juni bis September und von Dezember bis Anfang März. Jede Saison ist anders auf Sansibar. Wir haben Saisons erlebt, in denen man jeden Tag mit seinem 9m2-Kite hart arbeiten musste, aber auch Saisons, in denen es einige Tage keinen Wind gab oder nur große Kites (14m2 +) gebraucht wurden. Es bleibt ein Sport, der von Mutter Natur abhängt, aber im Allgemeinen wirst du das Jet-Kitesurfen auf jeden Fall genießen!!!!!",
                        du: "De wind op Zanzibar is een veelbesproken onderwerp in de kitesurfwereld. De windseizoenen zijn van juni tot september en van december tot begin maart. Elk seizoen is anders op Zanzibar, we hebben seizoenen meegemaakt waarin je elke dag hard moest werken met je 9m2, maar ook seizoenen waarin we een aantal dagen geen wind hadden of alleen voor grote kites (14m2 +). Het blijft een sport die afhankelijk is van moeder natuur, maar in het algemeen zul je zeker genieten van jet kitesurfen!!!!!"
                    }
                },
                {
                    title: {
                        en: "Are vaccinations necessary?",
                        gr: "Sind Impfungen notwendig?",
                        du: "Zijn vaccinaties noodzakelijk?"
                    },
                    para: {
                        en: "Make sure you have completed all vaccinations with your doctor. If you come from the EU, a yellow fever vaccine is not mandatory, but it is if you come from other destinations. Regardless of length of stay, the following vaccinations are recommended for Tanzania: vaccination against DTP (Diphtheria, Tetanus and Polio), vaccination against hepatitis A (contagious jaundice) and a yellow fever vaccination. If you have not had measles or a measles vaccination, an MMR vaccination is recommended for Tanzania.",
                        gr: "Stellen Sie sicher, dass Sie alle Impfungen mit Ihrem Arzt abgeschlossen haben. Wenn Sie aus der EU kommen, ist eine Gelbfieberimpfung nicht obligatorisch, aber sie ist erforderlich, wenn Sie aus anderen Ländern kommen. Unabhängig von der Aufenthaltsdauer werden für Tansania die folgenden Impfungen empfohlen: Impfung gegen DTP (Diphtherie, Tetanus und Polio), Impfung gegen Hepatitis A (ansteckende Gelbsucht) und eine Gelbfieberimpfung. Wenn Sie keine Masern hatten oder keine Masernimpfung erhalten haben, wird eine MMR-Impfung für Tansania empfohlen.",
                        du: "Zorg ervoor dat je alle vaccinaties hebt voltooid bij je arts. Als je uit de EU komt, is een gele koorts vaccinatie niet verplicht, maar wel als je uit andere bestemmingen komt. Ongeacht de verblijfsduur worden de volgende vaccinaties aanbevolen voor Tanzania: vaccinatie tegen DTP (Difterie, Tetanus en Polio), vaccinatie tegen hepatitis A (besmettelijke geelzucht) en een gele koorts vaccinatie. Als je geen mazelen hebt gehad of geen mazelen vaccinatie hebt ontvangen, wordt een BMR-vaccinatie aanbevolen voor Tanzania."
                    }
                },
                {
                    title: {
                        en: "Do I need a VISA?",
                        gr: "Benötige ich ein Visum?",
                        du: "Heb ik een visum nodig?"
                    },
                    para: {
                        en: "Yes, but you have to buy this at the airport for $50. So always make sure you have enough dollars with you when you arrive at the airport. This saves you a lot of time.",
                        gr: "Ja, aber Sie müssen es am Flughafen für 50 Dollar kaufen. Stellen Sie also sicher, dass Sie genügend Dollar bei sich haben, wenn Sie am Flughafen ankommen. Das spart Ihnen viel Zeit.",
                        du: "Ja, maar je moet dit op de luchthaven kopen voor $50. Zorg er dus voor dat je genoeg dollars bij je hebt wanneer je op de luchthaven aankomt. Dit bespaart je veel tijd."
                    }
                },
                {
                    title: {
                        en: "How can I book?",
                        gr: "Wie kann ich buchen?",
                        du: "Hoe kan ik boeken?"
                    },
                    para: {
                        en: "You can book online without obligation via this link. Booking a trip with us is very easy. Just send us one message, phone call, WhatsApp or send a carrier pigeon that you want to come with and we will arrange the rest.",
                        gr: "Sie können unverbindlich online über diesen Link buchen. Eine Reise mit uns zu buchen ist sehr einfach. Schicken Sie uns einfach eine Nachricht, einen Anruf, WhatsApp oder eine Brieftaube, dass Sie mitkommen möchten, und wir erledigen den Rest.",
                        du: "Je kunt online vrijblijvend boeken via deze link. Een reis met ons boeken is heel eenvoudig. Stuur ons gewoon een bericht, telefoontje, WhatsApp of stuur een postduif dat je mee wilt en wij regelen de rest."
                    }
                },
                {
                    title: {
                        en: "Is there more to do besides kitesurfing?",
                        gr: "Gibt es neben Kitesurfen noch mehr zu tun?",
                        du: "Is er meer te doen naast kitesurfen?"
                    },
                    para: {
                        en: "In addition to kitesurfing, Paje is a perfect place to learn how to dive or chill on the beach.",
                        gr: "Neben dem Kitesurfen ist Paje der perfekte Ort, um tauchen zu lernen oder am Strand zu entspannen.",
                        du: "Naast kitesurfen is Paje de perfecte plek om te leren duiken of te ontspannen op het strand."
                    }
                }
            ]
        },


        overview: {
            trip_heading: {
                en: "Snowkite Fever weekends in Feldberg",
                gr: "Snowkite-Fieber-Wochenenden in Feldberg",
                du: "Snowkite Fever weekenden in Feldberg"
            },
            trip_para: {
                en: "Join KiteActive for an exhilarating snowkite adventure in Feldberg! Nestled in the heart of the Black Forest, Feldberg offers pristine snow-covered landscapes and ideal wind conditions for snowkiting. Whether you're a seasoned kiter or looking to try something new, our experienced instructors will guide you through the basics and help you master the sport. Enjoy the thrill of gliding over snow, breathtaking mountain views, and the camaraderie of fellow kite enthusiasts. With all equipment provided and cozy accommodations arranged, this is the ultimate winter getaway. Don’t miss out on this unique adventure with KiteActive!",
                gr: "Begleite KiteActive auf ein aufregendes Snowkite-Abenteuer in Feldberg! Eingebettet im Herzen des Schwarzwaldes bietet Feldberg makellos verschneite Landschaften und ideale Windbedingungen zum Snowkiten. Egal, ob du ein erfahrener Kiter bist oder etwas Neues ausprobieren möchtest, unsere erfahrenen Trainer führen dich durch die Grundlagen und helfen dir, den Sport zu meistern. Erlebe den Nervenkitzel, über den Schnee zu gleiten, atemberaubende Bergblicke und die Kameradschaft von Gleichgesinnten. Mit bereitgestellter Ausrüstung und gemütlichen Unterkünften ist dies der ultimative Winterurlaub. Verpasse nicht dieses einzigartige Abenteuer mit KiteActive!",
                du: "Sluit je aan bij KiteActive voor een opwindend snowkite-avontuur in Feldberg! Gelegen in het hart van het Zwarte Woud, biedt Feldberg ongerepte, met sneeuw bedekte landschappen en ideale windomstandigheden voor snowkiten. Of je nu een ervaren kiter bent of iets nieuws wilt proberen, onze ervaren instructeurs begeleiden je door de basis en helpen je de sport onder de knie te krijgen. Geniet van de sensatie van glijden over sneeuw, adembenemende berguitzichten en de gezelligheid van mede-kite-enthousiastelingen. Met alle apparatuur inbegrepen en knusse accommodaties geregeld, is dit de ultieme winteruitstap. Mis dit unieke avontuur met KiteActive niet!"
            },
            img1: surf_img5,
            img1_head: {
                en: "Dates 2025",
                gr: "Daten 2025",
                du: "Data 2025"
            },
            img1_para: {
                en: "26 - 28 Dec 2024 16 - 18 Jan 2025 26 Feb - 01 Mar 2025",
                gr: "26. - 28. Dez. 2024 16. - 18. Jan. 2025 26. Feb. - 01. März 2025",
                du: "26 - 28 dec 2024 16 - 18 jan 2025 26 feb - 01 mrt 2025"
            },
            img2: surf_img6,
            img2_head: {
                en: "Skills",
                gr: "Fähigkeiten",
                du: "Vaardigheden"
            },
            img2_para: {
                en: "All levels are welcome",
                gr: "Alle Niveaus sind willkommen",
                du: "Alle niveaus zijn welkom"
            },
            img3: surf_img7,
            img3_head: {
                en: "Join KiteActive",
                gr: "Mach mit bei KiteActive",
                du: "Doe mee met KiteActive"
            },
            img3_para: {
                en: "Good vibes only. Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "Nur gute Vibes. Trete dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / dem KA-Team zu chatten.",
                du: "Alleen goede vibes. Doe mee met de groepschat om op de hoogte te blijven of te chatten met andere reizigers / het KA-team."
            },
            img4: surf_img8,
            img4_head: {
                en: "Early bird",
                gr: "Frühbucher",
                du: "Vroege vogel"
            },
            img4_para: {
                en: "Book before 15 november and get € 50 discount",
                gr: "Buche vor dem 15. November und erhalte € 50 Rabatt",
                du: "Boek voor 15 november en ontvang € 50 korting"
            },
            gall_img1: snowkite_OW_img1,
            gall_img2: snowkite_OW_img2,
            gall_img3: snowkite_OW_img3,
            gall_img4: snowkite_OW_img4,
        },
        kitespot: [
            {
                imgSrc: Snow_kitespotimg1,
                text: {
                    en: 'A winter wonderland for snowkiters!',
                    du: 'Een winterwonderland voor snowkiters!',
                    gr: 'Ein Winterwunderland für Snowkiter!'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: 'The snowkite spot on Feldberg, located in the Black Forest of Germany, is a paradise for snowkite enthusiasts. Feldberg, the highest mountain in the region, offers expansive snow-covered fields and consistent wind conditions, making it an ideal location for snowkiting. The wide-open spaces allow for plenty of room to maneuver, whether you’re carving through the snow or catching some air. With stunning panoramic views of the surrounding mountains and valleys, kiting here is not just an adrenaline rush but also a feast for the eyes. Feldberg is suitable for both beginners and advanced kiters, providing a perfect blend of adventure and natural beauty.',
                    du: 'De snowkite-spot op de Feldberg, gelegen in het Zwarte Woud van Duitsland, is een paradijs voor snowkite-liefhebbers. De Feldberg, de hoogste berg in de regio, biedt uitgestrekte met sneeuw bedekte velden en constante windomstandigheden, wat het tot een ideale locatie voor snowkiten maakt. De uitgestrekte open ruimtes bieden voldoende ruimte om te manoeuvreren, of je nu door de sneeuw snijdt of wat lucht vangt. Met adembenemende panoramische uitzichten op de omliggende bergen en valleien is kiten hier niet alleen een adrenalinekick, maar ook een lust voor het oog. Feldberg is geschikt voor zowel beginners als gevorderde kiters, en biedt de perfecte mix van avontuur en natuurlijke schoonheid.',
                    gr: 'Der Snowkite-Spot auf dem Feldberg im Schwarzwald in Deutschland ist ein Paradies für Snowkite-Enthusiasten. Der Feldberg, der höchste Berg der Region, bietet weitläufige schneebedeckte Felder und beständige Windverhältnisse, was ihn zu einem idealen Ort zum Snowkiten macht. Die weiten offenen Flächen bieten viel Platz zum Manövrieren, egal ob du durch den Schnee gleitest oder etwas Luft fängst. Mit atemberaubenden Panoramablicken auf die umliegenden Berge und Täler ist das Kiten hier nicht nur ein Adrenalinschub, sondern auch ein Fest für die Augen. Der Feldberg ist sowohl für Anfänger als auch für fortgeschrittene Kiter geeignet und bietet die perfekte Mischung aus Abenteuer und natürlicher Schönheit.'
                },
                imgPosition: 'right',
            }
        ],

        packages: {
            levelsData: [
                {
                    levelName: 'Level 1 - Beginner',
                    desc: {
                        en: 'This is the perfect package if you have no experience with kitesurfing and want to learn this beautiful sport. Kitesurfing is not a sport that you can just learn in 1 afternoon. That’s why our trips are perfect! You are at a perfect destination with ideal conditions for a week with people who also want to learn as much as possible. Hey ho, let’s go!',
                        du: "Dit is het perfecte pakket als je geen ervaring hebt met kitesurfen en deze mooie sport wilt leren. Kitesurfen is geen sport die je zomaar in een middag kunt leren. Daarom zijn onze reizen perfect! Je bevindt je op een perfecte bestemming met ideale omstandigheden voor een week met mensen die ook zoveel mogelijk willen leren. Hey ho, laten we gaan!",
                        gr: "Dies ist das perfekte Paket, wenn Sie keine Erfahrung mit dem Kitesurfen haben und diesen wunderschönen Sport erlernen möchten. Kitesurfen ist kein Sport, den man in einem Nachmittag einfach lernen kann. Deshalb sind unsere Reisen perfekt! Sie sind an einem perfekten Zielort mit idealen Bedingungen für eine Woche, zusammen mit Menschen, die ebenfalls so viel wie möglich lernen möchten. Los geht's!"
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Designed for beginners',
                                du: "Ontworpen voor beginners",
                                gr: "Für Anfänger entworfen"
                            },
                            para: {
                                en: 'This Level 1 – Beginner package is the ideal start to your kitesurfing career. We will help you get started and with the right conditions we hope we can get you on board in this weekend. 4 hours of lessons from our team. A complete package including kite lessons, (shared) equipment rental, supervision, and coaching.',
                                du: "Dit Level 1 - Beginner pakket is de ideale start voor je kitesurfcarrière. We helpen je op weg en hopen dat we je met de juiste omstandigheden dit weekend aan boord kunnen krijgen. 4 uur lessen van ons team. Een compleet pakket inclusief kitesurflessen, (gedeelde) materiaalverhuur, begeleiding en coaching.",
                                gr: "Dieses Level 1 – Anfängerpaket ist der ideale Start für Ihre Kitesurfkarriere. Wir helfen Ihnen beim Einstieg und hoffen, dass wir Sie bei den richtigen Bedingungen an diesem Wochenende aufs Board bekommen. 4 Stunden Unterricht von unserem Team. Ein Komplettpaket inklusive Kitesurfunterricht, (geteilte) Ausrüstungsverleih, Betreuung und Coaching."
                            },
                            imgPosition: 'left',
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: "Wat kun je van deze cursus verwachten?",
                                gr: "Was können Sie von diesem Kurs erwarten?"
                            },
                            para: {
                                en: 'After this week you can go on the water independently with your kite set. To ensure that you make optimal progress, we teach with 1 instructor on a maximum of 2 students. You share a kite set together for the entire week so that you can help each other and learn together. Kitesurfing can be quite intensive, especially in the beginning. It is nice to be on the water together. You will get lessons from our certified instructors! Real kite professionals who have been active in kite industry for over 10 years!',
                                du: "Na deze week kun je zelfstandig met je kitesurfset het water op. Om ervoor te zorgen dat je optimaal vooruitgang boekt, geven we les met 1 instructeur op maximaal 2 studenten. Je deelt samen een kitesurfset voor de hele week, zodat je elkaar kunt helpen en samen kunt leren. Kitesurfen kan behoorlijk intensief zijn, vooral in het begin. Het is fijn om samen op het water te zijn. Je krijgt les van onze gecertificeerde instructeurs! Echte kitesurfprofessionals die al meer dan 10 jaar actief zijn in de kitesurfbranche!",
                                gr: "Nach dieser Woche können Sie mit Ihrem Kitesurfset eigenständig aufs Wasser gehen. Um sicherzustellen, dass Sie optimal vorankommen, unterrichten wir mit einem Instruktor für maximal 2 Schüler. Sie teilen sich ein Kiteset die gesamte Woche, um sich gegenseitig zu helfen und gemeinsam zu lernen. Kitesurfen kann ziemlich intensiv sein, besonders am Anfang. Es ist schön, zusammen auf dem Wasser zu sein. Sie erhalten Unterricht von unseren zertifizierten Instruktoren! Echte Kitesurf-Profis, die seit über 10 Jahren in der Kitesurfbranche tätig sind!"
                            },
                            imgPosition: 'right',
                        },
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: "Wat leer je?",
                        gr: "Was lernst du?"
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Building up your kite and knowledge about kitematerials',
                                du: "Opbouwen van je kite en kennis over kitemateriaal",
                                gr: "Aufbau deines Kites und Wissen über Kite-Materialien"
                            },
                            info: {
                                en: 'Learn how to rig the kite, learn all about your material, bar extensions, line lengths, different systems, kite types, etc.!',
                                du: "Leer hoe je de kite opzet, leer alles over je materiaal, barverlengingen, lijnlengtes, verschillende systemen, kitetypes, enz.!",
                                gr: "Lerne, wie man den Kite aufrüstet, erfahre alles über dein Material, Barverlängerungen, Leinenlängen, verschiedene Systeme, Kitetypen usw.!"
                            },
                        },
                        {
                            title: {
                                en: 'Upwind bodydragging / board rescue',
                                du: "Upwind bodydragging / board redding",
                                gr: "Aufwind-Bodydragging / Board-Rettung"
                            },
                            info: {
                                en: 'What happens if you lose your board? Then you can safely reach your board or the shore by upwind body dragging. It is also good training to be able to fly your kite with one hand.',
                                du: "Wat gebeurt er als je je board verliest? Dan kun je door upwind body dragging veilig je board of de kust bereiken. Het is ook goede training om je kite met één hand te kunnen vliegen.",
                                gr: "Was passiert, wenn du dein Board verlierst? Dann kannst du durch Aufwind-Bodydragging sicher dein Board oder das Ufer erreichen. Es ist auch gutes Training, deinen Kite mit einer Hand zu steuern."
                            },
                        },
                        {
                            title: {
                                en: 'Turns & Upwind kiting',
                                du: "Bochten en upwind kiten",
                                gr: "Wenden & Upwind-Kiten"
                            },
                            info: {
                                en: 'Is everything going well? Of course, we will occasionally visit you after the course and provide supervision. We also give you tips and tricks when we see that you have arrived at a bend or altitude walk!',
                                du: "Gaat alles goed? Natuurlijk zullen we je af en toe na de cursus bezoeken en toezicht houden. We geven je ook tips en trucs wanneer we zien dat je een bocht of hoogte hebt bereikt!",
                                gr: "Läuft alles gut? Natürlich werden wir Sie gelegentlich nach dem Kurs besuchen und beaufsichtigen. Wir geben Ihnen auch Tipps und Tricks, wenn wir sehen, dass Sie eine Wende oder eine Höhe erreicht haben!"
                            },
                        },
                        {
                            title: {
                                en: 'Safe starting and landing',
                                du: "Veilig starten en landen",
                                gr: "Sicheres Starten und Landen"
                            },
                            info: {
                                en: 'Perhaps the most important thing during your course. Learn to take off/land independently and safely on the beach.',
                                du: "Misschien wel het belangrijkste tijdens je cursus. Leer zelfstandig en veilig op het strand te starten en te landen.",
                                gr: "Vielleicht das Wichtigste während deines Kurses. Lerne, unabhängig und sicher am Strand zu starten und zu landen."
                            },
                        },
                        {
                            title: {
                                en: 'Spot analysis',
                                du: "Spotanalyse",
                                gr: "Spot-Analyse"
                            },
                            info: {
                                en: 'Every kite spot is different, every forecast is different. We teach you what to pay attention to if you want to go kiting yourself.',
                                du: "Elke kite spot is anders, elke voorspelling is anders. We leren je waar je op moet letten als je zelf wilt gaan kiten.",
                                gr: "Jeder Kitespot ist anders, jede Wettervorhersage ist anders. Wir zeigen dir, worauf du achten musst, wenn du selbst Kiten gehen möchtest."
                            },
                        },
                        {
                            title: {
                                en: 'Safety systems, self rescue',
                                du: "Veiligheidssystemen, zelfredding",
                                gr: "Sicherheitssysteme, Selbstrettung"
                            },
                            info: {
                                en: 'Various safety systems are explained, emergency situations are discussed and a step-by-step plan is drawn up so that you can save yourself and get to safety at any time.',
                                du: "Verschillende veiligheidssystemen worden uitgelegd, noodsituaties worden besproken en er wordt een stapsgewijs plan opgesteld, zodat je jezelf kunt redden en altijd in veiligheid kunt brengen.",
                                gr: "Verschiedene Sicherheitssysteme werden erklärt, Notfallsituationen besprochen und ein Schritt-für-Schritt-Plan erstellt, damit du dich jederzeit retten und in Sicherheit bringen kannst."
                            },
                        },
                    ],

                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "FAQ"
                    },

                    faq_data: [
                        {
                            title: {
                                en: 'What happens after the 4 hour course?',
                                du: "Wat gebeurt er na de 4-uur durende cursus?",
                                gr: "Was passiert nach dem 4-Stunden-Kurs?"
                            },
                            info: {
                                en: 'You will practice as much as possible independently, together with another kiter. The wind often blows all day at our destinations, so there is plenty of time for everyone to spend hours on the water. Our team will supervise you during your practise and give you tips where needed.',
                                du: "Je oefent zoveel mogelijk zelfstandig, samen met een andere kiter. De wind waait vaak de hele dag op onze bestemmingen, dus er is voldoende tijd voor iedereen om uren op het water door te brengen. Ons team begeleidt je tijdens je oefening en geeft waar nodig tips.",
                                gr: "Du wirst so viel wie möglich unabhängig üben, zusammen mit einem anderen Kiter. Der Wind weht an unseren Zielen oft den ganzen Tag, sodass genügend Zeit für alle bleibt, Stunden auf dem Wasser zu verbringen. Unser Team wird dich während deines Trainings beaufsichtigen und dir bei Bedarf Tipps geben."
                            },
                        },
                        {

                            title: {
                                en: 'What happens if I have or want private lessons?',
                                du: "Wat gebeurt er als ik privélessen heb of wil?",
                                gr: "Was passiert, wenn ich Privatunterricht habe oder möchte?"
                            },
                            info: {
                                en: 'If it happens that the group lesson doesn’t suit you in terms of your level, you may receive private lessons. If you take private lessons, you will complete the course a little faster, but it is also a little more intensive. Our rule is that 7 hours of private lessons are equal to the nominal 9 hours of shared lessons. Do you really want private lessons? Please let us know in advance so that we can see what is possible.',
                                du: "Als de groepsles niet geschikt is voor jouw niveau, kun je privélessen krijgen. Als je privélessen volgt, rond je de cursus iets sneller af, maar het is ook iets intensiever. Onze regel is dat 7 uur privélessen gelijk zijn aan de nominale 9 uur gedeelde lessen. Wil je echt privélessen? Laat het ons van tevoren weten zodat we kunnen kijken wat mogelijk is.",
                                gr: "Wenn es passiert, dass der Gruppenunterricht nicht zu deinem Niveau passt, kannst du Privatstunden nehmen. Wenn du Privatstunden nimmst, wirst du den Kurs etwas schneller abschließen, aber es ist auch intensiver. Unsere Regel ist, dass 7 Stunden Privatunterricht den nominalen 9 Stunden Gruppenunterricht entsprechen. Möchtest du wirklich Privatstunden? Lass es uns im Voraus wissen, damit wir sehen können, was möglich ist."
                            },
                        },
                        {
                            title: {
                                en: 'What happens if I learn it much faster than the other participant?',
                                du: "Wat gebeurt er als ik het veel sneller leer dan de andere deelnemer?",
                                gr: "Was passiert, wenn ich es viel schneller lerne als der andere Teilnehmer?"
                            },
                            info: {
                                en: 'We may then reassign you to someone of the same level. Depending on the group, we can also decide that you get your own kite. This may incur additional costs.',
                                du: "We kunnen je dan indelen bij iemand van hetzelfde niveau. Afhankelijk van de groep kunnen we ook besluiten dat je je eigen kite krijgt. Dit kan extra kosten met zich meebrengen.",
                                gr: "Wir können dich dann jemandem auf dem gleichen Niveau zuordnen. Je nach Gruppe können wir auch entscheiden, dass du deinen eigenen Kite bekommst. Dies kann zusätzliche Kosten verursachen."
                            },

                        },

                        {

                            title: {
                                en: 'Am I insured?',
                                du: "Ben ik verzekerd?",
                                gr: "Bin ich versichert?"
                            },
                            info: {
                                en: 'Do you listen to your instructor’s instructions during our kite lesson? And does a kite break during the lesson? Then you are insured against this. Are you not listening to our instructor? For example, the instructor tells you to stay in a certain area and you keep moving on because you (quite understandably) like it so much? Then you are not insured if you break a kite. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports, to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!',
                                du: "Luister je naar de instructies van je instructeur tijdens onze kiteles? En breekt er een kite tijdens de les? Dan ben je hiervoor verzekerd. Luister je niet naar onze instructeur? Bijvoorbeeld, de instructeur vertelt je om in een bepaald gebied te blijven en je gaat toch verder omdat je het (begrijpelijkerwijs) zo leuk vindt? Dan ben je niet verzekerd als je een kite breekt. We raden je aan je reisverzekering goed te controleren om te zien of deze ook extreme sporten dekt, om teleurstelling en gedoe op vakantie te voorkomen. Wij werken samen met Sportzeker en dan ben je altijd overal voor verzekerd!",
                                gr: "Hörst du während unserer Kitesurfstunde auf die Anweisungen deines Lehrers? Und geht ein Kite während des Unterrichts kaputt? Dann bist du dagegen versichert. Hörst du nicht auf unseren Lehrer? Zum Beispiel sagt dir der Lehrer, dass du in einem bestimmten Bereich bleiben sollst, aber du gehst trotzdem weiter, weil es dir (verständlicherweise) so gut gefällt? Dann bist du nicht versichert, wenn du einen Kite beschädigst. Wir empfehlen dir, deine Reiseversicherung sorgfältig zu überprüfen, um sicherzustellen, dass sie auch Extremsportarten abdeckt, um Enttäuschungen und Ärger im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, und dann bist du für alles versichert!"
                            }

                        },



                    ],


                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: "Professioneel team tot uw dienst",
                                gr: "Professionelles Team zu Ihren Diensten"
                            },
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            },
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: "Boek vooruitgang in ideale omstandigheden!",
                                gr: "Mache Fortschritte unter idealen Bedingungen!"
                            },
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: "Vraag advies aan reisspecialist AJ",
                                gr: "Frage AJ, den Reiseexperten, um Rat"
                            },
                        },
                    ]
                },


                {
                    levelName: 'Level 2 - Waterstart',
                    desc: {
                        en: 'This is the perfect package if you have already had a kite course, but it has been a long time ago and you need to be refreshed. Or you are not yet completely independend and need an extra eye on you. Kitesurfing is not a sport that you can learn in 1 afternoon. That’s why our trips are perfect! We try to visit beginner friendly kitespots only for the weekend with people who also want to learn as much as possible. Hey ho, let’s go!',
                        du: "Dit is het perfecte pakket als je al een kitesurfles hebt gehad, maar het is lang geleden en je hebt een opfriscursus nodig. Of je bent nog niet helemaal zelfstandig en hebt extra begeleiding nodig. Kitesurfen is geen sport die je in één middag leert. Daarom zijn onze reizen perfect! We proberen voor het weekend alleen beginnersvriendelijke kitespots te bezoeken met mensen die ook zoveel mogelijk willen leren. Hey ho, laten we gaan!",
                        gr: "Dies ist das perfekte Paket, wenn du bereits einen Kitekurs absolviert hast, aber das ist lange her und du musst dein Wissen auffrischen. Oder du bist noch nicht vollständig unabhängig und brauchst ein zusätzliches Auge auf dich. Kitesurfen ist keine Sportart, die man an einem Nachmittag erlernen kann. Deshalb sind unsere Trips perfekt! Wir versuchen nur anfängerfreundliche Kitespots für das Wochenende zu besuchen mit Menschen, die auch so viel wie möglich lernen wollen. Hey ho, los geht's!"
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'A lot of practise with supervision',
                                du: "Veel oefenen onder begeleiding",
                                gr: "Viel Übung mit Aufsicht"
                            },
                            para: {
                                en: 'This intermediate package is the ideal package for kiters who need to get on board. We will help you get started and ensure that you can get on the water independently as a real kiter under good conditions, safely, and with great pleasure. A complete kite training to make you a real independent kiter in 1 weekend!',
                                du: "Dit tussenniveau pakket is het ideale pakket voor kiters die moeten leren opstappen. We helpen je op weg en zorgen ervoor dat je zelfstandig het water op kunt onder goede omstandigheden, veilig en met veel plezier. Een volledige kitesurftraining om je in één weekend een echte zelfstandige kiter te maken!",
                                gr: "Dieses fortgeschrittene Paket ist ideal für Kiter, die auf das Brett kommen müssen. Wir helfen dir beim Einstieg und sorgen dafür, dass du bei guten Bedingungen sicher und mit Freude eigenständig aufs Wasser kannst. Ein komplettes Kite-Training, um dich in einem Wochenende zu einem unabhängigen Kiter zu machen!"
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab2_2,
                            text: {
                                en: 'What do you learn?',
                                du: "Wat leer je?",
                                gr: "Was lernst du?"
                            },
                            para: {
                                en: 'After this weekend you can go on the water independently with your own kite set and the goal is that you can kite upwind so that you no longer have to walk! Our instructors ensure that you are in the right place with the right conditions, help you with tips and tricks, coach you, and keep an eye on things while you are practicing.',
                                du: "Na dit weekend kun je zelfstandig het water op met je eigen kiteset en het doel is dat je upwind kunt kitesurfen, zodat je niet meer hoeft te lopen! Onze instructeurs zorgen ervoor dat je op de juiste plek bent met de juiste omstandigheden, helpen je met tips en tricks, coachen je en houden een oogje in het zeil terwijl je oefent.",
                                gr: "Nach diesem Wochenende kannst du eigenständig mit deinem eigenen Kite-Set aufs Wasser gehen, und das Ziel ist es, dass du gegen den Wind kiten kannst, sodass du nicht mehr laufen musst! Unsere Instruktoren sorgen dafür, dass du am richtigen Ort mit den richtigen Bedingungen bist, helfen dir mit Tipps und Tricks, coachen dich und behalten alles im Auge, während du übst."
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: "Wat leer je?",
                        gr: "Was lernst du?"
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Good spot analysis and knowledge of kite material',
                                du: "Goede spotanalyse en kennis van kitemateriaal",
                                gr: "Gute Spotanalyse und Kenntnis des Kite-Materials"
                            },
                            info: {
                                en: 'Learn everything you need to know about the spot, forecast and your kite material',
                                du: "Leer alles wat je moet weten over de spot, de weersvoorspelling en je kitemateriaal",
                                gr: "Lerne alles, was du über den Spot, die Vorhersage und dein Kitematerial wissen musst"
                            }
                        },
                        {
                            title: {
                                en: 'Upwind riding and turns!',
                                du: "Upwind rijden en bochten maken!",
                                gr: "Kreuzen und Wenden!"
                            },
                            info: {
                                en: 'When riding upwind, you are finally an independend kiter!',
                                du: "Als je upwind kunt rijden, ben je eindelijk een zelfstandige kiter!",
                                gr: "Beim Fahren gegen den Wind bist du endlich ein unabhängiger Kiter!"
                            }
                        },
                        {
                            title: {
                                en: 'Refresh your safety skills',
                                du: "Vernieuw je veiligheidsvaardigheden",
                                gr: "Auffrischung deiner Sicherheitsfähigkeiten"
                            },
                            info: {
                                en: 'Do you know how to do a self rescue?',
                                du: "Weet je hoe je een zelfredding uitvoert?",
                                gr: "Weißt du, wie man eine Selbstrettung durchführt?"
                            }
                        },
                        {
                            title: {
                                en: 'Independence',
                                du: "Zelfstandigheid",
                                gr: "Unabhängigkeit"
                            },
                            info: {
                                en: 'Learn how to react on certain situations on and off the water. In this course we will try to make you as independend as possible and make sure you get the right amount of confidence to kite on your own on busy spots!',
                                du: "Leer hoe je op bepaalde situaties op en buiten het water reageert. In deze cursus proberen we je zo zelfstandig mogelijk te maken en ervoor te zorgen dat je voldoende zelfvertrouwen krijgt om zelfstandig op drukke spots te kitesurfen!",
                                gr: "Lerne, wie du auf bestimmte Situationen auf und außerhalb des Wassers reagieren kannst. In diesem Kurs werden wir versuchen, dich so unabhängig wie möglich zu machen und sicherzustellen, dass du genug Selbstvertrauen hast, um an vollen Spots alleine zu kiten!"
                            }
                        },
                        {
                            title: {
                                en: 'Safe starting and landing',
                                du: "Veilig starten en landen",
                                gr: "Sicheres Starten und Landen"
                            },
                            info: {
                                en: 'The most important about your kite career!',
                                du: "Het belangrijkste van je kitesurf carrière!",
                                gr: "Das Wichtigste in deiner Kitesurf-Karriere!"
                            }
                        },
                        {
                            title: {
                                en: 'Theory and Rules',
                                du: "Theorie en Regels",
                                gr: "Theorie und Regeln"
                            },
                            info: {
                                en: 'Everything you need to know',
                                du: "Alles wat je moet weten",
                                gr: "Alles, was du wissen musst"
                            }
                        },
                        {
                            title: {
                                en: 'Waterstart',
                                du: "Waterstart",
                                gr: "Wasserstart"
                            },
                            info: {
                                en: 'How do you perform the perfect waterstart?',
                                du: "Hoe voer je de perfecte waterstart uit?",
                                gr: "Wie machst du den perfekten Wasserstart?"
                            }
                        }
                    ],
                    faq_head: "FAQ",
                    faq_data: [
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: "Is kitemateriaal inbegrepen in dit pakket?",
                                gr: "Ist die Kiteausrüstung in diesem Paket enthalten?"
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 180 / weekend',
                                du: "Nee, je moet je eigen kitemateriaal meenemen of een complete set huren voor € 180 / weekend",
                                gr: "Nein, du musst deine eigene Kiteausrüstung mitbringen oder ein komplettes Set für 180 € / Wochenende mieten"
                            }
                        },
                        {
                            title: {
                                en: 'What happens if my level doen match with this course',
                                du: "Wat gebeurt er als mijn niveau niet overeenkomt met deze cursus",
                                gr: "Was passiert, wenn mein Niveau nicht zu diesem Kurs passt"
                            },
                            info: {
                                en: 'One person picks it up faster than the other and that is really no problem at all. If we see that you need extra lessons after the 9 hours, our instructor will take you aside and discuss with you how many hours he thinks you still need. Depending on whether other people in the group need extra lessons, the extra costs are: €60/hour for private lessons and €35/hour for group lessons.',
                                du: "De ene persoon pikt het sneller op dan de andere en dat is helemaal geen probleem. Als we zien dat je na de 9 uur nog extra lessen nodig hebt, neemt onze instructeur je apart en bespreekt hij hoeveel uur hij denkt dat je nog nodig hebt. Afhankelijk van of andere mensen in de groep extra lessen nodig hebben, zijn de extra kosten: € 60/uur voor privélessen en € 35/uur voor groepslessen.",
                                gr: "Die eine Person nimmt es schneller auf als die andere und das ist überhaupt kein Problem. Wenn wir sehen, dass du nach den 9 Stunden zusätzliche Stunden brauchst, wird unser Lehrer dich beiseite nehmen und mit dir besprechen, wie viele Stunden er denkt, dass du noch brauchst. Je nachdem, ob andere Personen in der Gruppe zusätzliche Stunden brauchen, betragen die zusätzlichen Kosten: 60 €/Stunde für Privatstunden und 35 €/Stunde für Gruppenstunden."
                            }
                        }
                    ],

                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: "Professioneel team tot uw dienst",
                                gr: "Professionelles Team zu Ihren Diensten"
                            },
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            },
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: "Boek vooruitgang in ideale omstandigheden!",
                                gr: "Mache Fortschritte unter idealen Bedingungen!"
                            },
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: "Vraag advies aan reisspecialist AJ",
                                gr: "Frage AJ, den Reiseexperten, um Rat"
                            },
                        },
                    ]

                },


                {
                    levelName: "Level 3 - Independend",
                    desc: {
                        en: "If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a weekend on the water with people who also want to make progress. Hey ho, Let’s go!",
                        du: "Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en daagt jezelf uit om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een weekend door op het water met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan!",
                        gr: "Wenn du mit Kitern auf dem Wasser bist, die das gleiche Niveau haben, machst du immer mehr Fortschritte. Ihr lernt voneinander und fordert euch heraus, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du ein Wochenende auf dem Wasser mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, los geht's!"
                    },
                    imgsect: [
                        {
                            imgSrc: tab3_1,
                            text: {
                                en: "Learn new tricks",
                                du: "Leer nieuwe trucs",
                                gr: "Neue Tricks lernen"
                            },
                            para: {
                                en: "This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!",
                                du: "Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste sprong tot een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!",
                                gr: "Dies ist das ultimative Paket für alle, die bereits kitesurfen können, aber Fortschritte machen und neue Tricks lernen wollen. Vom ersten Sprung bis zum Kiteloop Handle Pass. Unser Team steht bereit, dir mit den richtigen Tipps und Tricks zu helfen, um deine Ziele zu erreichen!"
                            },
                            imgPosition: "left"
                        },
                        {
                            imgSrc: tab3_2,
                            text: {
                                en: "What to expect from this course",
                                du: "Wat je van deze cursus kunt verwachten",
                                gr: "Was du von diesem Kurs erwarten kannst"
                            },
                            para: {
                                en: "During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.",
                                du: "Tijdens de cursus kijken we naar wat je persoonlijke doelen zijn en wat haalbaar is. We doorlopen samen de theorie en tijdens de week proberen we je zoveel mogelijk te pushen, coachen en motiveren om op een veilige manier nieuwe dingen te proberen.",
                                gr: "Im Kurs schauen wir uns deine persönlichen Ziele an und was machbar ist. Wir gehen gemeinsam die Theorie durch und während der Woche versuchen wir, dich so gut wie möglich zu pushen, zu coachen und zu motivieren, neue Dinge sicher auszuprobieren."
                            },
                            imgPosition: "right"
                        }
                    ],
                    acordation_head: {
                        en: "What do you learn?",
                        du: "Wat leer je?",
                        gr: "Was lernst du?"
                    },
                    acordation_data: [
                        {
                            title: {
                                en: "   ",
                                du: "Leer de basis van pop",
                                gr: "Lerne die Grundlagen des Pops"
                            },
                            info: {
                                en: "Learn how to pop. This is a very important skill which you need for your entire kite career!",
                                du: "Leer hoe je kunt poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitecarrière!",
                                gr: "Lerne, wie man popt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kitekarriere benötigst!"
                            }
                        },
                        {
                            title: {
                                en: "Your first downwinder",
                                du: "Je eerste downwinder",
                                gr: "Dein erster Downwinder"
                            },
                            info: {
                                en: "With a downwinder you are doing a kite tour along the coast!",
                                du: "Met een downwinder maak je een kitetour langs de kust!",
                                gr: "Mit einem Downwinder machst du eine Kitetour entlang der Küste!"
                            }
                        },
                        {
                            title: {
                                en: "Your first jump",
                                du: "Je eerste sprong",
                                gr: "Dein erster Sprung"
                            },
                            info: {
                                en: "You want to learn how to go skyhigh?",
                                du: "Wil je leren hoe je skyhigh gaat?",
                                gr: "Möchtest du lernen, wie man in die Höhe geht?"
                            }
                        },
                        {
                            title: {
                                en: "Back & Frontroll",
                                du: "Back & Frontroll",
                                gr: "Back- & Frontroll"
                            },
                            info: {
                                en: "Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!",
                                du: "Maak een salto in de lucht! Je denkt dat het moeilijk is, maar wij leren je deze geweldige truc in 1 week!",
                                gr: "Mache einen Salto in der Luft! Du denkst, es ist schwierig, aber wir bringen dir diesen fantastischen Trick in 1 Woche bei!"
                            }
                        },
                        {
                            title: {
                                en: "board grabs, onefooters and board-offs",
                                du: "boardgrabs, onefooters en board-offs",
                                gr: "Boardgrabs, Onefooters und Board-Offs"
                            },
                            info: {
                                en: "Grab the board in front of the camera!!",
                                du: "Pak het board voor de camera!!",
                                gr: "Greife das Board vor der Kamera!!"
                            }
                        },
                        {
                            title: {
                                en: "Your first kiteloop (downloop)",
                                du: "Je eerste kiteloop (downloop)",
                                gr: "Dein erster Kiteloop (Downloop)"
                            },
                            info: {
                                en: "Fly like a rollercoaster :)",
                                du: "Vlieg als een achtbaan :)",
                                gr: "Flieg wie eine Achterbahn :)"
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "FAQ"
                    },
                    faq_data: [
                        {
                            title: {
                                en: "How many hours include this course?",
                                du: "Hoeveel uren bevat deze cursus?",
                                gr: "Wie viele Stunden umfasst dieser Kurs?"
                            },
                            info: {
                                en: "We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.",
                                du: "We koppelen geen exacte uren aan dit pakket. We proberen iedereen zoveel mogelijk te pushen en bieden af en toe 1-op-1 begeleiding. Het doel is om zoveel mogelijk vooruitgang te boeken en tijdens deze cursus geven we je alle tools die je nodig hebt om te blijven oefenen.",
                                gr: "Wir verknüpfen keine genauen Stunden mit diesem Paket. Wir versuchen, jeden so viel wie möglich zu pushen und gelegentlich 1-zu-1-Betreuung zu bieten. Das Ziel ist, so viel wie möglich Fortschritte zu machen, und während dieses Kurses geben wir dir alle Werkzeuge, die du brauchst, um weiter zu üben."
                            }
                        },
                        {
                            title: {
                                en: "Is kitegear included in this package?",
                                du: "Is kitegear inbegrepen in dit pakket?",
                                gr: "Ist Kiteausrüstung in diesem Paket enthalten?"
                            },
                            info: {
                                en: "No, you need to bring your own kitegear or rent a complete set at € 399 / week.",
                                du: "Nee, je moet je eigen kitegear meenemen of een complete set huren voor € 399 / week.",
                                gr: "Nein, du musst deine eigene Kiteausrüstung mitbringen oder ein komplettes Set für 399 €/Woche mieten."
                            }
                        },
                        {
                            title: {
                                en: "Am I Insured?",
                                du: "Ben ik verzekerd?",
                                gr: "Bin ich versichert?"
                            },
                            info: {
                                en: "You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!",
                                du: "Je bent niet verzekerd tegen schade via KiteActive. We raden aan om je reisverzekering zorgvuldig te controleren om te zien of deze ook extreme sporten dekt. Zo voorkom je teleurstellingen en gedoe op vakantie. We werken samen met Sportzeker en dan ben je altijd voor alles verzekerd!",
                                gr: "Du bist nicht über KiteActive gegen Schäden versichert. Wir empfehlen dir, deine Reiseversicherung sorgfältig zu überprüfen, ob sie auch Extremsportarten abdeckt, um Enttäuschungen und Probleme im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, sodass du immer für alles versichert bist!"
                            }
                        }
                    ],

                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team at your service",
                                du: "Professioneel team tot uw dienst",
                                gr: "Professionelles Team zu Ihren Diensten"
                            },
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            },
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Make progress in ideal conditions!",
                                du: "Boek vooruitgang in ideale omstandigheden!",
                                gr: "Mache Fortschritte unter idealen Bedingungen!"
                            },
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Ask advice from travel expert AJ",
                                du: "Vraag advies aan reisspecialist AJ",
                                gr: "Frage AJ, den Reiseexperten, um Rat"
                            },
                        },
                    ]

                }
            ],

            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kitegear verhuur",
                    gr: "Kite-Ausrüstung Vermietung"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem! You can rent a complete kiteset for € 180 / weekend.",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en we zorgen ervoor dat je altijd op het water bent met de juiste maat kite. Geen zin om al je materialen mee te nemen? Geen probleem! Je kunt een complete kiteset huren voor € 180 / weekend.",
                    gr: "Bei uns findest du das neueste Kite-Material. Wir haben die meisten Marken verfügbar und stellen sicher, dass du immer mit der richtigen Kite-Größe auf dem Wasser bist. Keine Lust, all dein Material mitzunehmen? Kein Problem! Du kannst ein komplettes Kiteset für 180 € / Wochenende mieten."
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree.",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree.",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree."
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us. You can use the outstanding amount in our webshop on (kite) gear.",
                    du: "Je zorgt ervoor dat je je juiste niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt voltooien, kun je het openstaande bedrag op verschillende manieren bij ons verrekenen. Je kunt het openstaande bedrag gebruiken in onze webshop voor (kite)materiaal.",
                    gr: "Du stellst sicher, dass du dein korrektes Niveau während deiner Buchung angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den ausstehenden Betrag auf verschiedene Weise bei uns begleichen. Du kannst den ausstehenden Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden."
                },
                para2: {
                    en: "Make sure you get your insurance in order if you break your own or our material. Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Zorg ervoor dat je je verzekering op orde hebt als je je eigen of ons materiaal breekt. Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je het kitesurfen bij ons zo veilig mogelijk leert, moet je altijd op jezelf en anderen letten. Luister altijd goed naar de instructies van ons team.",
                    gr: "Stelle sicher, dass du deine Versicherung in Ordnung bringst, wenn du dein eigenes oder unser Material brichst. Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport und obwohl wir sicherstellen, dass du das Kitesurfen bei uns so sicher wie möglich lernst, musst du dir immer deiner selbst und anderer bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }
        },

        activities: [
            {
                img: zanzibar_activity_img1,
                title: {
                    en: 'Awesome Evenings',
                    du: 'Geweldige Avonden',
                    gr: 'Tolle Abende'
                }
            },
            {
                img: zanzibar_activity_img2,
                title: {
                    en: 'Wave Surfing',
                    du: 'Golfsurfen',
                    gr: 'Wellenreiten'
                }
            },
            {
                img: zanzibar_activity_img3,
                title: {
                    en: 'Down winders',
                    du: 'Downwinders',
                    gr: 'Downwinders'
                }
            },
            {
                img: zanzibar_activity_img4,
                title: {
                    en: 'Climbing',
                    du: 'Klimmen',
                    gr: 'Klettern'
                }
            },
            {
                img: zanzibar_activity_img5,
                title: {
                    en: 'Funbox - beach Games',
                    du: 'Funbox - strandspellen',
                    gr: 'Funbox - Strandspiele'
                }
            }
        ],

        hosted: [
            {
                hosted_img: Snow_hosted_img1,
                hosted_name: {
                    en: "Annemaj Muiser",
                    gr: "Annemaj Muiser",
                    du: "Annemaj Muiser"
                },
                hosted_position: {
                    en: "Photographer & Instructor",
                    gr: "Fotograf & Instruktor",
                    du: "Fotograaf & Instructeur"
                },
                kite_exp: {
                    en: "6 years kite experience",
                    gr: "6 Jahre Kiterfahrung",
                    du: "6 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "I love shooting pictures and teaching other people our amazing sport. I will make sure you have the perfect kite picture at the end of the week :)",
                    gr: "Ich liebe es, Bilder zu machen und anderen Menschen unseren großartigen Sport beizubringen. Ich werde dafür sorgen, dass du am Ende der Woche das perfekte Kite-Bild hast :)",
                    du: "Ik hou ervan om foto's te maken en andere mensen onze geweldige sport te leren. Ik zorg ervoor dat je aan het einde van de week de perfecte kitesurffoto hebt :)"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "Zanzibar",
                    gr: "Zanzibar",
                    du: "Zanzibar"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "6 years",
                    gr: "6 Jahre",
                    du: "6 jaar"
                }
            },
            {
                hosted_img: Snow_hosted_img2,
                hosted_name: {
                    en: "Felix Maks",
                    gr: "Felix Maks",
                    du: "Felix Maks"
                },
                hosted_position: {
                    en: "Camp manager & Instructor",
                    gr: "Camp-Leiter & Instruktor",
                    du: "Campmanager & Instructeur"
                },
                kite_exp: {
                    en: "16 years kite experience",
                    gr: "16 Jahre Kiterfahrung",
                    du: "16 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "After competing in the world tour I decided that I wanted to share my passion with other people. Now we travel the world and enjoy the ride!",
                    gr: "Nach meiner Teilnahme an der Weltmeisterschaft entschied ich mich, meine Leidenschaft mit anderen zu teilen. Jetzt reisen wir um die Welt und genießen die Fahrt!",
                    du: "Na deelname aan de wereldtour besloot ik mijn passie met andere mensen te delen. Nu reizen we de wereld rond en genieten we van de rit!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favorite Destination",
                    gr: "Lieblingsziel",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "Zanzibar and the Dutch islands",
                    gr: "Zanzibar und die niederländischen Inseln",
                    du: "Zanzibar en de Nederlandse eilanden"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "16 years",
                    gr: "16 Jahre",
                    du: "16 jaar"
                }
            },
            {
                hosted_img: Snow_hosted_img3,
                hosted_name: {
                    en: "Roderick Pijls",
                    gr: "Roderick Pijls",
                    du: "Roderick Pijls"
                },
                hosted_position: {
                    en: "",
                    gr: "",
                    du: ""
                },
                kite_exp: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_para: {
                    en: "Having a background of professional kitesurfer for over 12 years has taught me a lot. Seeing many cultures, meeting great people and knowing everything from the best spots in the world has convinced me to start sharing this with everyone who has that little 'Columbus' inside him/ herself.",
                    gr: "Ein Hintergrund als professioneller Kitesurfer seit über 12 Jahren hat mir viel beigebracht. Viele Kulturen zu sehen, großartige Menschen zu treffen und alles über die besten Spots der Welt zu wissen, hat mich überzeugt, dies mit jedem zu teilen, der das kleine 'Kolumbus' in sich trägt.",
                    du: "Een achtergrond als professionele kitesurfer gedurende meer dan 12 jaar heeft me veel geleerd. Het zien van veel culturen, het ontmoeten van geweldige mensen en alles weten over de beste spots ter wereld heeft me ervan overtuigd dit te delen met iedereen die dat kleine 'Columbus'-gevoel in zich heeft."
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "",
                    gr: "",
                    du: ""
                }
            },
            {
                hosted_img: Snow_hosted_img4,
                hosted_name: {
                    en: "Jan Willem",
                    gr: "Jan Willem",
                    du: "Jan Willem"
                },
                hosted_position: {
                    en: "",
                    gr: "",
                    du: ""
                },
                kite_exp: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_para: {
                    en: "As a Gym teacher my job is to motivate people to do activities and sports. Kitesurfing is my biggest passion and I will teach you everything I know!",
                    gr: "Als Sportlehrer ist es mein Job, Menschen zu motivieren, Aktivitäten und Sport zu treiben. Kitesurfen ist meine größte Leidenschaft und ich werde dir alles beibringen, was ich weiß!",
                    du: "Als gymdocent is het mijn taak om mensen te motiveren om activiteiten en sporten te doen. Kitesurfen is mijn grootste passie en ik zal je alles leren wat ik weet!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "",
                    gr: "",
                    du: ""
                }
            },
        ],
        reviews: {}
    },




    // Sky high capetown trip data ..................

    {
        name: 'skyCapTown',
        vedio: skyCapTown_vedio,
        backcover: skycaptowncover,
        alltabs: {
            highlight: [
                {
                    text: {
                        en: "Progress with professional coaches",
                        gr: "Fortschritte mit professionellen Trainern",
                        du: "Vooruitgang met professionele coaches"
                    }
                },
                {
                    text: {
                        en: "Amazing downwinders and day adventures",
                        gr: "Erstaunliche Downwinder und Tagesabenteuer",
                        du: "Geweldige downwinders en dagavonturen"
                    }
                },
                {
                    text: {
                        en: "Delicious food",
                        gr: "Leckeres Essen",
                        du: "Heerlijk eten"
                    }
                },
                {
                    text: {
                        en: "Explore new places and kitespots with the KiteActive crew",
                        gr: "Entdecke neue Orte und Kitespots mit der KiteActive-Crew",
                        du: "Ontdek nieuwe plekken en kitespots met de KiteActive-crew"
                    }
                },
                {
                    text: {
                        en: "Optional cultural activities (hiking/ wine tasting/ Cape of Good Hope/ etc..)",
                        gr: "Optionale kulturelle Aktivitäten (Wandern/ Weinprobe/ Kap der Guten Hoffnung/ usw.)",
                        du: "Optionele culturele activiteiten (wandelen/ wijnproeven/ Kaap de Goede Hoop/ etc..)"
                    }
                }
            ],
            question: [
                {
                    title: {
                        en: "How is the group?",
                        gr: "Wie ist die Gruppe?",
                        du: "Hoe is de groep?"
                    },
                    para: {
                        en: "Unfortunately not. But we will give you the best advice to make sure you book the correct flight tickets.",
                        gr: "Leider nicht. Aber wir geben dir die besten Tipps, damit du die richtigen Flugtickets buchst.",
                        du: "Helaas niet. Maar we geven je het beste advies om ervoor te zorgen dat je de juiste vliegtickets boekt."
                    }
                },
                {
                    title: {
                        en: "Do you also arrange flights?",
                        gr: "Organisiert ihr auch Flüge?",
                        du: "Regelen jullie ook vluchten?"
                    },
                    para: {
                        en: "Unfortunately not. But we will give you the best advice to make sure you book the correct flight tickets.",
                        gr: "Leider nicht. Aber wir geben dir die besten Tipps, damit du die richtigen Flugtickets buchst.",
                        du: "Helaas niet. Maar we geven je het beste advies om ervoor te zorgen dat je de juiste vliegtickets boekt."
                    }
                },
                {
                    title: {
                        en: "How is the wind? What kite material is needed?",
                        gr: "Wie ist der Wind? Welches Kitematerial wird benötigt?",
                        du: "Hoe is de wind? Welk kitemateriaal is nodig?"
                    },
                    para: {
                        en: "Normally the wind blows 13 to 30 knots so the golden rule bring all sizes of kites!",
                        gr: "Normalerweise weht der Wind mit 13 bis 30 Knoten, also lautet die goldene Regel: Bringe alle Größen von Kites mit!",
                        du: "Normaal gesproken waait de wind 13 tot 30 knopen, dus de gouden regel: neem alle maten kites mee!"
                    }
                },
                {
                    title: {
                        en: "Can I easily bring a boardbag?",
                        gr: "Kann ich problemlos eine Boardtasche mitbringen?",
                        du: "Kan ik gemakkelijk een boardtas meenemen?"
                    },
                    para: {
                        en: "Yes! If you fly with KLM you can easily add an extra piece of luggage to your reservation.",
                        gr: "Ja! Wenn du mit KLM fliegst, kannst du problemlos ein zusätzliches Gepäckstück zu deiner Reservierung hinzufügen.",
                        du: "Ja! Als je met KLM vliegt, kun je gemakkelijk een extra stuk bagage toevoegen aan je reservering."
                    }
                }
            ]
        },
        overview: {
            trip_heading: {
                en: "Skyhigh in Cape Town: Elite Kitesurfing Experience in Cape Town",
                gr: "Skyhigh in Kapstadt: Elite Kitesurfing Erlebnis in Kapstadt",
                du: "Skyhigh in Kaapstad: Elite Kitesurfing Ervaring in Kaapstad"
            },
            trip_para: {
                en: "Get ready for an epic adventure at the Skyhigh Kitesurf Camp in Cape Town with KiteActive, designed exclusively for advanced kiters! Enjoy top-notch coaching, tackle some of the best kitesurfing spots, and use the latest gear. Cape Town’s stunning beaches and perfect wind conditions make it a kitesurfer's paradise. Off the water, chill with new friends, explore the vibrant local scene, and soak in the breathtaking views of Table Mountain. It’s all about great vibes, challenging rides, and unforgettable memories. Don’t miss out – join us for the ultimate kitesurfing experience!",
                gr: "Mach dich bereit für ein episches Abenteuer im Skyhigh Kitesurf Camp in Kapstadt mit KiteActive, exklusiv für fortgeschrittene Kitesurfer! Genieße erstklassiges Coaching, erkunde die besten Kitesurf-Spots und nutze die neueste Ausrüstung. Die atemberaubenden Strände und perfekten Windbedingungen Kapstadts machen es zum Paradies für Kitesurfer. Abseits des Wassers kannst du mit neuen Freunden entspannen, die lebendige lokale Szene erkunden und den atemberaubenden Blick auf den Tafelberg genießen. Es geht um tolle Vibes, herausfordernde Fahrten und unvergessliche Erinnerungen. Verpasse es nicht – schließe dich uns für das ultimative Kitesurferlebnis an!",
                du: "Bereid je voor op een episch avontuur bij het Skyhigh Kitesurf Camp in Kaapstad met KiteActive, exclusief ontworpen voor gevorderde kiters! Geniet van eersteklas coaching, bedwing de beste kitesurfspots en maak gebruik van de nieuwste uitrusting. De prachtige stranden en perfecte windcondities van Kaapstad maken het tot een paradijs voor kitesurfers. Buiten het water kun je ontspannen met nieuwe vrienden, de bruisende lokale scène verkennen en genieten van het adembenemende uitzicht op de Tafelberg. Het draait allemaal om geweldige vibes, uitdagende ritten en onvergetelijke herinneringen. Mis het niet – sluit je bij ons aan voor de ultieme kitesurfervaring!"
            },
            img1: surf_img9,
            img1_head: {
                en: "Dates 2025",
                gr: "Termine 2025",
                du: "Data 2025"
            },
            img1_para: {
                en: "15 February - 1 March 2025",
                gr: "15. Februar - 1. März 2025",
                du: "15 februari - 1 maart 2025"
            },
            img2: surf_img10,
            img2_head: {
                en: "Skills",
                gr: "Fähigkeiten",
                du: "Vaardigheden"
            },
            img2_para: {
                en: "Level 3 - 4 | Independend / Advanced",
                gr: "Level 3 - 4 | Unabhängig / Fortgeschritten",
                du: "Niveau 3 - 4 | Onafhankelijk / Gevorderd"
            },
            img3: surf_img11,
            img3_head: {
                en: "Join KiteActive",
                gr: "Schließe dich KiteActive an",
                du: "Doe mee met KiteActive"
            },
            img3_para: {
                en: "Good vibes only. Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "Nur gute Vibes. Trete dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / dem KA-Team zu chatten.",
                du: "Alleen goede vibes. Doe mee met de groepschat om op de hoogte te blijven of te chatten met andere reizigers / het KA-team."
            },
            img4: surf_img12,
            img4_head: {
                en: "Early bird",
                gr: "Frühbucher",
                du: "Vroege vogel"
            },
            img4_para: {
                en: "Book before 15 October and get € 50 discount",
                gr: "Buche vor dem 15. Oktober und erhalte einen Rabatt von € 50",
                du: "Boek voor 15 oktober en ontvang € 50 korting"
            },
            gall_img1: skycap_OW_gall_img1,
            gall_img2: skycap_OW_gall_img2,
            gall_img3: skycap_OW_gall_img3,
            gall_img4: skycap_OW_gall_img4,
        },
        kitespot: [
            {
                imgSrc: skycap_kitespotimg1,
                text: {
                    en: 'big bay with the table mountain',
                    du: 'Big Bay met de Tafelberg',
                    gr: 'Big Bay mit dem Tafelberg'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: "Cape Town offers a variety of world-class kitesurfing spots perfect for advanced riders. Bloubergstrand, known for its powerful winds and stunning views of Table Mountain, is a top favorite. Big Bay offers consistent swells and is great for wave riding. Langebaan Lagoon, with its flat water and strong winds, provides ideal conditions for freestyle tricks. And then we even haven’t talked about Hermanus/Brandvlei or Witsands. Each spot offers unique challenges and breathtaking scenery, from the rugged coastline to the pristine beaches. Off the water, enjoy Cape Town’s vibrant culture, delicious cuisine, and diverse wildlife. The combination of excellent kitesurfing spots and stunning environment makes Cape Town a kitesurfing paradise, or as many call it, a kitesurf Cape Town haven.",
                    du: "Kaapstad biedt een verscheidenheid aan kitesurfspots van wereldklasse, perfect voor gevorderde kitesurfers. Bloubergstrand, bekend om zijn krachtige wind en adembenemende uitzichten op de Tafelberg, is een topfavoriet. Big Bay biedt consistente golven en is geweldig voor golfrijden. Langebaan Lagoon, met zijn vlakke water en sterke wind, biedt ideale omstandigheden voor freestyle-trucs. En dan hebben we het nog niet eens gehad over Hermanus/Brandvlei of Witsands. Elke spot biedt unieke uitdagingen en een adembenemend landschap, van de ruige kustlijn tot de ongerepte stranden. Buiten het water kun je genieten van de levendige cultuur, heerlijke keuken en diverse dierenwereld van Kaapstad. De combinatie van uitstekende kitesurfspots en een prachtige omgeving maakt Kaapstad een kitesurfparadijs, of zoals velen zeggen, een kitesurf-hotspot."
                },
                imgPosition: 'left',
            },
            {
                imgSrc: skycap_kitespotimg2,
                text: {
                    en: 'So much more…',
                    du: 'En zoveel meer...',
                    gr: 'Und so viel mehr…'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: "Next to amazing kitesurfing in Cape Town, the city has so much more to offer. Take a drive along Chapman’s Peak, join us for visits to the best vineyards and tastings in the country, or enjoy a sunset dinner at the many food markets Cape Town has to offer. Our guides are well equipped with many experiences and knowledge about the most amazing places. We will take you to all the hotspots.",
                    du: "Naast geweldig kitesurfen in Kaapstad heeft de stad nog zoveel meer te bieden. Maak een rit langs Chapman's Peak, ga mee voor bezoeken aan de beste wijngaarden en proeverijen van het land, of geniet van een diner bij zonsondergang op de vele foodmarkten die Kaapstad te bieden heeft. Onze gidsen zijn goed uitgerust met veel ervaring en kennis over de meest geweldige plekken. We nemen je mee naar alle hotspots.",
                    gr: "Neben dem fantastischen Kitesurfen in Kapstadt hat die Stadt noch so viel mehr zu bieten. Fahren Sie entlang des Chapman's Peak, besuchen Sie mit uns die besten Weingüter und Verkostungen des Landes oder genießen Sie ein Abendessen bei Sonnenuntergang auf einem der vielen Food-Märkte, die Kapstadt zu bieten hat. Unsere Guides verfügen über umfangreiche Erfahrungen und Kenntnisse der erstaunlichsten Orte. Wir bringen Sie zu allen Hotspots."
                },
                imgPosition: 'right',
            }
        ],

        packages: {
            levelsData: [
                {
                    levelName: 'Level 3 - Independent',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk aangemoedigd en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Learn new tricks and make progression',
                                du: 'Leer nieuwe trucs en maak vooruitgang',
                                gr: 'Lerne neue Tricks und mache Fortschritte'
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: 'Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste spron naar een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!',
                                gr: 'Dies ist das ultimative Paket für jeden, der bereits kitesurfen kann, aber Fortschritte machen und neue Tricks lernen möchte. Von deinem allerersten Sprung bis zum Kiteloop-Handle-Pass. Unser Team ist bereit, dir mit den richtigen Tipps und Tricks zu helfen, um deine Ziele zu erreichen!'
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je van deze cursus verwachten?',
                                gr: 'Was kannst du von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: 'Tijdens de cursus bekijken we wat je persoonlijke doelen zijn en wat haalbaar is. We gaan samen de theorie door en proberen je gedurende de week zoveel mogelijk te stimuleren, coachen en motiveren om nieuwe dingen op een veilige manier te proberen.',
                                gr: 'Während des Kurses werden wir uns ansehen, was deine persönlichen Ziele sind und was machbar ist. Wir gehen gemeinsam die Theorie durch und versuchen während der Woche, dich so gut wie möglich zu pushen, zu coachen und zu motivieren, um neue Dinge auf sichere Weise auszuprobieren.'
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'The basics of pop',
                                du: 'De basis van pop',
                                gr: 'Die Grundlagen des Pops'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitecarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kitesurfkarriere benötigst!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first jump',
                                du: 'Je eerste spron',
                                gr: 'Dein erster Sprung'
                            },
                            info: {
                                en: 'You want to learn how to go skyhigh?',
                                du: 'Wil je leren hoe je de lucht in kunt gaan?',
                                gr: 'Möchtest du lernen, wie du in die Höhe springen kannst?'
                            }
                        },
                        {
                            title: {
                                en: 'Back & Front roll',
                                du: 'Back & Front roll',
                                gr: 'Back & Frontroll'
                            },
                            info: {
                                en: 'Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!',
                                du: 'Maak een salto in de lucht! Je denkt dat het moeilijk is, maar we leren je deze geweldige truc in 1 week!',
                                gr: 'Mach einen Salto in der Luft! Du denkst, es ist schwierig, aber wir werden dir diesen erstaunlichen Trick in 1 Woche beibringen!'
                            }
                        },
                        {
                            title: {
                                en: 'Board grabs, onefooters and board-offs',
                                du: 'Board grabs, onefooters en board-offs',
                                gr: 'Board Grabs, Onefooters und Board-offs'
                            },
                            info: {
                                en: 'Grab the board in front of the camera!',
                                du: 'Pak het board voor de camera!',
                                gr: 'Greife das Board vor der Kamera!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first kiteloop (downloop)',
                                du: 'Je eerste kiteloop (downloop)',
                                gr: 'Dein erster Kiteloop (Downloop)'
                            },
                            info: {
                                en: 'Fly like a rollercoaster',
                                du: 'Vlieg als een achtbaan',
                                gr: 'Flieg wie eine Achterbahn'
                            }
                        },
                        {
                            title: {
                                en: 'Your first downwinder',
                                du: 'Je eerste downwinder',
                                gr: 'Dein erster Downwinder'
                            },
                            info: {
                                en: 'With a downwinder you are doing a kite tour along the coast!',
                                du: 'Met een downwinder maak je een kitetour langs de kust!',
                                gr: 'Mit einem Downwinder machst du eine Kitetour entlang der Küste!'
                            }
                        },
                        {
                            title: {
                                en: 'Learn how to ride strapless / bottom turns / wave skills',
                                du: 'Leer hoe je strapless kunt rijden / bottom turns / golfvaardigheden',
                                gr: 'Lerne, wie man strapless fährt / Bottom Turns / Wellenfähigkeiten'
                            },
                            info: {
                                en: 'Upgrade your skills in the waves',
                                du: 'Verbeter je vaardigheden in de golven',
                                gr: 'Verbessere deine Fähigkeiten in den Wellen'
                            }
                        },
                        {
                            title: {
                                en: 'Get unhooked!',
                                du: 'Ga los!',
                                gr: 'Lass los!'
                            },
                            info: {
                                en: 'Learn how to do unhooked tricks like Raily, S-bend or maybe even a handle pass?',
                                du: 'Leer hoe je unhooked trucs doet zoals Raily, S-bend of misschien zelfs een handle pass?',
                                gr: 'Lerne, wie man unhooked Tricks wie Raily, S-bend oder vielleicht sogar einen Handle Pass macht?'
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.',
                                du: 'We linken geen exacte uren aan dit pakket. We proberen iedereen zoveel mogelijk te stimuleren en bieden af en toe 1-op-1 begeleiding. Het doel is om zoveel mogelijk vooruitgang te boeken en tijdens deze cursus geven we je alle tools die je nodig hebt om te blijven oefenen.',
                                gr: 'Wir verlinken keine genauen Stunden mit diesem Paket. Wir versuchen, jeden so gut wie möglich zu unterstützen und bieten gelegentlich 1-zu-1-Begleitung an. Ziel ist es, so viel Fortschritt wie möglich zu machen, und während dieses Kurses geben wir dir alle Werkzeuge, die du brauchst, um weiter zu üben.'
                            }
                        },
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: 'Is kitemateriaal inbegrepen bij dit pakket?',
                                gr: 'Ist Kitematerial in diesem Paket enthalten?'
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 399 / week',
                                du: 'Nee, je moet je eigen kitemateriaal meenemen of een compleet set huren voor € 399 / week',
                                gr: 'Nein, du musst dein eigenes Kitematerial mitbringen oder ein komplettes Set für 399 € / Woche mieten.'
                            }
                        },
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!',
                                du: 'Je bent niet verzekerd tegen schade via KiteActive. We raden je aan om je reisverzekering goed te controleren om te zien of deze ook extreme sporten dekt. Dit om teleurstellingen en gedoe op vakantie te voorkomen. We werken samen met Sportzeker en dan ben je altijd voor alles verzekerd!',
                                gr: 'Du bist nicht gegen Schäden durch KiteActive versichert. Wir empfehlen, deine Reiseversicherung sorgfältig zu prüfen, um festzustellen, ob sie auch Extremsportarten abdeckt. Um Enttäuschungen und Probleme im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, und dann bist du immer für alles versichert!'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team",
                                du: "Professioneel team",
                                gr: "Professionelles Team"
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Get advice from travel expert AJ",
                                du: "Krijg advies van reisspecialist AJ",
                                gr: "Hol dir Ratschläge vom Reiseexperten AJ"
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: "Foto- en video bewijs / analyse",
                                gr: "Foto- und Video-Nachweis / Analyse"
                            }
                        }
                    ]
                },

                {
                    levelName: 'Level 4 - Advanced',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'Level up! There is still so much to learn',
                                du: 'Level omhoog! Er is nog zoveel te leren',
                                gr: 'Level hoch! Es gibt noch so viel zu lernen'
                            },
                            para: {
                                en: 'Do you want to improve in big air kiting? Going for that megaloop? Or do you really want to learn this sick handle pass? This course is also for waveriders who are able to do long downwinders in challenging conditions and who want to learn to perform better in the waves.',
                                du: 'Wil je verbeteren in big air kiten? Ga je voor die megaloop? Of wil je echt deze geweldige handle pass leren? Deze cursus is ook voor golfrijders die in staat zijn om lange downwinders in uitdagende omstandigheden te maken en die beter willen presteren in de golven.',
                                gr: 'Möchtest du im Big Air Kiten besser werden? Gehst du für den Megaloop? Oder möchtest du wirklich diesen coolen Handle Pass lernen? Dieser Kurs ist auch für Wellenreiter, die in der Lage sind, lange Downwinders unter herausfordernden Bedingungen zu machen und die lernen möchten, in den Wellen besser abzuschneiden.'
                            },
                            imgPosition: 'left'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Long downwinders in challenging conditions',
                                du: 'Lange downwinders in uitdagende omstandigheden',
                                gr: 'Lange Downwinders unter herausfordernden Bedingungen'
                            },
                            info: {
                                en: 'Like DEN TVTAS for example',
                                du: 'Zoals DEN TVTAS bijvoorbeeld',
                                gr: 'Wie DEN TVTAS zum Beispiel'
                            }
                        },
                        {
                            title: {
                                en: 'Jump 10 meter +',
                                du: 'Spring 10 meter +',
                                gr: 'Spring 10 Meter +'
                            },
                            info: {
                                en: 'Do you want to go skyhigh with us?',
                                du: 'Wil je met ons de lucht in?',
                                gr: 'Möchtest du mit uns in die Höhe springen?'
                            }
                        },
                        {
                            title: {
                                en: 'Megaloop',
                                du: 'Megaloop',
                                gr: 'Megaloop'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        },
                        {
                            title: {
                                en: 'Unhooked tricks',
                                du: 'Unhooked trucs',
                                gr: 'Unhooked Tricks'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'No, not with us. You need to take care of your own travel insurance. If you want, we can help you find the right one!',
                                du: 'Nee, niet bij ons. Je moet zelf zorgen voor je reisverzekering. Als je wilt, kunnen we je helpen de juiste te vinden!',
                                gr: 'Nein, nicht bei uns. Du musst dich um deine eigene Reiseversicherung kümmern. Wenn du möchtest, können wir dir helfen, die richtige zu finden!'
                            }
                        },
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'No specific hours are attached to this course. From time to time we do 1-on-1 sessions and our team will do our best to teach you as much as possible during this week.',
                                du: 'Er zijn geen specifieke uren aan deze cursus verbonden. Af en toe doen we 1-op-1 sessies en ons team zal ons best doen om je deze week zoveel mogelijk te leren.',
                                gr: 'Es sind keine spezifischen Stunden mit diesem Kurs verbunden. Von Zeit zu Zeit führen wir 1-zu-1-Sitzungen durch, und unser Team wird unser Bestes tun, um dir während dieser Woche so viel wie möglich beizubringen.'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Make progression in ideal conditions",
                                du: 'Maak vooruitgang onder ideale omstandigheden',
                                gr: 'Mach Fortschritte unter idealen Bedingungen'
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Advice from our travel expert AJ",
                                du: 'Advies van onze reisspecialist AJ',
                                gr: 'Rat von unserem Reiseexperten AJ'
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Headset coaching",
                                du: 'Coaching via headset',
                                gr: 'Coaching über Headset'
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: 'Foto- en video-analyse / bewijs',
                                gr: 'Foto- und Videoanalyse / Nachweis'
                            }
                        }
                    ]
                },
            ],


            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kitegear verhuur",
                    gr: "Kiteausrüstungsverleih"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en zorgen ervoor dat je altijd met de juiste maat kite op het water bent. Geen zin om al je materiaal mee te nemen? Geen probleem!",
                    gr: "Bei uns findest du das neueste Kitematerial. Wir haben die meisten Marken verfügbar und sorgen dafür, dass du immer mit der richtigen Kite-Größe auf dem Wasser bist. Keine Lust, all dein Material mitzunehmen? Kein Problem!"
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree"
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Check your insurance. If you need help with the right insurance please ask us for help! Not all insurance companies cover kitesurf material.",
                    du: "Je zorgt ervoor dat je je correcte niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt afronden, kun je het openstaande bedrag op verschillende manieren bij ons vereffenen. - Je kunt het openstaande bedrag in onze webshop gebruiken voor (kite)materiaal - Je kunt het gebruiken voor andere KiteActive-reizen of het als cadeaukaart aan vrienden geven - Je kunt je pakket afronden in 1 van onze scholen in Nederland. Controleer je verzekering. Als je hulp nodig hebt bij de juiste verzekering, vraag het ons dan! Niet alle verzekeringsmaatschappijen dekken kitesurfmateriaal.",
                    gr: "Du stellst sicher, dass du während deiner Buchung dein korrektes Niveau angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den offenen Betrag auf verschiedene Weise bei uns begleichen. - Du kannst den offenen Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden - Du kannst ihn für andere KiteActive-Reisen verwenden oder als Geschenkkarte an Freunde geben - Du kannst dein Paket in einer unserer Schulen in Holland abschließen. Überprüfe deine Versicherung. Wenn du Hilfe bei der richtigen Versicherung benötigst, frag uns um Hilfe! Nicht alle Versicherungsunternehmen decken Kitesurfmaterial ab."
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je op de veiligste manier kitesurfen leert, moet je altijd op jezelf en anderen letten. Luister altijd goed naar de instructies van ons team.",
                    gr: "Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport, und obwohl wir sicherstellen, dass du bei uns auf die sicherste Weise Kitesurfen lernst, musst du dir immer selbst und anderen bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }

        },

        hosted: [
            {
                hosted_img: skycap_hosted_img1,
                hosted_name: {
                    en: "Jan Willem",
                    gr: "Jan Willem",
                    du: "Jan Willem"
                },
                hosted_position: {
                    en: "",
                    gr: "",
                    du: ""
                },
                kite_exp: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_para: {
                    en: "As a Gym teacher my job is to motivate people to do activities and sports. Kitesurfing is my biggest passion and I will teach you everything I know!",
                    gr: "Als Sportlehrer ist es mein Job, Menschen zu motivieren, Aktivitäten und Sport zu betreiben. Kitesurfen ist meine größte Leidenschaft und ich werde dir alles beibringen, was ich weiß!",
                    du: "Als gymdocent is het mijn taak om mensen te motiveren om activiteiten en sport te doen. Kitesurfen is mijn grootste passie en ik zal je alles leren wat ik weet!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "",
                    gr: "",
                    du: ""
                }
            },
            {
                hosted_img: skycap_hosted_img2,
                hosted_name: {
                    en: "Roderick Pijls",
                    gr: "Roderick Pijls",
                    du: "Roderick Pijls"
                },
                hosted_position: {
                    en: "",
                    gr: "",
                    du: ""
                },
                kite_exp: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_para: {
                    en: "Having a background of professional kitesurfer for over 12 years has taught me a lot. Seeing many cultures, meeting great people and knowing everything from the best spots in the world has convinced me to start sharing this with everyone who has that little 'Columbus' inside him/ herself.",
                    gr: "Eine berufliche Kitesurfer-Hintergrund von über 12 Jahren hat mir viel beigebracht. Viele Kulturen zu sehen, großartige Menschen zu treffen und alles über die besten Spots der Welt zu wissen, hat mich überzeugt, dies mit allen zu teilen, die das kleine 'Kolumbus' in sich haben.",
                    du: "Een achtergrond als professionele kitesurfer van meer dan 12 jaar heeft me veel geleerd. Veel culturen zien, geweldige mensen ontmoeten en alles weten van de beste plekken ter wereld heeft me ervan overtuigd om dit te delen met iedereen die dat kleine 'Columbus' in zichzelf heeft."
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "",
                    gr: "",
                    du: ""
                }
            },
            {
                hosted_img: skycap_hosted_img3,
                hosted_name: {
                    en: "Leon ”the machine”",
                    gr: "Leon ”die Maschine”",
                    du: "Leon ”de machine”"
                },
                hosted_position: {
                    en: "Instructor / Coach",
                    gr: "Instruktor / Trainer",
                    du: "Instructeur / Coach"
                },
                kite_exp: {
                    en: "10 years kite experience",
                    gr: "10 Jahre Kiterfahrung",
                    du: "10 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "In the summer I have my own kiteschool the Spot on Ameland. In the winter I travel the world with KiteActive!",
                    gr: "Im Sommer habe ich meine eigene Kitesurfschule, den Spot auf Ameland. Im Winter reise ich mit KiteActive um die Welt!",
                    du: "In de zomer heb ik mijn eigen kiteschool The Spot op Ameland. In de winter reis ik de wereld rond met KiteActive!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favoriete Bestemming",
                    gr: "Top-Destination",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "Tarifa!",
                    gr: "Tarifa!",
                    du: "Tarifa!"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "1 year",
                    gr: "1 Jahr",
                    du: "1 jaar"
                }
            },
            {
                hosted_img: skycap_hosted_img4,
                hosted_name: {
                    en: "Joeri ”the Chef”",
                    gr: "Joeri ”der Chef”",
                    du: "Joeri ”de Chef”"
                },
                hosted_position: {
                    en: "Chef",
                    gr: "Koch",
                    du: "Chef"
                },
                kite_exp: {
                    en: "4 years kite experience",
                    gr: "4 Jahre Kiterfahrung",
                    du: "4 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "In the summer I work in a foodtruck on festivals and in the winter I cruise around in my Van and try to kite as much as possible!",
                    gr: "Im Sommer arbeite ich in einem Foodtruck auf Festivals und im Winter fahre ich mit meinem Van herum und versuche, so viel wie möglich zu kiten!",
                    du: "In de zomer werk ik in een foodtruck op festivals en in de winter rijd ik rond in mijn bus en probeer ik zoveel mogelijk te kitesurfen!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favoriete Bestemming",
                    gr: "Top-Destination",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "Tarifa!",
                    gr: "Tarifa!",
                    du: "Tarifa!"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "3 years",
                    gr: "3 Jahre",
                    du: "3 jaar"
                }
            }
        ],

        accommodation: {
            main_title: {
                en: "KiteActive HQ in Cape Town",
                gr: "KiteActive HQ in Kapstadt",
                du: "KiteActive HQ in Kaapstad"
            },
            main_desc: {
                en: "Our accommodation is on 3min drive distance from the beach and on walking distance from several great cafe's/ lunch areas, supermarket and bars. In the rooms we always try to seperate the males and females and you will have everything you need for the perfect week. Do you want to have a bit more privacy or share a room with your partner? Please inform us!",
                gr: "Unsere Unterkunft ist nur 3 Minuten Fahrt vom Strand entfernt und zu Fuß von mehreren großartigen Cafés, Lunch-Bereichen, Supermärkten und Bars erreichbar. In den Zimmern trennen wir stets Männer und Frauen, und Sie haben alles, was Sie für die perfekte Woche benötigen. Möchten Sie etwas mehr Privatsphäre oder ein Zimmer mit Ihrem Partner teilen? Bitte informieren Sie uns!",
                du: "Onze accommodatie ligt op 3 minuten rijden van het strand en op loopafstand van verschillende geweldige cafés, lunchgelegenheden, supermarkten en bars. In de kamers proberen we altijd mannen en vrouwen te scheiden, en je hebt alles wat je nodig hebt voor de perfecte week. Wil je wat meer privacy of een kamer delen met je partner? Laat het ons weten!"
            },
            card_details: [
                {
                    imgSrc: skycap_hotel1,
                    title: {
                        en: "Single or double rooms",
                        gr: "Einzel- oder Doppelzimmer",
                        du: "Eenpersoons- of tweepersoonskamers"
                    },
                    description: {
                        en: "Very nice and clean rooms for kiters!",
                        gr: "Sehr schöne und saubere Zimmer für Kitesurfer!",
                        du: "Zeer mooie en schone kamers voor kiters!"
                    }
                },
                {
                    imgSrc: skycap_hotel2,
                    title: {
                        en: "Shared rooms",
                        gr: "Geteilte Zimmer",
                        du: "Gedeelde kamers"
                    },
                    description: {
                        en: "Shared rooms are available",
                        gr: "Geteilte Zimmer sind verfügbar",
                        du: "Gedeelde kamers zijn beschikbaar"
                    }
                },
                {
                    imgSrc: skycap_hotel3,
                    title: {
                        en: "With a view",
                        gr: "Mit Aussicht",
                        du: "Met uitzicht"
                    },
                    description: {
                        en: "We are making sure you will be blown away!",
                        gr: "Wir sorgen dafür, dass Sie beeindruckt sein werden!",
                        du: "We zorgen ervoor dat je versteld zult staan!"
                    }
                },
                {
                    imgSrc: skycap_hotel4,
                    title: {
                        en: "Time to relax!",
                        gr: "Zeit zum Entspannen!",
                        du: "Tijd om te ontspannen!"
                    },
                    description: {
                        en: "Chill at the pool",
                        gr: "Entspannen Sie sich am Pool",
                        du: "Ontspan bij het zwembad"
                    }
                },
                {
                    imgSrc: skycap_hotel5,
                    title: {
                        en: "Yoga stretch sessions",
                        gr: "Yoga-Streckübungen",
                        du: "Yoga stretch sessies"
                    },
                    description: {
                        en: "Namaste!",
                        gr: "Namaste!",
                        du: "Namaste!"
                    }
                },
                {
                    imgSrc: skycap_hotel6,
                    title: {
                        en: "Awesome evenings",
                        gr: "Tolle Abende",
                        du: "Geweldige avonden"
                    },
                    description: {
                        en: "Relax with other kiters",
                        gr: "Entspannen Sie sich mit anderen Kitern",
                        du: "Ontspan met andere kiters"
                    }
                }
            ],
            location_title: {
                en: "How to get here",
                gr: "Wie man hierher kommt",
                du: "Hoe je hier komt"
            },
            location_subtitle: {
                en: "The Caribbean of Africa",
                gr: "Die Karibik Afrikas",
                du: "De Caraïben van Afrika"
            },
            location_desc: {
                en: "We proudly present you our own KiteActive Hotel on the beach in Paje. The ideal set-up for kite surfers. The atmosphere and our team ensure a top stay with everything you need within reach!",
                gr: "Wir präsentieren stolz unser eigenes KiteActive Hotel am Strand von Paje. Das ideale Setup für Kitesurfer. Die Atmosphäre und unser Team sorgen für einen erstklassigen Aufenthalt mit allem, was Sie brauchen, in greifbarer Nähe!",
                du: "We presenteren met trots ons eigen KiteActive Hotel op het strand in Paje. De ideale opstelling voor kitesurfers. De sfeer en ons team zorgen voor een topverblijf met alles wat je nodig hebt binnen handbereik!"
            },
                 iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10842.252843950639!2d18.4798475!3d-33.8167284!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5ff411ab18fb%3A0x47240d82e7e09a22!2sKhaya%20Kite%20and%20Yoga%20House!5e1!3m2!1sen!2sin!4v1729943208729!5m2!1sen!2sin"                 

        },

        activities: [
            {
                img: zanzibar_activity_img1,
                title: {
                    en: 'Awesome Evenings',
                    du: 'Geweldige Avonden',
                    gr: 'Tolle Abende'
                }
            },
            {
                img: zanzibar_activity_img2,
                title: {
                    en: 'Wave Surfing',
                    du: 'Golfsurfen',
                    gr: 'Wellenreiten'
                }
            },
            {
                img: zanzibar_activity_img3,
                title: {
                    en: 'Down winders',
                    du: 'Downwinders',
                    gr: 'Downwinders'
                }
            },
            {
                img: zanzibar_activity_img4,
                title: {
                    en: 'Climbing',
                    du: 'Klimmen',
                    gr: 'Klettern'
                }
            },
            {
                img: zanzibar_activity_img5,
                title: {
                    en: 'Funbox - beach Games',
                    du: 'Funbox - strandspellen',
                    gr: 'Funbox - Strandspiele'
                }
            },
                
               
            ],
        reviews: {}
    },


    // KitemeccaTraifa trip data ......................................... 

    {
        name: 'Tarifa',
        vedio: KitemeccaTraifa_vedio,
        backcover: kitemeacacover,
        alltabs: {
            highlight: [
                {
                    text: {
                        en: "Amazing kitesurf conditions",
                        gr: "Fantastische Kitesurf-Bedingungen",
                        du: "Geweldige kitesurf omstandigheden"
                    }
                },
                {
                    text: {
                        en: "100% Fun guaranteed",
                        gr: "100% Spaß garantiert",
                        du: "100% plezier gegarandeerd"
                    }
                },
                {
                    text: {
                        en: "Progression guaranteed",
                        gr: "Fortschritt garantiert",
                        du: "Vooruitgang gegarandeerd"
                    }
                },
                {
                    text: {
                        en: "Optional cultural activities",
                        gr: "Optionale kulturelle Aktivitäten",
                        du: "Optionele culturele activiteiten"
                    }
                }
            ],
            question: [
                {
                    title: {
                        en: "What lesson package should I choose?",
                        gr: "Welches Unterrichtspaket sollte ich wählen?",
                        du: "Welk lespakket moet ik kiezen?"
                    },
                    para: {
                        en: "Send us an e-mail or contact us via chat and leave your phone number and we will give you extensive advice!",
                        gr: "Sende uns eine E-Mail oder kontaktiere uns über den Chat und hinterlasse deine Telefonnummer, und wir werden dir umfassende Beratung geben!",
                        du: "Stuur ons een e-mail of neem contact met ons op via chat en laat je telefoonnummer achter, dan geven wij je uitgebreid advies!"
                    }
                },
                {
                    title: {
                        en: "How is the group?",
                        gr: "Wie ist die Gruppe?",
                        du: "Hoe is de groep?"
                    },
                    para: {
                        en: "Our groups can vary from young to old, and are often evenly distributed with both men and women. The group usually consists of ages between 20 and 45, with exceptions of course. We teach beginners and give tips to advanced kitesurfers, so you can expect every level from us. We also often offer group activities that you can participate in without obligation. On some trips you may share a large room with other KiteActive guests, but this will be indicated. You can often contact us in advance via our Facebook events or see via the WhatsApp group which people will be present on the trip.",
                        gr: "Unsere Gruppen können von jung bis alt variieren und sind oft gleichmäßig auf Männer und Frauen verteilt. Die Gruppe besteht normalerweise aus Menschen im Alter von 20 bis 45 Jahren, mit einigen Ausnahmen. Wir unterrichten Anfänger und geben fortgeschrittenen Kitesurfern Tipps, sodass du bei uns jedes Niveau erwarten kannst. Wir bieten auch oft Gruppenaktivitäten an, an denen du unverbindlich teilnehmen kannst. Bei einigen Reisen teilst du möglicherweise ein großes Zimmer mit anderen KiteActive-Gästen, aber dies wird vorher angegeben. Du kannst uns oft im Voraus über unsere Facebook-Events kontaktieren oder über die WhatsApp-Gruppe sehen, welche Personen an der Reise teilnehmen werden.",
                        du: "Onze groepen kunnen variëren van jong tot oud en zijn vaak gelijk verdeeld tussen mannen en vrouwen. De groep bestaat meestal uit mensen tussen de 20 en 45 jaar, met natuurlijk enkele uitzonderingen. We leren beginners en geven tips aan gevorderde kitesurfers, dus je kunt van ons elk niveau verwachten. We bieden ook vaak groepsactiviteiten aan waar je vrijblijvend aan kunt deelnemen. Bij sommige reizen deel je mogelijk een grote kamer met andere KiteActive-gasten, maar dit wordt aangegeven. Je kunt ons vaak van tevoren contacteren via onze Facebook-evenementen of via de WhatsApp-groep zien welke mensen aanwezig zullen zijn op de reis."
                    }
                },
                {
                    title: {
                        en: "Do you also arrange flights?",
                        gr: "Organisiert ihr auch Flüge?",
                        du: "Regelen jullie ook vluchten?"
                    },
                    para: {
                        en: "Unfortunately not. But we will give you the best advice to make sure you book the correct flight tickets.",
                        gr: "Leider nicht. Aber wir geben dir die besten Ratschläge, damit du die richtigen Flugtickets buchst.",
                        du: "Helaas niet. Maar we geven je het beste advies om ervoor te zorgen dat je de juiste vliegtickets boekt."
                    }
                },
                {
                    title: {
                        en: "For which level?",
                        gr: "Für welches Niveau?",
                        du: "Voor welk niveau?"
                    },
                    para: {
                        en: "For all levels! From beginner to advanced, you can enjoy and train on this lagoon.",
                        gr: "Für alle Niveaus! Vom Anfänger bis zum Fortgeschrittenen, du kannst auf dieser Lagune genießen und trainieren.",
                        du: "Voor alle niveaus! Van beginner tot gevorderde, je kunt genieten en trainen op deze lagune."
                    }
                },
                {
                    title: {
                        en: "How is the wind? What kite material is needed?",
                        gr: "Wie ist der Wind? Welches Kitematerial wird benötigt?",
                        du: "Hoe is de wind? Welk kite materiaal is nodig?"
                    },
                    para: {
                        en: "Normally the wind blows 13 to 30 knots so the golden rule is to bring all sizes of kites!",
                        gr: "Normalerweise weht der Wind mit 13 bis 30 Knoten, also lautet die goldene Regel: Bring alle Kitegrößen mit!",
                        du: "Normaal gesproken waait de wind 13 tot 30 knopen, dus de gouden regel is om alle maten kites mee te nemen!"
                    }
                },
                {
                    title: {
                        en: "Can I easily bring a boardbag?",
                        gr: "Kann ich problemlos eine Boardtasche mitbringen?",
                        du: "Kan ik gemakkelijk een boardbag meenemen?"
                    },
                    para: {
                        en: "Yes! Royal Air Maroc gives you the chance to bring your boardbag of 23kg for free.",
                        gr: "Ja! Royal Air Maroc gibt dir die Möglichkeit, deine 23kg-Boardtasche kostenlos mitzunehmen.",
                        du: "Ja! Royal Air Maroc geeft je de kans om je boardbag van 23kg gratis mee te nemen."
                    }
                }
            ]
        },



        overview: {
            trip_heading: {
                en: "Kite mecca Europe: Kitesurf in Tarifa",
                gr: "Kite-Mekka Europas: Kitesurfen in Tarifa",
                du: "Kite-mekka Europa: Kitesurfen in Tarifa"
            },
            trip_para: {
                en: "Tarifa is not only known as the southernmost tip of Spain, but also as the kitesurfing mecca of Europe, where you can kitesurf in Tarifa with its unique blend of Moroccan and Spanish influences. A perfect destination for your kite holiday close to home! This surfing paradise is located only 14 km from the African coast and is known as a place where the wind always blows. The nightlife in Tarifa is also fun with nice bars and delicious restaurants. Are you a real wave rider or do you like flat spots where you can stand knee deep anywhere? Do you want to get away cheaply this winter? Check in and check it out!",
                gr: "Tarifa ist nicht nur als der südlichste Punkt Spaniens bekannt, sondern auch als das Kitesurf-Mekka Europas. Hier kannst du in Tarifa kitesurfen und den einzigartigen Mix aus marokkanischen und spanischen Einflüssen erleben. Ein perfektes Reiseziel für deinen Kiteurlaub in der Nähe! Dieses Surfparadies liegt nur 14 km von der afrikanischen Küste entfernt und ist bekannt als ein Ort, wo der Wind immer weht. Auch das Nachtleben in Tarifa ist mit schönen Bars und leckeren Restaurants sehr unterhaltsam. Bist du ein echter Wellenreiter oder bevorzugst du flache Spots, wo du überall knietief stehen kannst? Willst du diesen Winter günstig entfliehen? Check ein und probier’s aus!",
                du: "Tarifa staat niet alleen bekend als het meest zuidelijke punt van Spanje, maar ook als het kitesurfmekka van Europa, waar je kunt kitesurfen in Tarifa met zijn unieke mix van Marokkaanse en Spaanse invloeden. Een perfecte bestemming voor je kitevakantie dicht bij huis! Dit surfparadijs ligt op slechts 14 km van de Afrikaanse kust en staat bekend als een plek waar de wind altijd waait. Het nachtleven in Tarifa is ook leuk met gezellige bars en heerlijke restaurants. Ben jij een echte golfrijder of houd je van vlakke spots waar je overal kniediep kunt staan? Wil je deze winter goedkoop ontsnappen? Check in en ontdek het zelf!"
            },

            img1: surf_img13,
            img1_head: {
                en: "Dates 2025",
                gr: "Termine 2025",
                du: "Data 2025"
            },
            img1_para: {
                en: "25 January - 1 February",
                gr: "25. Januar - 1. Februar",
                du: "25 januari - 1 februari"
            },
            img2: surf_img1,
            img2_head: {
                en: "Skills",
                gr: "Fähigkeiten",
                du: "Vaardigheden"
            },
            img2_para: {
                en: "Level 2 is possible, Level 3 is better for this kitecamp!",
                gr: "Level 2 ist möglich, Level 3 ist besser für dieses Kitecamp!",
                du: "Niveau 2 is mogelijk, niveau 3 is beter voor dit kitecamp!"
            },
            img3: surf_img2,
            img3_head: {
                en: "Join KiteActive",
                gr: "Schließe dich KiteActive an",
                du: "Doe mee met KiteActive"
            },
            img3_para: {
                en: "Good vibes only. Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "Nur gute Vibes. Trete dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / dem KA-Team zu chatten.",
                du: "Alleen goede vibes. Doe mee in de groepschat om op de hoogte te blijven of te chatten met andere reizigers / het KA-team."
            },
            img4: surf_img6,
            img4_head: {
                en: "Early bird",
                gr: "Frühbucher",
                du: "Vroege vogel"
            },
            img4_para: {
                en: "Book before 15 November and get € 100 discount",
                gr: "Buche vor dem 15. November und erhalte € 100 Rabatt",
                du: "Boek voor 15 november en ontvang € 100 korting"
            },
            gall_img1: KitemeccaTraifa_img1,
            gall_img2: KitemeccaTraifa_img2,
            gall_img3: KitemeccaTraifa_img3,
            gall_img4: KitemeccaTraifa_img4,
        },


        kitespot: [
            {
                imgSrc: KitemeccaTraifa_img5,
                text: {
                    en: 'follow the sun, wind, and waves with KiteActive',
                    du: 'volg de zon, wind en golven met KiteActive',
                    gr: 'Folge der Sonne, dem Wind und den Wellen mit KiteActive'
                },

                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: 'Tarifa is the ultimate kitesurfing destination, offering consistent winds and stunning beaches. Located at the southernmost tip of Spain, it boasts two main winds: the strong Levante, perfect for thrill-seekers, and the milder Poniente, ideal for beginners. Popular kitesurfing spots like Playa de Los Lances and Valdevaqueros cater to all skill levels with their expansive sandy shores and reliable winds. For those looking for an immersive experience, the tarifa kite camp and kitesurf camp tarifa options provide excellent opportunities to enhance your skills. Beyond kitesurfing, Tarifa charms with its vibrant nightlife, delicious local cuisine, and a variety of other activities. For an unforgettable kitesurfing holiday combining adventure and culture, Tarifa is the place to be.',
                    du: 'Tarifa is de ultieme bestemming voor kitesurfen, met constante wind en prachtige stranden. Gelegen op het meest zuidelijke puntje van Spanje, heeft het twee belangrijke winden: de sterke Levante, perfect voor avonturiers, en de mildere Poniente, ideaal voor beginners. Populaire kitesurfspots zoals Playa de Los Lances en Valdevaqueros zijn geschikt voor alle niveaus met hun uitgestrekte zandstranden en betrouwbare wind. Voor degenen die een volledige ervaring willen, bieden het Tarifa kite camp en het kitesurf camp Tarifa uitstekende mogelijkheden om je vaardigheden te verbeteren. Naast kitesurfen betovert Tarifa met zijn bruisende nachtleven, heerlijke lokale keuken en tal van andere activiteiten. Voor een onvergetelijke kitesurfvakantie, die avontuur en cultuur combineert, is Tarifa dé plek om te zijn.',
                    gr: 'Tarifa ist das ultimative Kitesurfziel und bietet konstante Winde und atemberaubende Strände. Am südlichsten Punkt Spaniens gelegen, gibt es dort zwei Hauptwinde: den starken Levante, perfekt für Adrenalinsucher, und den milderen Poniente, ideal für Anfänger. Beliebte Kitesurfspots wie Playa de Los Lances und Valdevaqueros bieten für alle Könnerstufen weitläufige Sandstrände und zuverlässige Winde. Für diejenigen, die ein intensives Erlebnis suchen, bieten die Tarifa Kite Camps und Kitesurf-Camp-Optionen hervorragende Möglichkeiten, um deine Fähigkeiten zu verbessern. Abgesehen vom Kitesurfen verzaubert Tarifa mit seinem pulsierenden Nachtleben, köstlicher lokaler Küche und einer Vielzahl anderer Aktivitäten. Für einen unvergesslichen Kitesurfurlaub, der Abenteuer und Kultur verbindet, ist Tarifa der richtige Ort.'
                },
                imgPosition: 'right',
            },
            {
                imgSrc: KitemeccaTraifa_img6,
                text: {
                    en: 'Meet the Poniente and Levante wind',
                    du: 'Ontmoet de Poniente en Levante wind',
                    gr: 'Lerne den Poniente- und Levante-Wind kennen'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: "Tarifa is located on the Strait of Gibraltar. Here it can sometimes be quite windy, ideal to kitesurf in Tarifa, with the Levante wind reaching 30-35 knots. We also have the Poniente wind blowing from the other direction, averaging between 12-20 knots. Ideal for water starters and people who want to practice new tricks! The weather in our winter months in Tarifa is mild, around 20 degrees. Bring a T-shirt, sweater, and wetsuit.",
                    du: "Tarifa ligt aan de Straat van Gibraltar. Hier kan het soms behoorlijk winderig zijn, ideaal om te kitesurfen in Tarifa, met de Levante-wind die 30-35 knopen bereikt. We hebben ook de Poniente-wind die uit de andere richting waait, met een gemiddelde van 12-20 knopen. Ideaal voor beginners en mensen die nieuwe trucs willen oefenen! Het weer in onze wintermaanden in Tarifa is mild, rond de 20 graden. Neem een T-shirt, trui en wetsuit mee.",
                    gr: "Tarifa liegt an der Straße von Gibraltar. Hier kann es manchmal recht windig sein, ideal zum Kitesurfen in Tarifa, mit dem Levante-Wind, der 30-35 Knoten erreicht. Wir haben auch den Poniente-Wind, der aus der anderen Richtung weht und im Durchschnitt zwischen 12-20 Knoten liegt. Ideal für Anfänger und Menschen, die neue Tricks üben möchten! Das Wetter in den Wintermonaten in Tarifa ist mild, etwa 20 Grad. Bring ein T-Shirt, einen Pullover und einen Neoprenanzug mit."
                },
                imgPosition: 'left',
            },
            {
                imgSrc: KitemeccaTraifa_img7,
                text: {
                    en: 'variety of unique kitespots',
                    du: 'verscheidenheid aan unieke kitespots',
                    gr: 'verscheidenheid aan unieke kitespots'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: "Tarifa, a world-famous kite spot known for tarifa spain kitesurfing. There are several kite spots in and around Tarifa. Los Lances is the beach before Tarifa and here is where the most kiting takes place. Then in the winter you have a beautiful lagoon that fills up and there are plenty of other spots we can drive to! Don’t forget the world spot Balnerio where all the pro kiters train for their mega loops!",
                    du: "Tarifa, een wereldberoemde kitesurfspot die bekend staat om kitesurfen in Spanje. Er zijn verschillende kitespots in en rond Tarifa. Los Lances is het strand voor Tarifa en hier vindt het meeste kitesurfen plaats. In de winter is er een prachtige lagune die zich vult en er zijn nog veel andere spots waar we naartoe kunnen rijden! Vergeet de wereldberoemde spot Balnerio niet, waar alle pro kiters trainen voor hun mega loops!",
                    gr: "Tarifa, ein weltberühmter Kitesurfspot, bekannt für das Kitesurfen in Tarifa, Spanien. Es gibt mehrere Kitesurfspots in und um Tarifa. Los Lances ist der Strand vor Tarifa und hier findet das meiste Kitesurfen statt. Im Winter gibt es eine wunderschöne Lagune, die sich füllt, und es gibt viele andere Spots, zu denen wir fahren können! Vergiss nicht den weltberühmten Spot Balnerio, wo alle Profi-Kiter für ihre Mega-Loops trainieren!"
                },
                imgPosition: 'right',
            }
        ],

        packages: {
            levelsData: [
                {
                    levelName: 'Level 3 - Independent',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk aangemoedigd en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Learn new tricks and make progression',
                                du: 'Leer nieuwe trucs en maak vooruitgang',
                                gr: 'Lerne neue Tricks und mache Fortschritte'
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: 'Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste spron naar een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!',
                                gr: 'Dies ist das ultimative Paket für jeden, der bereits kitesurfen kann, aber Fortschritte machen und neue Tricks lernen möchte. Von deinem allerersten Sprung bis zum Kiteloop-Handle-Pass. Unser Team ist bereit, dir mit den richtigen Tipps und Tricks zu helfen, um deine Ziele zu erreichen!'
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je van deze cursus verwachten?',
                                gr: 'Was kannst du von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: 'Tijdens de cursus bekijken we wat je persoonlijke doelen zijn en wat haalbaar is. We gaan samen de theorie door en proberen je gedurende de week zoveel mogelijk te stimuleren, coachen en motiveren om nieuwe dingen op een veilige manier te proberen.',
                                gr: 'Während des Kurses werden wir uns ansehen, was deine persönlichen Ziele sind und was machbar ist. Wir gehen gemeinsam die Theorie durch und versuchen während der Woche, dich so gut wie möglich zu pushen, zu coachen und zu motivieren, um neue Dinge auf sichere Weise auszuprobieren.'
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'The basics of pop',
                                du: 'De basis van pop',
                                gr: 'Die Grundlagen des Pops'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitecarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kitesurfkarriere benötigst!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first jump',
                                du: 'Je eerste spron',
                                gr: 'Dein erster Sprung'
                            },
                            info: {
                                en: 'You want to learn how to go skyhigh?',
                                du: 'Wil je leren hoe je de lucht in kunt gaan?',
                                gr: 'Möchtest du lernen, wie du in die Höhe springen kannst?'
                            }
                        },
                        {
                            title: {
                                en: 'Back & Front roll',
                                du: 'Back & Front roll',
                                gr: 'Back & Frontroll'
                            },
                            info: {
                                en: 'Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!',
                                du: 'Maak een salto in de lucht! Je denkt dat het moeilijk is, maar we leren je deze geweldige truc in 1 week!',
                                gr: 'Mach einen Salto in der Luft! Du denkst, es ist schwierig, aber wir werden dir diesen erstaunlichen Trick in 1 Woche beibringen!'
                            }
                        },
                        {
                            title: {
                                en: 'Board grabs, onefooters and board-offs',
                                du: 'Board grabs, onefooters en board-offs',
                                gr: 'Board Grabs, Onefooters und Board-offs'
                            },
                            info: {
                                en: 'Grab the board in front of the camera!',
                                du: 'Pak het board voor de camera!',
                                gr: 'Greife das Board vor der Kamera!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first kiteloop (downloop)',
                                du: 'Je eerste kiteloop (downloop)',
                                gr: 'Dein erster Kiteloop (Downloop)'
                            },
                            info: {
                                en: 'Fly like a rollercoaster',
                                du: 'Vlieg als een achtbaan',
                                gr: 'Flieg wie eine Achterbahn'
                            }
                        },
                        {
                            title: {
                                en: 'Your first downwinder',
                                du: 'Je eerste downwinder',
                                gr: 'Dein erster Downwinder'
                            },
                            info: {
                                en: 'With a downwinder you are doing a kite tour along the coast!',
                                du: 'Met een downwinder maak je een kitetour langs de kust!',
                                gr: 'Mit einem Downwinder machst du eine Kitetour entlang der Küste!'
                            }
                        },
                        {
                            title: {
                                en: 'Learn how to ride strapless / bottom turns / wave skills',
                                du: 'Leer hoe je strapless kunt rijden / bottom turns / golfvaardigheden',
                                gr: 'Lerne, wie man strapless fährt / Bottom Turns / Wellenfähigkeiten'
                            },
                            info: {
                                en: 'Upgrade your skills in the waves',
                                du: 'Verbeter je vaardigheden in de golven',
                                gr: 'Verbessere deine Fähigkeiten in den Wellen'
                            }
                        },
                        {
                            title: {
                                en: 'Get unhooked!',
                                du: 'Ga los!',
                                gr: 'Lass los!'
                            },
                            info: {
                                en: 'Learn how to do unhooked tricks like Raily, S-bend or maybe even a handle pass?',
                                du: 'Leer hoe je unhooked trucs doet zoals Raily, S-bend of misschien zelfs een handle pass?',
                                gr: 'Lerne, wie man unhooked Tricks wie Raily, S-bend oder vielleicht sogar einen Handle Pass macht?'
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.',
                                du: 'We linken geen exacte uren aan dit pakket. We proberen iedereen zoveel mogelijk te stimuleren en bieden af en toe 1-op-1 begeleiding. Het doel is om zoveel mogelijk vooruitgang te boeken en tijdens deze cursus geven we je alle tools die je nodig hebt om te blijven oefenen.',
                                gr: 'Wir verlinken keine genauen Stunden mit diesem Paket. Wir versuchen, jeden so gut wie möglich zu unterstützen und bieten gelegentlich 1-zu-1-Begleitung an. Ziel ist es, so viel Fortschritt wie möglich zu machen, und während dieses Kurses geben wir dir alle Werkzeuge, die du brauchst, um weiter zu üben.'
                            }
                        },
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: 'Is kitemateriaal inbegrepen bij dit pakket?',
                                gr: 'Ist Kitematerial in diesem Paket enthalten?'
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 399 / week',
                                du: 'Nee, je moet je eigen kitemateriaal meenemen of een compleet set huren voor € 399 / week',
                                gr: 'Nein, du musst dein eigenes Kitematerial mitbringen oder ein komplettes Set für 399 € / Woche mieten.'
                            }
                        },
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!',
                                du: 'Je bent niet verzekerd tegen schade via KiteActive. We raden je aan om je reisverzekering goed te controleren om te zien of deze ook extreme sporten dekt. Dit om teleurstellingen en gedoe op vakantie te voorkomen. We werken samen met Sportzeker en dan ben je altijd voor alles verzekerd!',
                                gr: 'Du bist nicht gegen Schäden durch KiteActive versichert. Wir empfehlen, deine Reiseversicherung sorgfältig zu prüfen, um festzustellen, ob sie auch Extremsportarten abdeckt. Um Enttäuschungen und Probleme im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, und dann bist du immer für alles versichert!'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team",
                                du: "Professioneel team",
                                gr: "Professionelles Team"
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Get advice from travel expert AJ",
                                du: "Krijg advies van reisspecialist AJ",
                                gr: "Hol dir Ratschläge vom Reiseexperten AJ"
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: "Foto- en video bewijs / analyse",
                                gr: "Foto- und Video-Nachweis / Analyse"
                            }
                        }
                    ]
                },

                {
                    levelName: 'Level 4 - Advanced',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'Level up! There is still so much to learn',
                                du: 'Level omhoog! Er is nog zoveel te leren',
                                gr: 'Level hoch! Es gibt noch so viel zu lernen'
                            },
                            para: {
                                en: 'Do you want to improve in big air kiting? Going for that megaloop? Or do you really want to learn this sick handle pass? This course is also for waveriders who are able to do long downwinders in challenging conditions and who want to learn to perform better in the waves.',
                                du: 'Wil je verbeteren in big air kiten? Ga je voor die megaloop? Of wil je echt deze geweldige handle pass leren? Deze cursus is ook voor golfrijders die in staat zijn om lange downwinders in uitdagende omstandigheden te maken en die beter willen presteren in de golven.',
                                gr: 'Möchtest du im Big Air Kiten besser werden? Gehst du für den Megaloop? Oder möchtest du wirklich diesen coolen Handle Pass lernen? Dieser Kurs ist auch für Wellenreiter, die in der Lage sind, lange Downwinders unter herausfordernden Bedingungen zu machen und die lernen möchten, in den Wellen besser abzuschneiden.'
                            },
                            imgPosition: 'left'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Long downwinders in challenging conditions',
                                du: 'Lange downwinders in uitdagende omstandigheden',
                                gr: 'Lange Downwinders unter herausfordernden Bedingungen'
                            },
                            info: {
                                en: 'Like DEN TVTAS for example',
                                du: 'Zoals DEN TVTAS bijvoorbeeld',
                                gr: 'Wie DEN TVTAS zum Beispiel'
                            }
                        },
                        {
                            title: {
                                en: 'Jump 10 meter +',
                                du: 'Spring 10 meter +',
                                gr: 'Spring 10 Meter +'
                            },
                            info: {
                                en: 'Do you want to go skyhigh with us?',
                                du: 'Wil je met ons de lucht in?',
                                gr: 'Möchtest du mit uns in die Höhe springen?'
                            }
                        },
                        {
                            title: {
                                en: 'Megaloop',
                                du: 'Megaloop',
                                gr: 'Megaloop'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        },
                        {
                            title: {
                                en: 'Unhooked tricks',
                                du: 'Unhooked trucs',
                                gr: 'Unhooked Tricks'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'No, not with us. You need to take care of your own travel insurance. If you want, we can help you find the right one!',
                                du: 'Nee, niet bij ons. Je moet zelf zorgen voor je reisverzekering. Als je wilt, kunnen we je helpen de juiste te vinden!',
                                gr: 'Nein, nicht bei uns. Du musst dich um deine eigene Reiseversicherung kümmern. Wenn du möchtest, können wir dir helfen, die richtige zu finden!'
                            }
                        },
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'No specific hours are attached to this course. From time to time we do 1-on-1 sessions and our team will do our best to teach you as much as possible during this week.',
                                du: 'Er zijn geen specifieke uren aan deze cursus verbonden. Af en toe doen we 1-op-1 sessies en ons team zal ons best doen om je deze week zoveel mogelijk te leren.',
                                gr: 'Es sind keine spezifischen Stunden mit diesem Kurs verbunden. Von Zeit zu Zeit führen wir 1-zu-1-Sitzungen durch, und unser Team wird unser Bestes tun, um dir während dieser Woche so viel wie möglich beizubringen.'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Make progression in ideal conditions",
                                du: 'Maak vooruitgang onder ideale omstandigheden',
                                gr: 'Mach Fortschritte unter idealen Bedingungen'
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Advice from our travel expert AJ",
                                du: 'Advies van onze reisspecialist AJ',
                                gr: 'Rat von unserem Reiseexperten AJ'
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Headset coaching",
                                du: 'Coaching via headset',
                                gr: 'Coaching über Headset'
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: 'Foto- en video-analyse / bewijs',
                                gr: 'Foto- und Videoanalyse / Nachweis'
                            }
                        }
                    ]
                },
            ],


            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kitegear verhuur",
                    gr: "Kiteausrüstungsverleih"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en zorgen ervoor dat je altijd met de juiste maat kite op het water bent. Geen zin om al je materiaal mee te nemen? Geen probleem!",
                    gr: "Bei uns findest du das neueste Kitematerial. Wir haben die meisten Marken verfügbar und sorgen dafür, dass du immer mit der richtigen Kite-Größe auf dem Wasser bist. Keine Lust, all dein Material mitzunehmen? Kein Problem!"
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree"
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Check your insurance. If you need help with the right insurance please ask us for help! Not all insurance companies cover kitesurf material.",
                    du: "Je zorgt ervoor dat je je correcte niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt afronden, kun je het openstaande bedrag op verschillende manieren bij ons vereffenen. - Je kunt het openstaande bedrag in onze webshop gebruiken voor (kite)materiaal - Je kunt het gebruiken voor andere KiteActive-reizen of het als cadeaukaart aan vrienden geven - Je kunt je pakket afronden in 1 van onze scholen in Nederland. Controleer je verzekering. Als je hulp nodig hebt bij de juiste verzekering, vraag het ons dan! Niet alle verzekeringsmaatschappijen dekken kitesurfmateriaal.",
                    gr: "Du stellst sicher, dass du während deiner Buchung dein korrektes Niveau angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den offenen Betrag auf verschiedene Weise bei uns begleichen. - Du kannst den offenen Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden - Du kannst ihn für andere KiteActive-Reisen verwenden oder als Geschenkkarte an Freunde geben - Du kannst dein Paket in einer unserer Schulen in Holland abschließen. Überprüfe deine Versicherung. Wenn du Hilfe bei der richtigen Versicherung benötigst, frag uns um Hilfe! Nicht alle Versicherungsunternehmen decken Kitesurfmaterial ab."
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je op de veiligste manier kitesurfen leert, moet je altijd op jezelf en anderen letten. Luister altijd goed naar de instructies van ons team.",
                    gr: "Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport, und obwohl wir sicherstellen, dass du bei uns auf die sicherste Weise Kitesurfen lernst, musst du dir immer selbst und anderen bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }

        },

        hosted: [
            {
                hosted_img: KitemeccaTraifa_img8,
                hosted_name: {
                    en: "Annemaj Muiser",
                    gr: "Annemaj Muiser",
                    du: "Annemaj Muiser"
                },
                hosted_position: {
                    en: "Photographer & Instructor",
                    gr: "Fotografin & Instruktorin",
                    du: "Fotograaf & Instructeur"
                },
                kite_exp: {
                    en: "6 years kite experience",
                    gr: "6 Jahre Kite-Erfahrung",
                    du: "6 jaar kite-ervaring"
                },
                hosted_para: {
                    en: "I love shooting pictures and teaching other people our amazing sport. I will make sure you have the perfect kite picture at the end of the week :)",
                    gr: "Ich liebe es, Bilder zu machen und anderen Menschen unseren tollen Sport beizubringen. Ich werde dafür sorgen, dass du am Ende der Woche das perfekte Kitesurf-Foto hast :)",
                    du: "Ik hou van fotograferen en andere mensen onze geweldige sport leren. Aan het eind van de week zorg ik ervoor dat je het perfecte kite-foto hebt :)"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top Reiseziel",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "Zanzibar",
                    gr: "Sansibar",
                    du: "Zanzibar"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "6 years",
                    gr: "6 Jahre",
                    du: "6 jaar"
                }
            },
            {
                hosted_img: KitemeccaTraifa_img9,
                hosted_name: {
                    en: "Felix Maks",
                    gr: "Felix Maks",
                    du: "Felix Maks"
                },
                hosted_position: {
                    en: "Camp manager & Instructor",
                    gr: "Campmanager & Instruktor",
                    du: "Kampmanager & Instructeur"
                },
                kite_exp: {
                    en: "16 years kite experience",
                    gr: "16 Jahre Kite-Erfahrung",
                    du: "16 jaar kite-ervaring"
                },
                hosted_para: {
                    en: "After competing in the world tour I decided that I wanted to share my passion with other people. Now we travel the world and enjoy the ride!",
                    gr: "Nach meiner Teilnahme an der Weltmeisterschaft entschied ich, meine Leidenschaft mit anderen zu teilen. Jetzt reisen wir um die Welt und genießen die Fahrt!",
                    du: "Na deelname aan de wereldtour besloot ik mijn passie te delen met anderen. Nu reizen we de wereld rond en genieten we van de rit!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favoriete Bestemming",
                    gr: "Lieblingsziel",
                    du: "Favoriete bestemming"
                },
                hosted_msg: {
                    en: "Zanzibar and the Dutch islands",
                    gr: "Sansibar und die niederländischen Inseln",
                    du: "Zanzibar en de Nederlandse eilanden"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "16 years",
                    gr: "16 Jahre",
                    du: "16 jaar"
                }
            },
            {
                hosted_img: KitemeccaTraifa_img10,
                hosted_name: {
                    en: "Leon ”the machine”",
                    gr: "Leon „die Maschine“",
                    du: "Leon „de machine“"
                },
                hosted_position: {
                    en: "Instructor / Coach",
                    gr: "Instruktor / Coach",
                    du: "Instructeur / Coach"
                },
                kite_exp: {
                    en: "10 years kite experience",
                    gr: "10 Jahre Kite-Erfahrung",
                    du: "10 jaar kite-ervaring"
                },
                hosted_para: {
                    en: "In the summer I have my own kiteschool the Spot on Ameland. In the winter I travel the world with KiteActive!",
                    gr: "Im Sommer habe ich meine eigene Kiteschule The Spot auf Ameland. Im Winter reise ich mit KiteActive um die Welt!",
                    du: "In de zomer heb ik mijn eigen kiteschool The Spot op Ameland. In de winter reis ik de wereld rond met KiteActive!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favoriete Bestemming",
                    gr: "Lieblingsziel",
                    du: "Favoriete bestemming"
                },
                hosted_msg: {
                    en: "Tarifa!",
                    gr: "Tarifa!",
                    du: "Tarifa!"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "1 year",
                    gr: "1 Jahr",
                    du: "1 jaar"
                }
            },
            {
                hosted_img: KitemeccaTraifa_img11,
                hosted_name: {
                    en: "Joeri ”the Chef”",
                    gr: "Joeri „der Chef“",
                    du: "Joeri „de Chef“"
                },
                hosted_position: {
                    en: "Chef",
                    gr: "Koch",
                    du: "Chef"
                },
                kite_exp: {
                    en: "4 years kite experience",
                    gr: "4 Jahre Kite-Erfahrung",
                    du: "4 jaar kite-ervaring"
                },
                hosted_para: {
                    en: "In the summer I work in a foodtruck on festivals and in the winter I cruise around in my Van and try to kite as much as possible!",
                    gr: "Im Sommer arbeite ich in einem Foodtruck auf Festivals, und im Winter fahre ich mit meinem Van herum und versuche, so viel wie möglich zu kiten!",
                    du: "In de zomer werk ik in een foodtruck op festivals en in de winter rijd ik rond in mijn busje en probeer ik zoveel mogelijk te kitesurfen!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favoriete Bestemming",
                    gr: "Lieblingsziel",
                    du: "Favoriete bestemming"
                },
                hosted_msg: {
                    en: "Tarifa!",
                    gr: "Tarifa!",
                    du: "Tarifa!"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "3 years",
                    gr: "3 Jahre",
                    du: "3 jaar"
                }
            }
        ],

        activities: [
            {
                img: zanzibar_activity_img1,
                title: {
                    en: 'Awesome Evenings',
                    du: 'Geweldige Avonden',
                    gr: 'Tolle Abende'
                }
            },
            {
                img: zanzibar_activity_img2,
                title: {
                    en: 'Wave Surfing',
                    du: 'Golfsurfen',
                    gr: 'Wellenreiten'
                }
            },
            {
                img: zanzibar_activity_img3,
                title: {
                    en: 'Down winders',
                    du: 'Downwinders',
                    gr: 'Downwinders'
                }
            },
            {
                img: zanzibar_activity_img4,
                title: {
                    en: 'Climbing',
                    du: 'Klimmen',
                    gr: 'Klettern'
                }
            },
            {
                img: zanzibar_activity_img5,
                title: {
                    en: 'Funbox - beach Games',
                    du: 'Funbox - strandspellen',
                    gr: 'Funbox - Strandspiele'
                }
            }
        ],

        reviews: {}
    },



    // EndlessSummerParty trip data Egy  ............................................
    {
        name: 'Egypt',
        vedio: EndlessSummerParty_vedio,
        backcover: endlesscoover,
        alltabs: {
            highlight: [
                {
                    text: {
                        en: "Een gigantische kitespot met alle ruimte",
                        gr: "Ein riesiger Kitespot mit viel Platz",
                        du: "Een gigantische kitespot met alle ruimte"
                    }
                },
                {
                    text: {
                        en: "Je kunt overal staan, perfect voor beginners!",
                        gr: "Du kannst überall stehen, perfekt für Anfänger!",
                        du: "Je kunt overal staan, perfect voor beginners!"
                    }
                },
                {
                    text: {
                        en: "Good food, all inclusive",
                        gr: "Gutes Essen, alles inklusive",
                        du: "Goed eten, alles inclusief"
                    }
                },
                {
                    text: {
                        en: "Nice pool bar with delicious cocktails",
                        gr: "Schöne Poolbar mit leckeren Cocktails",
                        du: "Leuke poolbar met heerlijke cocktails"
                    }
                },
                {
                    text: {
                        en: "Ideal conditions for learning and making progression",
                        gr: "Ideale Bedingungen zum Lernen und Fortschritt machen",
                        du: "Ideale omstandigheden om te leren en vooruitgang te boeken"
                    }
                },
                {
                    text: {
                        en: "Awesome hotel",
                        gr: "Tolles Hotel",
                        du: "Geweldig hotel"
                    }
                },
            ],
            question: [
                {
                    title: {
                        en: "How far is the accommodation from the kite spot",
                        gr: "Wie weit ist die Unterkunft vom Kitespot entfernt?",
                        du: "Hoe ver is de accommodatie van de kitespot?"
                    },
                    para: {
                        en: "The kite spot is on your doorstep!",
                        gr: "Der Kitespot ist direkt vor der Tür!",
                        du: "De kitespot ligt voor de deur!"
                    }
                },
                {
                    title: {
                        en: "What is the wind like and what material should I take with me?",
                        gr: "Wie ist der Wind und welches Material sollte ich mitnehmen?",
                        du: "Hoe is de wind en welk materiaal moet ik meenemen?"
                    },
                    para: {
                        en: "On average the wind blows between 14 and 25 knots. So take all the kite sizes you have with you! Furthermore, a large board or a foil can sometimes come in handy in the mornings and a wave board is also recommended because there is also a beautiful wave spot!",
                        gr: "Im Durchschnitt weht der Wind mit 14 bis 25 Knoten. Also nimm alle Kitegrößen mit, die du hast! Außerdem kann ein großes Board oder ein Foil am Morgen nützlich sein, und ein Waveboard wird auch empfohlen, da es auch einen wunderschönen Wavespot gibt!",
                        du: "Gemiddeld waait de wind tussen de 14 en 25 knopen. Dus neem alle kite maten die je hebt mee! Verder kan een groot board of een foil soms handig zijn in de ochtend en een waveboard wordt ook aanbevolen omdat er ook een mooie wavespot is!"
                    }
                },
                {
                    title: {
                        en: "How is the group?",
                        gr: "Wie ist die Gruppe?",
                        du: "Hoe is de groep?"
                    },
                    para: {
                        en: "Our groups can vary from young to old, and are often evenly distributed with both men and women. The group usually consists of ages between 20 and 45, with exceptions of course. We teach beginners and give tips to advanced kitesurfers, so you can expect every level from us. We also often offer group activities that you can participate in without obligation. On some trips you may share a large room with other KiteActive guests, but this will be indicated. Click this link to join the official Whats-App group for Egypt in the KiteActive community.",
                        gr: "Unsere Gruppen variieren von jung bis alt und sind oft gleichmäßig mit Männern und Frauen verteilt. Die Gruppe besteht normalerweise aus Personen im Alter von 20 bis 45 Jahren, mit Ausnahmen natürlich. Wir unterrichten Anfänger und geben fortgeschrittenen Kitesurfern Tipps, sodass du jedes Niveau bei uns erwarten kannst. Wir bieten auch oft Gruppenaktivitäten an, an denen du freiwillig teilnehmen kannst. Auf einigen Reisen teilst du möglicherweise ein großes Zimmer mit anderen KiteActive-Gästen, aber das wird vorher angegeben. Klicke auf diesen Link, um der offiziellen WhatsApp-Gruppe für Ägypten in der KiteActive-Community beizutreten.",
                        du: "Onze groepen variëren van jong tot oud en zijn vaak gelijk verdeeld met zowel mannen als vrouwen. De groep bestaat meestal uit leeftijden tussen de 20 en 45, met natuurlijk uitzonderingen. We geven beginners les en geven tips aan gevorderde kitesurfers, dus je kunt elk niveau bij ons verwachten. We bieden ook vaak groepsactiviteiten aan waar je vrijblijvend aan kunt deelnemen. Tijdens sommige reizen deel je misschien een grote kamer met andere KiteActive-gasten, maar dat wordt aangegeven. Klik op deze link om lid te worden van de officiële WhatsApp-groep voor Egypte in de KiteActive-community."
                    }
                },
                {
                    title: {
                        en: "How do I fly?",
                        gr: "Wie fliege ich?",
                        du: "Hoe vlieg ik?"
                    },
                    para: {
                        en: "You fly into Hurghada airport. There are several airlines that fly here, but Transavia & Tui is a particular favorite.",
                        gr: "Du fliegst zum Flughafen Hurghada. Es gibt mehrere Fluggesellschaften, die hierher fliegen, aber Transavia und Tui sind besonders beliebt.",
                        du: "Je vliegt naar de luchthaven van Hurghada. Er zijn verschillende luchtvaartmaatschappijen die hier vliegen, maar Transavia en Tui zijn favoriet."
                    }
                },
                {
                    title: {
                        en: "How long is the flight? And from Airport to the Kitevilla?",
                        gr: "Wie lange dauert der Flug? Und vom Flughafen zur Kitevilla?",
                        du: "Hoe lang is de vlucht? En van de luchthaven naar de Kitevilla?"
                    },
                    para: {
                        en: "It is about a 5-hour flight from Amsterdam to Hurghada. From Hurghada, it is a 20-minute drive to the hotel.",
                        gr: "Der Flug von Amsterdam nach Hurghada dauert etwa 5 Stunden. Von Hurghada aus sind es 20 Minuten Fahrt zum Hotel.",
                        du: "Het is ongeveer een vlucht van 5 uur van Amsterdam naar Hurghada. Vanaf Hurghada is het 20 minuten rijden naar het hotel."
                    }
                },
                {
                    title: {
                        en: "How is the nightlife?",
                        gr: "Wie ist das Nachtleben?",
                        du: "Hoe is het nachtleven?"
                    },
                    para: {
                        en: "We are with a great group and we will make some awesome evenings together! 🙂",
                        gr: "Wir sind mit einer großartigen Gruppe unterwegs und werden einige tolle Abende zusammen verbringen! 🙂",
                        du: "We zijn met een geweldige groep en we gaan samen geweldige avonden beleven! 🙂"
                    }
                },
                {
                    title: {
                        en: "I can't during this period",
                        gr: "Ich kann in diesem Zeitraum nicht",
                        du: "Ik kan niet in deze periode"
                    },
                    para: {
                        en: "Sad!! Unfortunately, we only offer this event during this period. Fortunately, we have many other cool ones outside this period kitesurfing events and keep our Facebook Stay tuned to stay informed when new dates for Italy come online.",
                        gr: "Schade!! Leider bieten wir dieses Event nur in diesem Zeitraum an. Zum Glück haben wir viele andere coole Kitesurfing-Events außerhalb dieses Zeitraums, und halte unsere Facebook-Seite im Auge, um informiert zu bleiben, wenn neue Termine für Italien online kommen.",
                        du: "Jammer!! Helaas bieden we dit evenement alleen in deze periode aan. Gelukkig hebben we veel andere coole kitesurfing-evenementen buiten deze periode, en blijf onze Facebook-pagina volgen om op de hoogte te blijven wanneer nieuwe data voor Italië online komen."
                    }
                }
            ]
        },

        overview: {
            trip_heading: {
                en: "Epic Egypt. The land of Pyramids, warm blue water and steady wind.",
                gr: "Epos Ägypten. Das Land der Pyramiden, warmes blaues Wasser und konstanter Wind.",
                du: "Episch Egypte. Het land van piramides, warm blauw water en constante wind."
            },
            trip_para: {
                en: "At our Epic KiteCamp in Hurghada, you’ll dive into kitesurfing paradise right in the heart of Egypt’s stunning Red Sea! Enjoy your stay at a luxurious all-inclusive resort where every detail is taken care of, allowing you to focus on what matters most: kitesurfing. Whether you're a seasoned pro or just starting out, Hurghada is one of the best spots in the world to learn kitesurfing. With its warm, shallow waters and reliable winds, you’ll be riding the waves in no time. Our expert instructors and top-quality equipment ensure you’ll progress quickly in a welcoming, beginner-friendly atmosphere. Thanks to direct flights, reaching this kitesurfing haven is a breeze! Get ready for the adventure of a lifetime!",
                gr: "In unserem Epic KiteCamp in Hurghada tauchst du ein in das Kitesurf-Paradies im Herzen des atemberaubenden Roten Meeres Ägyptens! Genieße deinen Aufenthalt in einem luxuriösen All-Inclusive-Resort, wo jedes Detail berücksichtigt wird, sodass du dich auf das Wesentliche konzentrieren kannst: Kitesurfen. Ob du ein erfahrener Profi oder gerade erst anfängst, Hurghada gehört zu den besten Spots der Welt, um Kitesurfen zu lernen. Mit seinen warmen, flachen Gewässern und zuverlässigen Winden wirst du im Handumdrehen über die Wellen gleiten. Unsere erfahrenen Instruktoren und hochwertige Ausrüstung sorgen dafür, dass du schnell in einer einladenden, anfängerfreundlichen Atmosphäre Fortschritte machst. Dank direkter Flüge ist es ein Kinderspiel, dieses Kitesurf-Paradies zu erreichen! Mach dich bereit für das Abenteuer deines Lebens!",
                du: "In ons Epic KiteCamp in Hurghada duik je het kitesurfparadijs in, recht in het hart van de verbluffende Rode Zee van Egypte! Geniet van je verblijf in een luxueus all-inclusive resort waar elk detail is verzorgd, zodat je je kunt concentreren op wat het belangrijkst is: kitesurfen. Of je nu een doorgewinterde pro bent of net begint, Hurghada is een van de beste plekken ter wereld om te leren kitesurfen. Met zijn warme, ondiepe wateren en betrouwbare winden surf je in een mum van tijd over de golven. Onze deskundige instructeurs en topkwaliteit apparatuur zorgen ervoor dat je snel vooruitgang boekt in een verwelkomende, beginner-vriendelijke sfeer. Dankzij directe vluchten is het een fluitje van een cent om dit kitesurfparadijs te bereiken! Maak je klaar voor het avontuur van je leven!"
            },
            img1: surf_img9,
            img1_head: {
                en: "Only stars!",
                gr: "Nur Sterne!",
                du: "Alleen sterren!"
            },
            img1_para: {
                en: "Read the reviews of our travellers about kite travel.",
                gr: "Lies die Bewertungen unserer Reisenden über Kite-Reisen.",
                du: "Lees de recensies van onze reizigers over kite reizen."
            },
            img2: surf_img10,
            img2_head: {
                en: "Community",
                gr: "Gemeinschaft",
                du: "Gemeenschap"
            },
            img2_para: {
                en: "11-15 kiters / week couples, solo, family, single Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "11-15 Kiter / Woche Paare, Solo, Familie, allein. Trete dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / dem KA-Team zu chatten.",
                du: "11-15 kiters / week koppels, solo, gezin, alleen. Doe mee in de groepschat om op de hoogte te blijven of te chatten met andere reizigers / KA-team."
            },
            img3: surf_img4,
            img3_head: {
                en: "Surf skills",
                gr: "Surf-Fähigkeiten",
                du: "Surfvaardigheden"
            },
            img3_para: {
                en: "Level 1-3 / Beginner to Advanced Kite Lessons",
                gr: "Level 1-3 / Anfänger bis Fortgeschrittene Kitesurf-Unterricht",
                du: "Niveau 1-3 / Beginner tot Gevorderde Kitesurflessen"
            },
            img4: surf_img2,
            img4_head: {
                en: "Save the date",
                gr: "Das Datum vormerken",
                du: "Markeer de datum"
            },
            img4_para: {
                en: "From 23 March till 13 April",
                gr: "Vom 23. März bis 13. April",
                du: "Van 23 maart tot 13 april"
            },
            gall_img1: endless_img1,
            gall_img2: endless_img2,
            gall_img3: endless_img3,
            gall_img4: endless_img4,
        },

        kitespot: [
            {
                imgSrc: endless_img5,
                text: {
                    en: 'flat water Odyssey for all levels',
                    du: 'vlakke waterodyssee voor alle niveaus',
                    gr: 'flache Wasser-Odyssee für alle Niveaus'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: 'Hurghada is a kitesurfing haven along Egypt’s stunning Red Sea coast, offering an unbeatable combination of ideal conditions and breathtaking scenery. The kite spot is incredibly beginner-friendly, featuring expansive standing areas, no rocks or coral, and flat, shallow waters that create a safe and comfortable environment for learning. With steady, reliable winds throughout the year, you can count on consistent sessions, whether you’re just getting started or aiming to enhance your skills. The wide, sandy beaches are free of obstacles, making Hurghada not only picturesque but also one of the safest and easiest locations to enjoy kitesurfing at any level. Come experience the thrill of kitesurfing in this tropical paradise.',
                    du: 'Hurghada is een kitesurfparadijs langs de prachtige kust van de Rode Zee in Egypte, met een onverslaanbare combinatie van ideale omstandigheden en adembenemende uitzichten. De kitesurfplek is ongelooflijk beginner-vriendelijk, met uitgestrekte staande gebieden, geen rotsen of koraal en vlak, ondiep water dat een veilige en comfortabele omgeving voor leren biedt. Met constante, betrouwbare winden het hele jaar door kun je rekenen op consistente sessies, of je nu net begint of je vaardigheden wilt verbeteren. De brede, zandstranden zijn vrij van obstakels, waardoor Hurghada niet alleen pittoresk is, maar ook een van de veiligste en gemakkelijkste locaties om kitesurfen op elk niveau te genieten. Kom de opwinding van kitesurfen in dit tropische paradijs ervaren.',
                    gr: 'Hurghada ist ein Kitesurf-Paradies an der beeindruckenden Küste des Roten Meeres in Ägypten und bietet eine unschlagbare Kombination aus idealen Bedingungen und atemberaubenden Landschaften. Der Kitesurfspot ist unglaublich anfängerfreundlich und verfügt über große Stehbereiche, keine Steine oder Korallen und flaches, flaches Wasser, das eine sichere und komfortable Lernumgebung schafft. Mit gleichmäßigen, zuverlässigen Winden das ganze Jahr über kannst du auf konsistente Sessions zählen, egal ob du gerade erst anfängst oder deine Fähigkeiten verbessern möchtest. Die breiten, sandigen Strände sind frei von Hindernissen, was Hurghada nicht nur malerisch, sondern auch zu einem der sichersten und einfachsten Orte macht, um Kitesurfen auf jedem Niveau zu genießen. Erlebe den Nervenkitzel des Kitesurfens in diesem tropischen Paradies.'
                },
                imgPosition: 'right',
            },
        ],

        packages: {
            levelsData: [
                {
                    levelName: 'Level 3 - Independent',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk aangemoedigd en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Learn new tricks and make progression',
                                du: 'Leer nieuwe trucs en maak vooruitgang',
                                gr: 'Lerne neue Tricks und mache Fortschritte'
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: 'Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste spron naar een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!',
                                gr: 'Dies ist das ultimative Paket für jeden, der bereits kitesurfen kann, aber Fortschritte machen und neue Tricks lernen möchte. Von deinem allerersten Sprung bis zum Kiteloop-Handle-Pass. Unser Team ist bereit, dir mit den richtigen Tipps und Tricks zu helfen, um deine Ziele zu erreichen!'
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je van deze cursus verwachten?',
                                gr: 'Was kannst du von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: 'Tijdens de cursus bekijken we wat je persoonlijke doelen zijn en wat haalbaar is. We gaan samen de theorie door en proberen je gedurende de week zoveel mogelijk te stimuleren, coachen en motiveren om nieuwe dingen op een veilige manier te proberen.',
                                gr: 'Während des Kurses werden wir uns ansehen, was deine persönlichen Ziele sind und was machbar ist. Wir gehen gemeinsam die Theorie durch und versuchen während der Woche, dich so gut wie möglich zu pushen, zu coachen und zu motivieren, um neue Dinge auf sichere Weise auszuprobieren.'
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'The basics of pop',
                                du: 'De basis van pop',
                                gr: 'Die Grundlagen des Pops'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitecarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kitesurfkarriere benötigst!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first jump',
                                du: 'Je eerste spron',
                                gr: 'Dein erster Sprung'
                            },
                            info: {
                                en: 'You want to learn how to go skyhigh?',
                                du: 'Wil je leren hoe je de lucht in kunt gaan?',
                                gr: 'Möchtest du lernen, wie du in die Höhe springen kannst?'
                            }
                        },
                        {
                            title: {
                                en: 'Back & Front roll',
                                du: 'Back & Front roll',
                                gr: 'Back & Frontroll'
                            },
                            info: {
                                en: 'Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!',
                                du: 'Maak een salto in de lucht! Je denkt dat het moeilijk is, maar we leren je deze geweldige truc in 1 week!',
                                gr: 'Mach einen Salto in der Luft! Du denkst, es ist schwierig, aber wir werden dir diesen erstaunlichen Trick in 1 Woche beibringen!'
                            }
                        },
                        {
                            title: {
                                en: 'Board grabs, onefooters and board-offs',
                                du: 'Board grabs, onefooters en board-offs',
                                gr: 'Board Grabs, Onefooters und Board-offs'
                            },
                            info: {
                                en: 'Grab the board in front of the camera!',
                                du: 'Pak het board voor de camera!',
                                gr: 'Greife das Board vor der Kamera!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first kiteloop (downloop)',
                                du: 'Je eerste kiteloop (downloop)',
                                gr: 'Dein erster Kiteloop (Downloop)'
                            },
                            info: {
                                en: 'Fly like a rollercoaster',
                                du: 'Vlieg als een achtbaan',
                                gr: 'Flieg wie eine Achterbahn'
                            }
                        },
                        {
                            title: {
                                en: 'Your first downwinder',
                                du: 'Je eerste downwinder',
                                gr: 'Dein erster Downwinder'
                            },
                            info: {
                                en: 'With a downwinder you are doing a kite tour along the coast!',
                                du: 'Met een downwinder maak je een kitetour langs de kust!',
                                gr: 'Mit einem Downwinder machst du eine Kitetour entlang der Küste!'
                            }
                        },
                        {
                            title: {
                                en: 'Learn how to ride strapless / bottom turns / wave skills',
                                du: 'Leer hoe je strapless kunt rijden / bottom turns / golfvaardigheden',
                                gr: 'Lerne, wie man strapless fährt / Bottom Turns / Wellenfähigkeiten'
                            },
                            info: {
                                en: 'Upgrade your skills in the waves',
                                du: 'Verbeter je vaardigheden in de golven',
                                gr: 'Verbessere deine Fähigkeiten in den Wellen'
                            }
                        },
                        {
                            title: {
                                en: 'Get unhooked!',
                                du: 'Ga los!',
                                gr: 'Lass los!'
                            },
                            info: {
                                en: 'Learn how to do unhooked tricks like Raily, S-bend or maybe even a handle pass?',
                                du: 'Leer hoe je unhooked trucs doet zoals Raily, S-bend of misschien zelfs een handle pass?',
                                gr: 'Lerne, wie man unhooked Tricks wie Raily, S-bend oder vielleicht sogar einen Handle Pass macht?'
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.',
                                du: 'We linken geen exacte uren aan dit pakket. We proberen iedereen zoveel mogelijk te stimuleren en bieden af en toe 1-op-1 begeleiding. Het doel is om zoveel mogelijk vooruitgang te boeken en tijdens deze cursus geven we je alle tools die je nodig hebt om te blijven oefenen.',
                                gr: 'Wir verlinken keine genauen Stunden mit diesem Paket. Wir versuchen, jeden so gut wie möglich zu unterstützen und bieten gelegentlich 1-zu-1-Begleitung an. Ziel ist es, so viel Fortschritt wie möglich zu machen, und während dieses Kurses geben wir dir alle Werkzeuge, die du brauchst, um weiter zu üben.'
                            }
                        },
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: 'Is kitemateriaal inbegrepen bij dit pakket?',
                                gr: 'Ist Kitematerial in diesem Paket enthalten?'
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 399 / week',
                                du: 'Nee, je moet je eigen kitemateriaal meenemen of een compleet set huren voor € 399 / week',
                                gr: 'Nein, du musst dein eigenes Kitematerial mitbringen oder ein komplettes Set für 399 € / Woche mieten.'
                            }
                        },
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!',
                                du: 'Je bent niet verzekerd tegen schade via KiteActive. We raden je aan om je reisverzekering goed te controleren om te zien of deze ook extreme sporten dekt. Dit om teleurstellingen en gedoe op vakantie te voorkomen. We werken samen met Sportzeker en dan ben je altijd voor alles verzekerd!',
                                gr: 'Du bist nicht gegen Schäden durch KiteActive versichert. Wir empfehlen, deine Reiseversicherung sorgfältig zu prüfen, um festzustellen, ob sie auch Extremsportarten abdeckt. Um Enttäuschungen und Probleme im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, und dann bist du immer für alles versichert!'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team",
                                du: "Professioneel team",
                                gr: "Professionelles Team"
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Get advice from travel expert AJ",
                                du: "Krijg advies van reisspecialist AJ",
                                gr: "Hol dir Ratschläge vom Reiseexperten AJ"
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: "Foto- en video bewijs / analyse",
                                gr: "Foto- und Video-Nachweis / Analyse"
                            }
                        }
                    ]
                },

                {
                    levelName: 'Level 4 - Advanced',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'Level up! There is still so much to learn',
                                du: 'Level omhoog! Er is nog zoveel te leren',
                                gr: 'Level hoch! Es gibt noch so viel zu lernen'
                            },
                            para: {
                                en: 'Do you want to improve in big air kiting? Going for that megaloop? Or do you really want to learn this sick handle pass? This course is also for waveriders who are able to do long downwinders in challenging conditions and who want to learn to perform better in the waves.',
                                du: 'Wil je verbeteren in big air kiten? Ga je voor die megaloop? Of wil je echt deze geweldige handle pass leren? Deze cursus is ook voor golfrijders die in staat zijn om lange downwinders in uitdagende omstandigheden te maken en die beter willen presteren in de golven.',
                                gr: 'Möchtest du im Big Air Kiten besser werden? Gehst du für den Megaloop? Oder möchtest du wirklich diesen coolen Handle Pass lernen? Dieser Kurs ist auch für Wellenreiter, die in der Lage sind, lange Downwinders unter herausfordernden Bedingungen zu machen und die lernen möchten, in den Wellen besser abzuschneiden.'
                            },
                            imgPosition: 'left'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Long downwinders in challenging conditions',
                                du: 'Lange downwinders in uitdagende omstandigheden',
                                gr: 'Lange Downwinders unter herausfordernden Bedingungen'
                            },
                            info: {
                                en: 'Like DEN TVTAS for example',
                                du: 'Zoals DEN TVTAS bijvoorbeeld',
                                gr: 'Wie DEN TVTAS zum Beispiel'
                            }
                        },
                        {
                            title: {
                                en: 'Jump 10 meter +',
                                du: 'Spring 10 meter +',
                                gr: 'Spring 10 Meter +'
                            },
                            info: {
                                en: 'Do you want to go skyhigh with us?',
                                du: 'Wil je met ons de lucht in?',
                                gr: 'Möchtest du mit uns in die Höhe springen?'
                            }
                        },
                        {
                            title: {
                                en: 'Megaloop',
                                du: 'Megaloop',
                                gr: 'Megaloop'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        },
                        {
                            title: {
                                en: 'Unhooked tricks',
                                du: 'Unhooked trucs',
                                gr: 'Unhooked Tricks'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'No, not with us. You need to take care of your own travel insurance. If you want, we can help you find the right one!',
                                du: 'Nee, niet bij ons. Je moet zelf zorgen voor je reisverzekering. Als je wilt, kunnen we je helpen de juiste te vinden!',
                                gr: 'Nein, nicht bei uns. Du musst dich um deine eigene Reiseversicherung kümmern. Wenn du möchtest, können wir dir helfen, die richtige zu finden!'
                            }
                        },
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'No specific hours are attached to this course. From time to time we do 1-on-1 sessions and our team will do our best to teach you as much as possible during this week.',
                                du: 'Er zijn geen specifieke uren aan deze cursus verbonden. Af en toe doen we 1-op-1 sessies en ons team zal ons best doen om je deze week zoveel mogelijk te leren.',
                                gr: 'Es sind keine spezifischen Stunden mit diesem Kurs verbunden. Von Zeit zu Zeit führen wir 1-zu-1-Sitzungen durch, und unser Team wird unser Bestes tun, um dir während dieser Woche so viel wie möglich beizubringen.'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Make progression in ideal conditions",
                                du: 'Maak vooruitgang onder ideale omstandigheden',
                                gr: 'Mach Fortschritte unter idealen Bedingungen'
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Advice from our travel expert AJ",
                                du: 'Advies van onze reisspecialist AJ',
                                gr: 'Rat von unserem Reiseexperten AJ'
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Headset coaching",
                                du: 'Coaching via headset',
                                gr: 'Coaching über Headset'
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: 'Foto- en video-analyse / bewijs',
                                gr: 'Foto- und Videoanalyse / Nachweis'
                            }
                        }
                    ]
                },
            ],


            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kitegear verhuur",
                    gr: "Kiteausrüstungsverleih"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en zorgen ervoor dat je altijd met de juiste maat kite op het water bent. Geen zin om al je materiaal mee te nemen? Geen probleem!",
                    gr: "Bei uns findest du das neueste Kitematerial. Wir haben die meisten Marken verfügbar und sorgen dafür, dass du immer mit der richtigen Kite-Größe auf dem Wasser bist. Keine Lust, all dein Material mitzunehmen? Kein Problem!"
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree"
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Check your insurance. If you need help with the right insurance please ask us for help! Not all insurance companies cover kitesurf material.",
                    du: "Je zorgt ervoor dat je je correcte niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt afronden, kun je het openstaande bedrag op verschillende manieren bij ons vereffenen. - Je kunt het openstaande bedrag in onze webshop gebruiken voor (kite)materiaal - Je kunt het gebruiken voor andere KiteActive-reizen of het als cadeaukaart aan vrienden geven - Je kunt je pakket afronden in 1 van onze scholen in Nederland. Controleer je verzekering. Als je hulp nodig hebt bij de juiste verzekering, vraag het ons dan! Niet alle verzekeringsmaatschappijen dekken kitesurfmateriaal.",
                    gr: "Du stellst sicher, dass du während deiner Buchung dein korrektes Niveau angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den offenen Betrag auf verschiedene Weise bei uns begleichen. - Du kannst den offenen Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden - Du kannst ihn für andere KiteActive-Reisen verwenden oder als Geschenkkarte an Freunde geben - Du kannst dein Paket in einer unserer Schulen in Holland abschließen. Überprüfe deine Versicherung. Wenn du Hilfe bei der richtigen Versicherung benötigst, frag uns um Hilfe! Nicht alle Versicherungsunternehmen decken Kitesurfmaterial ab."
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je op de veiligste manier kitesurfen leert, moet je altijd op jezelf en anderen letten. Luister altijd goed naar de instructies van ons team.",
                    gr: "Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport, und obwohl wir sicherstellen, dass du bei uns auf die sicherste Weise Kitesurfen lernst, musst du dir immer selbst und anderen bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }

        },

        accommodation: {
            main_title: {
                en: "Hawaiian Resort in Hurghada",
                gr: "Hawaiian Resort in Hurghada",
                du: "Hawaiian Resort in Hurghada"
            },
            main_desc: {
                en: "The Hawaiian Resort in Hurghada is a stunning beachfront destination that embodies the essence of paradise. Nestled along the beautiful Red Sea coast, this resort offers guests a perfect blend of relaxation and adventure, making it an ideal choice for kitesurfers and beach lovers alike. Accommodations: The resort features a variety of spacious rooms and suites, each designed with comfort and style in mind. Many accommodations boast breathtaking views of the sea or lush gardens, providing a serene atmosphere for your stay.",
                gr: "Das Hawaiian Resort in Hurghada ist ein atemberaubendes Strandresort, das die Essenz des Paradieses verkörpert. Eingebettet an der wunderschönen Küste des Roten Meeres bietet dieses Resort den Gästen die perfekte Mischung aus Entspannung und Abenteuer und ist somit die ideale Wahl für Kitesurfer und Strandliebhaber. Unterkünfte: Das Resort bietet eine Vielzahl geräumiger Zimmer und Suiten, die alle mit Komfort und Stil gestaltet sind. Viele Unterkünfte bieten einen atemberaubenden Blick auf das Meer oder üppige Gärten und schaffen eine ruhige Atmosphäre für Ihren Aufenthalt.",
                du: "Het Hawaiian Resort in Hurghada is een prachtige bestemming aan het strand die de essentie van het paradijs belichaamt. Gelegen langs de prachtige kust van de Rode Zee biedt dit resort gasten de perfecte mix van ontspanning en avontuur, waardoor het een ideale keuze is voor zowel kitesurfers als strandliefhebbers. Accommodaties: Het resort beschikt over een verscheidenheid aan ruime kamers en suites, elk ontworpen met comfort en stijl in gedachten. Veel accommodaties bieden een adembenemend uitzicht op de zee of weelderige tuinen, wat zorgt voor een serene sfeer tijdens je verblijf."
            },
            card_details: [
                {
                    imgSrc: endless_acc_img1,
                    title: {
                        en: "SHARED ROOM",
                        gr: "GETEILTES ZIMMER",
                        du: "GEDEELDE KAMER"
                    },
                    description: {
                        en: "Share your room with max. 3 other kiters! We try not to mix male/female. Standard option!",
                        gr: "Teilen Sie Ihr Zimmer mit maximal 3 anderen Kitern! Wir versuchen, Männer und Frauen nicht zu mischen. Standardoption!",
                        du: "Deel je kamer met maximaal 3 andere kiters! We proberen mannen en vrouwen niet te mengen. Standaardoptie!"
                    }
                },
                {
                    imgSrc: endless_acc_img2,
                    title: {
                        en: "PRIVATE ROOM",
                        gr: "PRIVATZIMMER",
                        du: "PRIVÉKAMER"
                    },
                    description: {
                        en: "Private room for single use. If you want more privacy it will cost you €25/night extra",
                        gr: "Privatzimmer zur Einzelnutzung. Wenn Sie mehr Privatsphäre wünschen, kostet es €25/Nacht extra",
                        du: "Privé kamer voor eenpersoonsgebruik. Als je meer privacy wilt, kost het je €25/nacht extra"
                    }
                },
                {
                    imgSrc: endless_acc_img3,
                    title: {
                        en: "DOUBLE ROOM FOR COUPLES",
                        gr: "DOPPELZIMMER FÜR PAARE",
                        du: "TWEEPERSOONSKAMER VOOR KOPPELS"
                    },
                    description: {
                        en: "Private double room for couples with an ensuite bathroom. Additional charge of €20/night",
                        gr: "Privates Doppelzimmer für Paare mit eigenem Bad. Zusätzliche Gebühr von €20/Nacht",
                        du: "Privé tweepersoonskamer voor koppels met een eigen badkamer. Extra toeslag van €20/nacht"
                    }
                },
                {
                    imgSrc: endless_acc_img4,
                    title: {
                        en: "LARGE POOLS",
                        gr: "GROSSE POOLS",
                        du: "GROTE ZWEMBADEN"
                    },
                    description: {
                        en: "The golden hour with ice-cold drinks and fun with plenty of lounge areas where you can retreat.",
                        gr: "Die goldene Stunde mit eiskalten Getränken und Spaß in vielen Lounge-Bereichen, in denen Sie sich zurückziehen können.",
                        du: "Het gouden uur met ijskoude drankjes en plezier met tal van loungegebieden waar je je kunt terugtrekken."
                    }
                },
                {
                    imgSrc: endless_acc_img5,
                    title: {
                        en: "AT THE KITE SPOT",
                        gr: "AM KITE-SPOT",
                        du: "BIJ DE KITESPOT"
                    },
                    description: {
                        en: "A+ location on the beach. Free storage and your kite will be neatly cleaned up every day by our kite boys!",
                        gr: "A+ Lage am Strand. Kostenlose Lagerung und Ihr Kite wird jeden Tag von unseren Kite-Jungs ordentlich aufgeräumt!",
                        du: "A+ locatie op het strand. Gratis opslag en je kite wordt elke dag netjes opgeruimd door onze kitejongens!"
                    }
                },
                {
                    imgSrc: endless_acc_img6,
                    title: {
                        en: "CLEAN ROOMS",
                        gr: "SAUBERE ZIMMER",
                        du: "SCHONE KAMERS"
                    },
                    description: {
                        en: "This huge hotel is newly built with comfortable and clean rooms.",
                        gr: "Dieses große Hotel ist neu gebaut mit komfortablen und sauberen Zimmern.",
                        du: "Dit grote hotel is nieuw gebouwd met comfortabele en schone kamers."
                    }
                }
            ],
            location_title: {
                en: "How to get here",
                gr: "Wie man hierher kommt",
                du: "Hoe kom je hier"
            },
            location_subtitle: {
                en: "Hurghada (HRG)",
                gr: "Hurghada (HRG)",
                du: "Hurghada (HRG)"
            },
            location_desc: {
                en: "Getting to Hurghada is straightforward and convenient, making it an ideal destination for your kitesurfing adventure. Flights are also cheap and affordable starting from € 300 retour! Here’s a quick guide:",
                gr: "Die Anreise nach Hurghada ist einfach und bequem, was es zu einem idealen Ziel für Ihr Kitesurf-Abenteuer macht. Flüge sind auch günstig und erschwinglich, beginnend bei €300 retour! Hier ist ein kurzer Leitfaden:",
                du: "Hurghada is eenvoudig en gemakkelijk te bereiken, waardoor het een ideale bestemming is voor je kitesurfavontuur. Vluchten zijn ook goedkoop en betaalbaar vanaf €300 retour! Hier is een korte gids:"
            }
        },


        hosted: [
            {
                hosted_img: hosted_img1,
                hosted_name: {
                    en: "Roderick Pijls",
                    gr: "Roderick Pijls",
                    du: "Roderick Pijls"
                },
                hosted_position: {
                    en: "",
                    gr: "",
                    du: ""
                },
                kite_exp: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_para: {
                    en: "Having a background of professional kitesurfer for over 12 years has taught me a lot. Seeing many cultures, meeting great people and knowing everything from the best spots in the world has convinced me to start sharing this with everyone who has that little 'Columbus' inside him/ herself.",
                    gr: "Eine Hintergrund als professioneller Kitesurfer seit über 12 Jahren hat mir viel beigebracht. Viele Kulturen zu sehen, großartige Menschen zu treffen und alles über die besten Spots der Welt zu wissen, hat mich überzeugt, dies mit jedem zu teilen, der das kleine 'Kolumbus' in sich trägt.",
                    du: "Een achtergrond als professionele kitesurfer gedurende meer dan 12 jaar heeft me veel geleerd. Het zien van veel culturen, het ontmoeten van geweldige mensen en alles weten over de beste spots ter wereld heeft me ervan overtuigd dit te delen met iedereen die dat kleine 'Columbus'-gevoel in zich heeft."
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "",
                    gr: "",
                    du: ""
                }
            },
            {
                hosted_img: hosted_img3,
                hosted_name: {
                    en: "Bas ”=cool”",
                    gr: "Bas „=cool“",
                    du: "Bas '=cool'"
                },
                hosted_position: {
                    en: "Photographer & Instructor",
                    gr: "Fotograf & Instruktor",
                    du: "Fotograaf & Instructeur"
                },
                kite_exp: {
                    en: "8 years kite experience",
                    gr: "8 Jahre Kiterfahrung",
                    du: "8 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "I did my traineeship at Kiteactive when I was 16, and since then I have been part of the Kiteactive family!",
                    gr: "Ich habe mein Praktikum bei Kiteactive gemacht, als ich 16 war, und seitdem bin ich Teil der Kiteactive-Familie!",
                    du: "Ik heb mijn stage bij Kiteactive gedaan toen ik 16 was, en sindsdien ben ik onderdeel van de Kiteactive-familie!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favorite Destination",
                    gr: "Lieblingsziel",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "I like Sicily",
                    gr: "Ich mag Sizilien",
                    du: "Ik hou van Sicilië"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "7 years",
                    gr: "7 Jahre",
                    du: "7 jaar"
                }
            },
        ],

        reviews: {}
    },




    // BRAZIL trip data  ............................................

    {
        name: 'Brazil',
        vedio: Brazil_vedio,
        backcover: brazilcover,

        alltabs: {
            highlight: [
                {
                    text: {
                        en: "Travel with your kite along the most pristine locations",
                        gr: "Reise mit deinem Kite zu den unberührtesten Orten",
                        du: "Reis met je kite langs de meest ongerepte locaties"
                    }
                },
                {
                    text: {
                        en: "Everyday wind",
                        gr: "Täglicher Wind",
                        du: "Elke dag wind"
                    }
                },
                {
                    text: {
                        en: "100% fun guarantee",
                        gr: "100% Spaßgarantie",
                        du: "100% pleziergarantie"
                    }
                },
                {
                    text: {
                        en: "Meet nice kiters with the same level",
                        gr: "Treffe nette Kiter auf dem gleichen Level",
                        du: "Ontmoet leuke kiters van hetzelfde niveau"
                    }
                },
                {
                    text: {
                        en: "A professional team is ready to make as much progress as possible with you",
                        gr: "Ein professionelles Team ist bereit, mit dir so viel Fortschritt wie möglich zu machen",
                        du: "Een professioneel team staat klaar om zoveel mogelijk vooruitgang met je te boeken"
                    }
                }
            ],
            question: [
                {
                    title: {
                        en: "Which kite material should I bring?",
                        gr: "Welches Kitematerial sollte ich mitbringen?",
                        du: "Welk kitemateriaal moet ik meenemen?"
                    },
                    para: {
                        en: "The wind always blows at least 16 knots and often even harder around 20 knots. In the afternoon the wind sometimes decreases somewhat, so it is also good to take large sizes with you. the Golden rule: Bring all the kite sizes you have!!",
                        gr: "Der Wind weht immer mindestens 16 Knoten, oft sogar stärker um 20 Knoten. Am Nachmittag lässt der Wind manchmal etwas nach, daher ist es auch gut, große Größen mitzunehmen. Die goldene Regel: Bringe alle Kitedimensionen mit, die du hast!!",
                        du: "De wind waait altijd minimaal 16 knopen en vaak nog harder rond de 20 knopen. In de middag neemt de wind soms wat af, dus het is ook goed om grote maten mee te nemen. De gouden regel: Breng alle kitematen die je hebt mee!!"
                    }
                },
                {
                    title: {
                        en: "When does the downwinder start?",
                        gr: "Wann beginnt der Downwinder?",
                        du: "Wanneer begint de downwinder?"
                    },
                    para: {
                        en: "For the Chill & Adventure (& XXL) downwinders can be checked in at our KiteActive pousada on the relevant Saturday (~2:00 PM). Here we gather with the entire group, will eat a delicious meal that evening and the next morning (Sunday morning) we leave early with the downwinder to our first overnight location.",
                        gr: "Für die Chill & Adventure (& XXL) Downwinder kannst du am betreffenden Samstag (~14:00 Uhr) in unserer KiteActive Pousada einchecken. Hier versammeln wir uns mit der gesamten Gruppe, essen an diesem Abend eine köstliche Mahlzeit und am nächsten Morgen (Sonntagmorgen) fahren wir früh mit dem Downwinder zu unserem ersten Übernachtungsort.",
                        du: "Voor de Chill & Adventure (& XXL) downwinders kun je inchecken bij onze KiteActive pousada op de desbetreffende zaterdag (~14:00 uur). Hier verzamelen we met de hele groep, eten we een heerlijk diner en de volgende ochtend (zondagochtend) vertrekken we vroeg met de downwinder naar onze eerste overnachtingsplek."
                    }
                },
                {
                    title: {
                        en: "For which level is this trip?",
                        gr: "Für welches Niveau ist diese Reise?",
                        du: "Voor welk niveau is deze reis?"
                    },
                    para: {
                        en: "The chill downwinder for level 3 and the XXL & Adventure for level 4 Have done a downwinder at least once and be able to sail upwind independently and perform a self rescue.",
                        gr: "Der Chill-Downwinder ist für Level 3, und der XXL & Adventure für Level 4. Du solltest mindestens einmal einen Downwinder gemacht haben, in der Lage sein, selbstständig gegen den Wind zu segeln und eine Selbstrettung durchzuführen.",
                        du: "De chill downwinder is voor niveau 3 en de XXL & Adventure voor niveau 4. Je moet minstens één keer een downwinder hebben gedaan en zelfstandig tegen de wind in kunnen varen en een zelfredding kunnen uitvoeren."
                    }
                },
                {
                    title: {
                        en: "Where does the downwinder end?",
                        gr: "Wo endet der Downwinder?",
                        du: "Waar eindigt de downwinder?"
                    },
                    para: {
                        en: "Your downwinder ends at the final destination (Jericoacoara or Atins). Upon arrival, we will all enjoy a victory dinner, dance the night away and enjoy a delicious breakfast the next morning (this overnight stay is included). The next morning our drivers and supervisors will have to drive back to Cumbuco. You can decide for yourself whether you ride back with our crew (free of charge) or stay at the final destination.",
                        gr: "Dein Downwinder endet am Ziel (Jericoacoara oder Atins). Bei der Ankunft genießen wir alle ein Siegesdinner, tanzen die Nacht durch und genießen am nächsten Morgen ein köstliches Frühstück (diese Übernachtung ist inbegriffen). Am nächsten Morgen müssen unsere Fahrer und Betreuer nach Cumbuco zurückfahren. Du kannst selbst entscheiden, ob du kostenlos mit unserer Crew zurückfährst oder am Zielort bleibst.",
                        du: "Je downwinder eindigt op de eindbestemming (Jericoacoara of Atins). Bij aankomst genieten we allemaal van een overwinningsdiner, dansen we de hele nacht en de volgende ochtend genieten we van een heerlijk ontbijt (deze overnachting is inbegrepen). De volgende ochtend moeten onze chauffeurs en begeleiders terugrijden naar Cumbuco. Je kunt zelf beslissen of je met onze crew gratis terugrijdt of op de eindbestemming blijft."
                    }
                },
                {
                    title: {
                        en: "Can I book extra nights?",
                        gr: "Kann ich zusätzliche Nächte buchen?",
                        du: "Kan ik extra nachten boeken?"
                    },
                    para: {
                        en: "Yes no problem, discuss with our team and we will help you with anything you need!",
                        gr: "Ja, kein Problem, sprich mit unserem Team und wir helfen dir bei allem, was du brauchst!",
                        du: "Ja, geen probleem, bespreek het met ons team en we helpen je met alles wat je nodig hebt!"
                    }
                },
                {
                    title: {
                        en: "Which flights are recommended?",
                        gr: "Welche Flüge werden empfohlen?",
                        du: "Welke vluchten worden aanbevolen?"
                    },
                    para: {
                        en: "From Europe KLM is a good choice or FlyTAP. Roughly it will cost you between €800-1200 return.",
                        gr: "Von Europa aus sind KLM oder FlyTAP eine gute Wahl. Ungefähr wird es dich zwischen 800-1200 € für Hin- und Rückflug kosten.",
                        du: "Vanuit Europa zijn KLM of FlyTAP een goede keuze. Het kost je ongeveer tussen de €800-1200 voor een retour."
                    }
                },
                {
                    title: {
                        en: "Can I sleep with my friend in the same room?",
                        gr: "Kann ich mit meinem Freund im selben Zimmer schlafen?",
                        du: "Kan ik met mijn vriend in dezelfde kamer slapen?"
                    },
                    para: {
                        en: "Yes, please let us know during your booking and we will fix it!",
                        gr: "Ja, lass es uns bitte bei der Buchung wissen, und wir regeln das!",
                        du: "Ja, laat het ons weten tijdens je boeking en wij regelen het!"
                    }
                }
            ]
            ,
        },

        overview: {
            trip_heading: {
                en: "The land of salsa, football and.. WIND!",
                gr: "Das Land der Salsa, des Fußballs und... WIND!",
                du: "Het land van salsa, voetbal en... WIND!"
            },
            trip_para: {
                en: "For years we have been searching for the most beautiful destinations that combine key factors such as: reliable wind, pristine sandy beaches, rolling waves, and unlimited space on the water. Have you ever dreamed about such a place? Then you have just found the trip that will make your dream come true! When you join our XXL Downwinder through Brazil, one of the best kitesurf spots in the world, we leave the busy beaches and mass tourism behind us. You will discover new kitesurf spots and be coached by our pro kitesurfers who have been coming here for years. Life is one big journey, so why not travel in style!",
                gr: "Jahrelang haben wir nach den schönsten Reisezielen gesucht, die wichtige Faktoren wie: zuverlässigen Wind, unberührte Sandstrände, rollende Wellen und unbegrenzten Platz auf dem Wasser kombinieren. Hast du jemals von einem solchen Ort geträumt? Dann hast du gerade die Reise gefunden, die deinen Traum wahr werden lässt! Wenn du dich unserem XXL Downwinder durch Brasilien, einem der besten Kitesurfspots der Welt, anschließt, lassen wir die überfüllten Strände und den Massentourismus hinter uns. Du wirst neue Kitesurfspots entdecken und von unseren professionellen Kitesurfern gecoacht, die schon seit Jahren hierher kommen. Das Leben ist eine große Reise, also warum nicht stilvoll reisen!",
                du: "Jarenlang hebben we gezocht naar de mooiste bestemmingen die belangrijke factoren combineren zoals: betrouwbare wind, ongerepte zandstranden, golvende zeeën en onbeperkte ruimte op het water. Heb je ooit gedroomd van zo'n plek? Dan heb je net de reis gevonden die je droom waar zal maken! Wanneer je je aansluit bij onze XXL Downwinder door Brazilië, een van de beste kitesurfplekken ter wereld, laten we de drukke stranden en het massatoerisme achter ons. Je zult nieuwe kitesurfplekken ontdekken en gecoacht worden door onze professionele kitesurfers die hier al jaren komen. Het leven is een grote reis, waarom niet in stijl reizen!"
            },
            img1: surf_img7,
            img1_head: {
                en: "Save the date",
                gr: "Das Datum vormerken",
                du: "Markeer de datum"
            },
            img1_para: {
                en: "Our KiteActive downwinders in Brazil are from 2 - 26 Nov or reach out to us to plan your custom downwinder!",
                gr: "Unsere KiteActive Downwinder in Brasilien finden vom 2. bis 26. November statt, oder kontaktiere uns, um deinen individuellen Downwinder zu planen!",
                du: "Onze KiteActive downwinders in Brazilië zijn van 2 - 26 nov of neem contact met ons op om je aangepaste downwinder te plannen!"
            },
            img2: surf_img8,
            img2_head: {
                en: "Only stars!",
                gr: "Nur Sterne!",
                du: "Alleen sterren!"
            },
            img2_para: {
                en: "Read the reviews of our travellers",
                gr: "Lies die Bewertungen unserer Reisenden",
                du: "Lees de recensies van onze reizigers"
            },
            img3: surf_img10,
            img3_head: {
                en: "Skill set",
                gr: "Fähigkeiten",
                du: "Vaardigheden"
            },
            img3_para: {
                en: "Level 3-4 Intermediate to Advanced",
                gr: "Niveau 3-4 Mittelstufe bis Fortgeschrittene",
                du: "Niveau 3-4 Gemiddeld tot Gevorderd"
            },
            img4: surf_img13,
            img4_head: {
                en: "Join KiteActive",
                gr: "Mach mit bei KiteActive",
                du: "Doe mee met KiteActive"
            },
            img4_para: {
                en: "Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "Tritt dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / KA-Team zu chatten.",
                du: "Doe mee in de groepschat om op de hoogte te blijven of te chatten met andere reizigers / KA-team."
            },
            gall_img1: brazil_img1,
            gall_img2: brazil_img2,
            gall_img3: brazil_img3,
            gall_img4: brazil_img4,
        },

        kitespot: [
            {
                imgSrc: brazil_kitespotimg1,
                text: {
                    en: 'Location, routes & spots',
                    du: 'Locatie, routes & spots',
                    gr: 'Standort, Routen & Spots'
                },
                heading: {
                    en: 'Capoeira, 100% wind and caipirinha!',
                    du: 'Capoeira, 100% wind en caipirinha!',
                    gr: 'Capoeira, 100% Wind und Caipirinha!'
                },
                para: {
                    en: 'There is no better way to travel than with your kite! When we start with our downwinder in Brazil, the 4×4 jeeps will follow us while we chase the wind! During the Brazilian downwinder, we will guide you to all the hidden lagoons and undiscovered places, with the final destination at surfers’ paradise and the ‘wild west’ of Brazil: Jericoacoara! This place is in the top 10 of the most beautiful beaches in the world. Throughout this kitesurf travel in Brazil, everything is arranged down to the last detail, and professional travel guidance from KiteActive will accompany you to show you the way! Get in touch with us and get ready for the kite holiday Brazil experience of a lifetime. The wind is blowing and the waves are rolling!',
                    du: 'Er is geen betere manier om te reizen dan met je kite! Wanneer we beginnen met onze downwinder in Brazilië, zullen de 4×4 jeeps ons volgen terwijl we de wind achterna jagen! Tijdens de Braziliaanse downwinder leiden we je naar alle verborgen lagunes en onontdekte plekken, met als eindbestemming het surfersparadijs en het ‘wilde westen’ van Brazilië: Jericoacoara! Deze plek staat in de top 10 van de mooiste stranden ter wereld. Gedurende deze kitesurfreis in Brazilië is alles tot in de puntjes geregeld, en professionele reisbegeleiding van KiteActive zal je begeleiden om je de weg te wijzen! Neem contact met ons op en bereid je voor op de kitesurfvakantie-ervaring van je leven. De wind waait en de golven rollen!',
                    gr: 'Es gibt keinen besseren Weg zu reisen als mit deinem Kite! Wenn wir mit unserem Downwinder in Brasilien starten, werden uns die 4×4-Jeeps folgen, während wir dem Wind nachjagen! Während des brasilianischen Downwinders führen wir dich zu allen versteckten Lagunen und unentdeckten Orten, mit dem endgültigen Ziel im Surferparadies und dem „Wilden Westen“ Brasiliens: Jericoacoara! Dieser Ort gehört zu den Top 10 der schönsten Strände der Welt. Während dieser Kitesurfreise in Brasilien ist alles bis ins kleinste Detail organisiert, und die professionelle Reisebegleitung von KiteActive wird dich begleiten, um dir den Weg zu zeigen! Kontaktiere uns und mach dich bereit für die Kite-Urlaubserfahrung deines Lebens. Der Wind weht und die Wellen rollen!'
                },
                imgPosition: 'right',
            },

            {
                imgSrc: brazil_kitespotimg2,
                text: {
                    en: 'Endless summer',
                    du: 'Eindeloze zomer',
                    gr: 'Endloser Sommer'
                },
                heading: {
                    en: 'Follow the summer!',
                    du: 'Volg de zomer!',
                    gr: 'Folge dem Sommer!'
                },
                para: {
                    en: "The climate in Brazil is great. With a water temperature of 25 degrees and an air temperature of +30 degrees, you can walk around in your bikini or board shorts all day long. The evenings are also wonderfully warm, so it’s the perfect way to extend your summer! The season in Brazil starts from June to the end of December. KiteActive settles down in Brazil from mid-October to the end of December, making it one of the best kitesurf camps in the world.",
                    du: "Het klimaat in Brazilië is geweldig. Met een watertemperatuur van 25 graden en een luchttemperatuur van +30 graden kun je de hele dag in je bikini of boardshorts rondlopen. De avonden zijn ook heerlijk warm, dus het is de perfecte manier om je zomer te verlengen! Het seizoen in Brazilië loopt van juni tot het einde van december. KiteActive vestigt zich in Brazilië van half oktober tot het einde van december, waardoor het een van de beste kitesurfkampen ter wereld is.",
                    gr: "Das Klima in Brasilien ist großartig. Mit einer Wassertemperatur von 25 Grad und einer Lufttemperatur von +30 Grad kannst du den ganzen Tag in deinem Bikini oder Boardshorts herumlaufen. Auch die Abende sind wunderbar warm, also ist es die perfekte Möglichkeit, deinen Sommer zu verlängern! Die Saison in Brasilien dauert von Juni bis Ende Dezember. KiteActive lässt sich von Mitte Oktober bis Ende Dezember in Brasilien nieder, was es zu einem der besten Kitesurf-Camps der Welt macht."
                },
                imgPosition: 'left',
            },
        ],
        packages: {
            levelsData: [
                {
                    levelName: 'Level 3 - Independent',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk aangemoedigd en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Learn new tricks and make progression',
                                du: 'Leer nieuwe trucs en maak vooruitgang',
                                gr: 'Lerne neue Tricks und mache Fortschritte'
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: 'Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste spron naar een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!',
                                gr: 'Dies ist das ultimative Paket für jeden, der bereits kitesurfen kann, aber Fortschritte machen und neue Tricks lernen möchte. Von deinem allerersten Sprung bis zum Kiteloop-Handle-Pass. Unser Team ist bereit, dir mit den richtigen Tipps und Tricks zu helfen, um deine Ziele zu erreichen!'
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je van deze cursus verwachten?',
                                gr: 'Was kannst du von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: 'Tijdens de cursus bekijken we wat je persoonlijke doelen zijn en wat haalbaar is. We gaan samen de theorie door en proberen je gedurende de week zoveel mogelijk te stimuleren, coachen en motiveren om nieuwe dingen op een veilige manier te proberen.',
                                gr: 'Während des Kurses werden wir uns ansehen, was deine persönlichen Ziele sind und was machbar ist. Wir gehen gemeinsam die Theorie durch und versuchen während der Woche, dich so gut wie möglich zu pushen, zu coachen und zu motivieren, um neue Dinge auf sichere Weise auszuprobieren.'
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'The basics of pop',
                                du: 'De basis van pop',
                                gr: 'Die Grundlagen des Pops'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitecarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kitesurfkarriere benötigst!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first jump',
                                du: 'Je eerste spron',
                                gr: 'Dein erster Sprung'
                            },
                            info: {
                                en: 'You want to learn how to go skyhigh?',
                                du: 'Wil je leren hoe je de lucht in kunt gaan?',
                                gr: 'Möchtest du lernen, wie du in die Höhe springen kannst?'
                            }
                        },
                        {
                            title: {
                                en: 'Back & Front roll',
                                du: 'Back & Front roll',
                                gr: 'Back & Frontroll'
                            },
                            info: {
                                en: 'Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!',
                                du: 'Maak een salto in de lucht! Je denkt dat het moeilijk is, maar we leren je deze geweldige truc in 1 week!',
                                gr: 'Mach einen Salto in der Luft! Du denkst, es ist schwierig, aber wir werden dir diesen erstaunlichen Trick in 1 Woche beibringen!'
                            }
                        },
                        {
                            title: {
                                en: 'Board grabs, onefooters and board-offs',
                                du: 'Board grabs, onefooters en board-offs',
                                gr: 'Board Grabs, Onefooters und Board-offs'
                            },
                            info: {
                                en: 'Grab the board in front of the camera!',
                                du: 'Pak het board voor de camera!',
                                gr: 'Greife das Board vor der Kamera!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first kiteloop (downloop)',
                                du: 'Je eerste kiteloop (downloop)',
                                gr: 'Dein erster Kiteloop (Downloop)'
                            },
                            info: {
                                en: 'Fly like a rollercoaster',
                                du: 'Vlieg als een achtbaan',
                                gr: 'Flieg wie eine Achterbahn'
                            }
                        },
                        {
                            title: {
                                en: 'Your first downwinder',
                                du: 'Je eerste downwinder',
                                gr: 'Dein erster Downwinder'
                            },
                            info: {
                                en: 'With a downwinder you are doing a kite tour along the coast!',
                                du: 'Met een downwinder maak je een kitetour langs de kust!',
                                gr: 'Mit einem Downwinder machst du eine Kitetour entlang der Küste!'
                            }
                        },
                        {
                            title: {
                                en: 'Learn how to ride strapless / bottom turns / wave skills',
                                du: 'Leer hoe je strapless kunt rijden / bottom turns / golfvaardigheden',
                                gr: 'Lerne, wie man strapless fährt / Bottom Turns / Wellenfähigkeiten'
                            },
                            info: {
                                en: 'Upgrade your skills in the waves',
                                du: 'Verbeter je vaardigheden in de golven',
                                gr: 'Verbessere deine Fähigkeiten in den Wellen'
                            }
                        },
                        {
                            title: {
                                en: 'Get unhooked!',
                                du: 'Ga los!',
                                gr: 'Lass los!'
                            },
                            info: {
                                en: 'Learn how to do unhooked tricks like Raily, S-bend or maybe even a handle pass?',
                                du: 'Leer hoe je unhooked trucs doet zoals Raily, S-bend of misschien zelfs een handle pass?',
                                gr: 'Lerne, wie man unhooked Tricks wie Raily, S-bend oder vielleicht sogar einen Handle Pass macht?'
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.',
                                du: 'We linken geen exacte uren aan dit pakket. We proberen iedereen zoveel mogelijk te stimuleren en bieden af en toe 1-op-1 begeleiding. Het doel is om zoveel mogelijk vooruitgang te boeken en tijdens deze cursus geven we je alle tools die je nodig hebt om te blijven oefenen.',
                                gr: 'Wir verlinken keine genauen Stunden mit diesem Paket. Wir versuchen, jeden so gut wie möglich zu unterstützen und bieten gelegentlich 1-zu-1-Begleitung an. Ziel ist es, so viel Fortschritt wie möglich zu machen, und während dieses Kurses geben wir dir alle Werkzeuge, die du brauchst, um weiter zu üben.'
                            }
                        },
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: 'Is kitemateriaal inbegrepen bij dit pakket?',
                                gr: 'Ist Kitematerial in diesem Paket enthalten?'
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 399 / week',
                                du: 'Nee, je moet je eigen kitemateriaal meenemen of een compleet set huren voor € 399 / week',
                                gr: 'Nein, du musst dein eigenes Kitematerial mitbringen oder ein komplettes Set für 399 € / Woche mieten.'
                            }
                        },
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!',
                                du: 'Je bent niet verzekerd tegen schade via KiteActive. We raden je aan om je reisverzekering goed te controleren om te zien of deze ook extreme sporten dekt. Dit om teleurstellingen en gedoe op vakantie te voorkomen. We werken samen met Sportzeker en dan ben je altijd voor alles verzekerd!',
                                gr: 'Du bist nicht gegen Schäden durch KiteActive versichert. Wir empfehlen, deine Reiseversicherung sorgfältig zu prüfen, um festzustellen, ob sie auch Extremsportarten abdeckt. Um Enttäuschungen und Probleme im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, und dann bist du immer für alles versichert!'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team",
                                du: "Professioneel team",
                                gr: "Professionelles Team"
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Get advice from travel expert AJ",
                                du: "Krijg advies van reisspecialist AJ",
                                gr: "Hol dir Ratschläge vom Reiseexperten AJ"
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: "Foto- en video bewijs / analyse",
                                gr: "Foto- und Video-Nachweis / Analyse"
                            }
                        }
                    ]
                },

                {
                    levelName: 'Level 4 - Advanced',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'Level up! There is still so much to learn',
                                du: 'Level omhoog! Er is nog zoveel te leren',
                                gr: 'Level hoch! Es gibt noch so viel zu lernen'
                            },
                            para: {
                                en: 'Do you want to improve in big air kiting? Going for that megaloop? Or do you really want to learn this sick handle pass? This course is also for waveriders who are able to do long downwinders in challenging conditions and who want to learn to perform better in the waves.',
                                du: 'Wil je verbeteren in big air kiten? Ga je voor die megaloop? Of wil je echt deze geweldige handle pass leren? Deze cursus is ook voor golfrijders die in staat zijn om lange downwinders in uitdagende omstandigheden te maken en die beter willen presteren in de golven.',
                                gr: 'Möchtest du im Big Air Kiten besser werden? Gehst du für den Megaloop? Oder möchtest du wirklich diesen coolen Handle Pass lernen? Dieser Kurs ist auch für Wellenreiter, die in der Lage sind, lange Downwinders unter herausfordernden Bedingungen zu machen und die lernen möchten, in den Wellen besser abzuschneiden.'
                            },
                            imgPosition: 'left'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Long downwinders in challenging conditions',
                                du: 'Lange downwinders in uitdagende omstandigheden',
                                gr: 'Lange Downwinders unter herausfordernden Bedingungen'
                            },
                            info: {
                                en: 'Like DEN TVTAS for example',
                                du: 'Zoals DEN TVTAS bijvoorbeeld',
                                gr: 'Wie DEN TVTAS zum Beispiel'
                            }
                        },
                        {
                            title: {
                                en: 'Jump 10 meter +',
                                du: 'Spring 10 meter +',
                                gr: 'Spring 10 Meter +'
                            },
                            info: {
                                en: 'Do you want to go skyhigh with us?',
                                du: 'Wil je met ons de lucht in?',
                                gr: 'Möchtest du mit uns in die Höhe springen?'
                            }
                        },
                        {
                            title: {
                                en: 'Megaloop',
                                du: 'Megaloop',
                                gr: 'Megaloop'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        },
                        {
                            title: {
                                en: 'Unhooked tricks',
                                du: 'Unhooked trucs',
                                gr: 'Unhooked Tricks'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'No, not with us. You need to take care of your own travel insurance. If you want, we can help you find the right one!',
                                du: 'Nee, niet bij ons. Je moet zelf zorgen voor je reisverzekering. Als je wilt, kunnen we je helpen de juiste te vinden!',
                                gr: 'Nein, nicht bei uns. Du musst dich um deine eigene Reiseversicherung kümmern. Wenn du möchtest, können wir dir helfen, die richtige zu finden!'
                            }
                        },
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'No specific hours are attached to this course. From time to time we do 1-on-1 sessions and our team will do our best to teach you as much as possible during this week.',
                                du: 'Er zijn geen specifieke uren aan deze cursus verbonden. Af en toe doen we 1-op-1 sessies en ons team zal ons best doen om je deze week zoveel mogelijk te leren.',
                                gr: 'Es sind keine spezifischen Stunden mit diesem Kurs verbunden. Von Zeit zu Zeit führen wir 1-zu-1-Sitzungen durch, und unser Team wird unser Bestes tun, um dir während dieser Woche so viel wie möglich beizubringen.'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Make progression in ideal conditions",
                                du: 'Maak vooruitgang onder ideale omstandigheden',
                                gr: 'Mach Fortschritte unter idealen Bedingungen'
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Advice from our travel expert AJ",
                                du: 'Advies van onze reisspecialist AJ',
                                gr: 'Rat von unserem Reiseexperten AJ'
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Headset coaching",
                                du: 'Coaching via headset',
                                gr: 'Coaching über Headset'
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: 'Foto- en video-analyse / bewijs',
                                gr: 'Foto- und Videoanalyse / Nachweis'
                            }
                        }
                    ]
                },
            ],


            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kitegear verhuur",
                    gr: "Kiteausrüstungsverleih"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en zorgen ervoor dat je altijd met de juiste maat kite op het water bent. Geen zin om al je materiaal mee te nemen? Geen probleem!",
                    gr: "Bei uns findest du das neueste Kitematerial. Wir haben die meisten Marken verfügbar und sorgen dafür, dass du immer mit der richtigen Kite-Größe auf dem Wasser bist. Keine Lust, all dein Material mitzunehmen? Kein Problem!"
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree"
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Check your insurance. If you need help with the right insurance please ask us for help! Not all insurance companies cover kitesurf material.",
                    du: "Je zorgt ervoor dat je je correcte niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt afronden, kun je het openstaande bedrag op verschillende manieren bij ons vereffenen. - Je kunt het openstaande bedrag in onze webshop gebruiken voor (kite)materiaal - Je kunt het gebruiken voor andere KiteActive-reizen of het als cadeaukaart aan vrienden geven - Je kunt je pakket afronden in 1 van onze scholen in Nederland. Controleer je verzekering. Als je hulp nodig hebt bij de juiste verzekering, vraag het ons dan! Niet alle verzekeringsmaatschappijen dekken kitesurfmateriaal.",
                    gr: "Du stellst sicher, dass du während deiner Buchung dein korrektes Niveau angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den offenen Betrag auf verschiedene Weise bei uns begleichen. - Du kannst den offenen Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden - Du kannst ihn für andere KiteActive-Reisen verwenden oder als Geschenkkarte an Freunde geben - Du kannst dein Paket in einer unserer Schulen in Holland abschließen. Überprüfe deine Versicherung. Wenn du Hilfe bei der richtigen Versicherung benötigst, frag uns um Hilfe! Nicht alle Versicherungsunternehmen decken Kitesurfmaterial ab."
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je op de veiligste manier kitesurfen leert, moet je altijd op jezelf en anderen letten. Luister altijd goed naar de instructies van ons team.",
                    gr: "Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport, und obwohl wir sicherstellen, dass du bei uns auf die sicherste Weise Kitesurfen lernst, musst du dir immer selbst und anderen bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }

        },
        accommodation: {
            main_title: {
                en: "Kite In & Out",
                gr: "Kite Ein & Aus",
                du: "Kite In & Uit"
            },
            main_desc: {
                en: "During this unforgettable kitesurf adventure, you will sleep in comfortable pousadas along the coast. Sufficient lounge areas and lovely rooms to relax after a full day of kiting! We recommend everyone to acclimatize in our kitehouse in Cumbuco 3 days before the departure of the downwinder Brazil!",
                gr: "Während dieses unvergesslichen Kitesurf-Abenteuers wirst du in komfortablen Pousadas entlang der Küste übernachten. Ausreichend Lounge-Bereiche und schöne Zimmer, um sich nach einem ganzen Tag Kiten zu entspannen! Wir empfehlen jedem, sich 3 Tage vor der Abfahrt des Downwinders in unserem Kitehouse in Cumbuco zu akklimatisieren!",
                du: "Tijdens dit onvergetelijke kitesurfavontuur verblijf je in comfortabele pousada's langs de kust. Voldoende loungegebieden en prachtige kamers om te ontspannen na een hele dag kiten! We raden iedereen aan om 3 dagen voor vertrek van de downwinder in ons kitehuis in Cumbuco te acclimatiseren!"
            },
            card_details: [
                {
                    imgSrc: brazil_hotel1,
                    title: {
                        en: "STANDARD SHARED ROOM",
                        gr: "STANDARD GETEILTES ZIMMER",
                        du: "STANDAARD GEDEELDE KAMER"
                    },
                    description: {
                        en: "Share your room with max 2 other kitesurfers. We always try to separate male and female rooms.",
                        gr: "Teilen Sie Ihr Zimmer mit maximal 2 anderen Kitesurfern. Wir versuchen immer, männliche und weibliche Zimmer zu trennen.",
                        du: "Deel je kamer met maximaal 2 andere kitesurfers. We proberen altijd mannelijke en vrouwelijke kamers te scheiden."
                    }
                },
                {
                    imgSrc: brazil_hotel2,
                    title: {
                        en: "SINGLE PRIVATE ROOM",
                        gr: "EINZELPRIVATZIMMER",
                        du: "EENPERSOONS PRIVEKAMER"
                    },
                    description: {
                        en: "In need for more privacy? Book a private room for an additional fee.",
                        gr: "Brauchst du mehr Privatsphäre? Buche ein privates Zimmer gegen eine zusätzliche Gebühr.",
                        du: "Behoefte aan meer privacy? Boek een privékamer tegen een toeslag."
                    }
                },
                {
                    imgSrc: brazil_hotel3,
                    title: {
                        en: "DOUBLE PRIVATE ROOM",
                        gr: "DOPPELPRIVATZIMMER",
                        du: "TWEEPERSOONS PRIVÉKAMER"
                    },
                    description: {
                        en: "Perfect for couples.",
                        gr: "Perfekt für Paare.",
                        du: "Perfect voor koppels."
                    }
                },
                {
                    imgSrc: brazil_hotel4,
                    title: {
                        en: "Kite inn >> Kite out",
                        gr: "Kite ein >> Kite aus",
                        du: "Kite in >> Kite uit"
                    },
                    description: {
                        en: "As we have been coming to Brazil since 2009, we know the best places to stay. We try to arrange great pousadas directly at the spot, so that you can park your kite in front of the entrance!",
                        gr: "Da wir seit 2009 nach Brasilien kommen, kennen wir die besten Unterkünfte. Wir versuchen, großartige Pousadas direkt am Spot zu arrangieren, damit du deinen Kite vor dem Eingang parken kannst!",
                        du: "Omdat we sinds 2009 naar Brazilië komen, kennen we de beste plekken om te verblijven. We proberen geweldige pousada's direct op de locatie te regelen, zodat je je kite voor de ingang kunt parkeren!"
                    }
                },
                {
                    imgSrc: brazil_hotel5,
                    title: {
                        en: "Good vibes",
                        gr: "Gute Stimmung",
                        du: "Goede sfeer"
                    },
                    description: {
                        en: "In the evenings we will organize some campfires and beach parties",
                        gr: "Am Abend organisieren wir Lagerfeuer und Strandpartys.",
                        du: "’s Avonds organiseren we kampvuren en strandfeesten."
                    }
                },
                {
                    imgSrc: brazil_hotel6,
                    title: {
                        en: "Stunning views",
                        gr: "Atemberaubende Ausblicke",
                        du: "Adembenemende uitzichten"
                    },
                    description: {
                        en: "From your room you will have the best view on the beach. Sometimes we are in the middle of nowhere :)",
                        gr: "Von deinem Zimmer aus hast du den besten Blick auf den Strand. Manchmal sind wir mitten im Nirgendwo :)",
                        du: "Vanuit je kamer heb je het beste uitzicht op het strand. Soms zijn we letterlijk in the middle of nowhere :)"
                    }
                }
            ],
            location_title: {
                en: "How to get here - Fortaleza",
                gr: "Wie man hierher kommt - Fortaleza",
                du: "Hoe kom je hier - Fortaleza"
            },
            location_subtitle: {
                en: "The Caribbean of Africa",
                gr: "Die Karibik Afrikas",
                du: "De Caraïben van Afrika"
            },
            location_desc: {
                en: "There are good flights from Amsterdam to Fortaleza, which will take you there in approximately 12 hours. There are 2 good connections; KLM (via Paris or Rio) and TAP (via Lissabon).",
                gr: "Es gibt gute Flüge von Amsterdam nach Fortaleza, die etwa 12 Stunden dauern. Es gibt 2 gute Verbindungen; KLM (über Paris oder Rio) und TAP (über Lissabon).",
                du: "Er zijn goede vluchten van Amsterdam naar Fortaleza, die ongeveer 12 uur duren. Er zijn 2 goede verbindingen; KLM (via Parijs of Rio) en TAP (via Lissabon)."
            },

            iframe : "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13023.844754398688!2d-38.7215728!3d-3.6302715!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c0cbf4a405555d%3A0xa721927c28beb60e!2sKite%20soul%20Pousada%20hotel%20Cumbuco!5e1!3m2!1sen!2sin!4v1729944314464!5m2!1sen!2sin"

            
        },
        activities: [
            {
                img: zanzibar_activity_img1,
                title: {
                    en: 'Awesome Evenings',
                    du: 'Geweldige Avonden',
                    gr: 'Tolle Abende'
                }
            },
            {
                img: zanzibar_activity_img2,
                title: {
                    en: 'Wave Surfing',
                    du: 'Golfsurfen',
                    gr: 'Wellenreiten'
                }
            },
            {
                img: zanzibar_activity_img3,
                title: {
                    en: 'Down winders',
                    du: 'Downwinders',
                    gr: 'Downwinders'
                }
            },
            {
                img: zanzibar_activity_img4,
                title: {
                    en: 'Climbing',
                    du: 'Klimmen',
                    gr: 'Klettern'
                }
            },
            {
                img: zanzibar_activity_img5,
                title: {
                    en: 'Funbox - beach Games',
                    du: 'Funbox - strandspellen',
                    gr: 'Funbox - Strandspiele'
                }
            }
        ],
        reviews: {}
    },




    // Sail&kite  for example st_tour/tv-tas-downwinder  

    {
        name: 'Sail&kite',
        vedio: Sailkite_vedio,
        backcover: sailkitecover,
        alltabs: {
            highlight: [
                {
                    text: {
                        en: "1 of the most unique downwinders in the world",
                        gr: "Einer der einzigartigsten Downwinder der Welt",
                        du: "Een van de meest unieke downwinders ter wereld"
                    }
                },
                {
                    text: {
                        en: "Kitesurf between seals, sandbanks & dolphins",
                        gr: "Kitesurfen zwischen Robben, Sandbänken und Delfinen",
                        du: "Kitesurfen tussen zeehonden, zandbanken en dolfijnen"
                    }
                },
                {
                    text: {
                        en: "A real adventure for the advanced kiters",
                        gr: "Ein echtes Abenteuer für die fortgeschrittenen Kiter",
                        du: "Een echt avontuur voor de gevorderde kiters"
                    }
                },
                {
                    text: {
                        en: "Explore Holland!",
                        gr: "Erkunde Holland!",
                        du: "Verken Nederland!"
                    }
                },
            ],
            question: [
                {
                    title: {
                        en: "Can I go with any TV-TAS downwinder anyway?",
                        gr: "Kann ich trotzdem mit einem beliebigen TV-TAS Downwinder mitfahren?",
                        du: "Kan ik toch met elke TV-TAS downwinder meegaan?"
                    },
                    para: {
                        en: "Unfortunately not, we work with the system that gives priority to the person who registered first and has the correct level. If you have been along, you will come back again and in this way we want to give everyone the opportunity to make this unique tour.",
                        gr: "Leider nicht, wir arbeiten mit einem System, das der Person Vorrang gibt, die sich zuerst angemeldet hat und das richtige Niveau hat. Wenn Sie dabei waren, kommen Sie wieder und so wollen wir jedem die Möglichkeit geben, diese einzigartige Tour zu machen.",
                        du: "Helaas niet, we werken met een systeem dat prioriteit geeft aan de persoon die zich als eerste heeft aangemeld en het juiste niveau heeft. Als je er al bij bent geweest, kom je weer terug en op deze manier willen we iedereen de kans geven om deze unieke tour te maken.",
                    }
                },
                {
                    title: {
                        en: "I have a high starting number, do I have a chance?",
                        gr: "Ich habe eine hohe Startnummer, habe ich eine Chance?",
                        du: "Ik heb een hoog startnummer, heb ik kans?"
                    },
                    para: {
                        en: "To be sure, a lot of people always cancel at the last minute and then places are moved on. Keep an eye on your phone/email when things get exciting!",
                        gr: "Um sicher zu sein, sagen viele Leute immer in letzter Minute ab, und dann werden Plätze frei. Halte dein Telefon/E-Mail im Auge, wenn es spannend wird!",
                        du: "Om zeker te zijn, zeggen veel mensen altijd op het laatste moment af en dan komen er plaatsen vrij. Houd je telefoon/e-mail in de gaten wanneer het spannend wordt!",
                    }
                },
                {
                    title: {
                        en: "When will it be announced whether the downwinder will go ahead?",
                        gr: "Wann wird bekannt gegeben, ob der Downwinder stattfinden kann?",
                        du: "Wanneer wordt aangekondigd of de downwinder doorgaat?"
                    },
                    para: {
                        en: "The first announcement will be made on Tuesday with code orange and on Thursday 12:00 it will be decided whether the downwinder can continue due to the predictions.",
                        gr: "Die erste Ankündigung erfolgt am Dienstag mit Code Orange, und am Donnerstag um 12:00 Uhr wird entschieden, ob der Downwinder aufgrund der Vorhersagen stattfinden kann.",
                        du: "De eerste aankondiging wordt dinsdag gedaan met code oranje en donderdag om 12:00 uur wordt besloten of de downwinder door kan gaan op basis van de voorspellingen.",
                    }
                },
                {
                    title: {
                        en: "What if I drop out halfway through the tour?",
                        gr: "Was ist, wenn ich mitten in der Tour aussteige?",
                        du: "Wat als ik halverwege de tour uitstap?"
                    },
                    para: {
                        en: "Then you have to make your own way back to your car in Den Helder by public transport",
                        gr: "Dann müssen Sie mit öffentlichen Verkehrsmitteln selbst zu Ihrem Auto in Den Helder zurückfahren.",
                        du: "Dan moet je zelf met het openbaar vervoer terug naar je auto in Den Helder."
                    }
                },
                {
                    title: {
                        en: "What kind of accommodation do we sleep in?",
                        gr: "In welcher Art von Unterkunft schlafen wir?",
                        du: "In wat voor soort accommodatie slapen we?"
                    },
                    para: {
                        en: "This differs every time because we always have to decide last minute whether it can go ahead or not, it just depends on where there is room. We usually ensure that we stay in a luxurious group accommodation that is fully equipped.",
                        gr: "Das variiert jedes Mal, da wir immer in letzter Minute entscheiden müssen, ob es stattfinden kann oder nicht, es hängt einfach davon ab, wo Platz ist. Wir sorgen normalerweise dafür, dass wir in einer luxuriösen Gruppenunterkunft übernachten, die komplett ausgestattet ist.",
                        du: "Dit verschilt elke keer omdat we altijd op het laatste moment moeten beslissen of het door kan gaan of niet, het hangt gewoon af van waar er ruimte is. We zorgen er meestal voor dat we in een luxe groepsaccommodatie verblijven die volledig is uitgerust."
                    }
                },
                {
                    title: {
                        en: "I'm high on the list but I can't make it during the weekend the trip takes place.",
                        gr: "Ich stehe hoch auf der Liste, aber ich kann am Wochenende, an dem die Reise stattfindet, nicht.",
                        du: "Ik sta hoog op de lijst, maar ik kan niet komen tijdens het weekend waarop de reis plaatsvindt."
                    },
                    para: {
                        en: "Very unfortunate! You may cancel once due to private circumstances and then unfortunately you will be at the back again. We need quite specific conditions for this trip so we also need your commitment 🙂",
                        gr: "Sehr bedauerlich! Sie können einmal aufgrund privater Umstände absagen und dann sind Sie leider wieder am Ende der Liste. Für diese Reise benötigen wir recht spezifische Bedingungen, daher benötigen wir auch Ihr Engagement 🙂",
                        du: "Heel jammer! Je mag eenmaal annuleren vanwege privé-omstandigheden en dan sta je helaas weer achteraan. We hebben vrij specifieke voorwaarden nodig voor deze reis, dus we hebben ook jouw inzet nodig 🙂"
                    }
                }
            ]
        },

        overview: {
            trip_heading: {
                en: "Den Helder > Texel > Vlieland > Terschelling > Ameland > Schiermonnikoog",
                gr: "Den Helder > Texel > Vlieland > Terschelling > Ameland > Schiermonnikoog",
                du: "Den Helder > Texel > Vlieland > Terschelling > Ameland > Schiermonnikoog"
            },
            trip_para: {
                en: "This year, KiteActive is once again organizing one of the most unique TV TAS downwinder experiences in the world in our own country! This year, KiteActive will again organize DEN TV-TAS downwinder. Don’t you know what DEN TV TAS means? It is a handy mnemonic for the names of all Dutch Wadden islands. The kiteboarding downwinder starts in Den Helder. On the north side of Texel, we make an island crossing towards Vlieland, and after lunch, we continue to Terschelling. Depending on the wind, we will spend the night here and continue our adventure towards Ameland and Schiermonnikoog the next day. This is one of the most thrilling kitesurf trips you can embark on this year!",
                gr: "In diesem Jahr organisiert KiteActive erneut eines der einzigartigsten TV-TAS Downwinder-Erlebnisse der Welt in unserem eigenen Land! In diesem Jahr wird KiteActive wieder DEN TV-TAS Downwinder organisieren. Weißt du nicht, was DEN TV TAS bedeutet? Es ist ein praktisches Eselsbrücken für die Namen aller niederländischen Watteninseln. Der Kitesurf-Downwinder beginnt in Den Helder. An der Nordseite von Texel machen wir eine Inselüberquerung in Richtung Vlieland, und nach dem Mittagessen geht es weiter nach Terschelling. Je nach Wind verbringen wir die Nacht hier und setzen unser Abenteuer am nächsten Tag in Richtung Ameland und Schiermonnikoog fort. Dies ist einer der aufregendsten Kitesurf-Ausflüge, die du in diesem Jahr machen kannst!",
                du: "Dit jaar organiseert KiteActive opnieuw een van de meest unieke TV TAS downwinder ervaringen ter wereld in ons eigen land! Dit jaar organiseert KiteActive weer de DEN TV-TAS downwinder. Weet je niet wat DEN TV TAS betekent? Het is een handig geheugensteuntje voor de namen van alle Nederlandse Waddeneilanden. De kitesurf downwinder begint in Den Helder. Aan de noordkant van Texel maken we een eilandoversteek naar Vlieland, en na de lunch gaan we verder naar Terschelling. Afhankelijk van de wind zullen we hier de nacht doorbrengen en de volgende dag ons avontuur voortzetten naar Ameland en Schiermonnikoog. Dit is een van de spannendste kitesurfreizen die je dit jaar kunt maken!"
            },
            img1: surf_img13,
            img1_head: {
                en: "Surf skill",
                gr: "Surf-Fähigkeit",
                du: "Surfvaardigheid"
            },
            img1_para: {
                en: "Level 4 - Advanced! The group size is a maximum of 25 people.",
                gr: "Stufe 4 - Fortgeschritten! Die Gruppengröße beträgt maximal 25 Personen.",
                du: "Niveau 4 - Gevorderd! De groepsgrootte is maximaal 25 personen."
            },
            img2: surf_img10,
            img2_head: {
                en: "Join community",
                gr: "Treten Sie der Gemeinschaft bei",
                du: "Word lid van de gemeenschap"
            },
            img2_para: {
                en: "Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "Treten Sie dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / KA-Team zu chatten.",
                du: "Sluit je aan bij de groepschat om op de hoogte te blijven of te chatten met andere reizigers / KA-team."
            },
            img3: surf_img3,
            img3_head: {
                en: "Be ready.. Always!",
                gr: "Sei bereit... Immer!",
                du: "Wees altijd klaar!"
            },
            img3_para: {
                en: "The wind window is from April 15 to the end of October. CAUTION: From this year onwards we will also take the tour during the week Offer.",
                gr: "Das Windfenster reicht vom 15. April bis Ende Oktober. VORSICHT: Ab diesem Jahr werden wir die Tour auch während des Wochenangebots durchführen.",
                du: "Het windvenster is van 15 april tot het einde van oktober. LET OP: Vanaf dit jaar zullen we de tour ook tijdens het weekaanbod maken."
            },
            img4: surf_img6,
            img4_head: {
                en: "Wind & Weather",
                gr: "Wind & Wetter",
                du: "Wind & Weer"
            },
            img4_para: {
                en: "The weekend continues from wind force 4-6 west to northwest (first announcement 4 days before departure social and final start announced a 2 days before departure via email)",
                gr: "Das Wochenende geht weiter von Windstärke 4-6 west bis nordwest (erste Ankündigung 4 Tage vor Abfahrt sozial und endgültiger Start 2 Tage vor Abfahrt per E-Mail angekündigt)",
                du: "Het weekend gaat door van windkracht 4-6 west tot noordwest (eerste aankondiging 4 dagen voor vertrek sociaal en definitieve start 2 dagen voor vertrek per e-mail aangekondigd)"
            },
            gall_img1: vanlife_gall_img1,
            gall_img2: vanlife_gall_img2,
            gall_img3: vanlife_gall_img3,
            gall_img4: vanlife_gall_img4,
        },

        packages: {
            levelsData: [
                {
                    levelName: 'Level 3 - Independent',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk aangemoedigd en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Learn new tricks and make progression',
                                du: 'Leer nieuwe trucs en maak vooruitgang',
                                gr: 'Lerne neue Tricks und mache Fortschritte'
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: 'Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste spron naar een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!',
                                gr: 'Dies ist das ultimative Paket für jeden, der bereits kitesurfen kann, aber Fortschritte machen und neue Tricks lernen möchte. Von deinem allerersten Sprung bis zum Kiteloop-Handle-Pass. Unser Team ist bereit, dir mit den richtigen Tipps und Tricks zu helfen, um deine Ziele zu erreichen!'
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je van deze cursus verwachten?',
                                gr: 'Was kannst du von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: 'Tijdens de cursus bekijken we wat je persoonlijke doelen zijn en wat haalbaar is. We gaan samen de theorie door en proberen je gedurende de week zoveel mogelijk te stimuleren, coachen en motiveren om nieuwe dingen op een veilige manier te proberen.',
                                gr: 'Während des Kurses werden wir uns ansehen, was deine persönlichen Ziele sind und was machbar ist. Wir gehen gemeinsam die Theorie durch und versuchen während der Woche, dich so gut wie möglich zu pushen, zu coachen und zu motivieren, um neue Dinge auf sichere Weise auszuprobieren.'
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'The basics of pop',
                                du: 'De basis van pop',
                                gr: 'Die Grundlagen des Pops'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitecarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kitesurfkarriere benötigst!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first jump',
                                du: 'Je eerste spron',
                                gr: 'Dein erster Sprung'
                            },
                            info: {
                                en: 'You want to learn how to go skyhigh?',
                                du: 'Wil je leren hoe je de lucht in kunt gaan?',
                                gr: 'Möchtest du lernen, wie du in die Höhe springen kannst?'
                            }
                        },
                        {
                            title: {
                                en: 'Back & Front roll',
                                du: 'Back & Front roll',
                                gr: 'Back & Frontroll'
                            },
                            info: {
                                en: 'Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!',
                                du: 'Maak een salto in de lucht! Je denkt dat het moeilijk is, maar we leren je deze geweldige truc in 1 week!',
                                gr: 'Mach einen Salto in der Luft! Du denkst, es ist schwierig, aber wir werden dir diesen erstaunlichen Trick in 1 Woche beibringen!'
                            }
                        },
                        {
                            title: {
                                en: 'Board grabs, onefooters and board-offs',
                                du: 'Board grabs, onefooters en board-offs',
                                gr: 'Board Grabs, Onefooters und Board-offs'
                            },
                            info: {
                                en: 'Grab the board in front of the camera!',
                                du: 'Pak het board voor de camera!',
                                gr: 'Greife das Board vor der Kamera!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first kiteloop (downloop)',
                                du: 'Je eerste kiteloop (downloop)',
                                gr: 'Dein erster Kiteloop (Downloop)'
                            },
                            info: {
                                en: 'Fly like a rollercoaster',
                                du: 'Vlieg als een achtbaan',
                                gr: 'Flieg wie eine Achterbahn'
                            }
                        },
                        {
                            title: {
                                en: 'Your first downwinder',
                                du: 'Je eerste downwinder',
                                gr: 'Dein erster Downwinder'
                            },
                            info: {
                                en: 'With a downwinder you are doing a kite tour along the coast!',
                                du: 'Met een downwinder maak je een kitetour langs de kust!',
                                gr: 'Mit einem Downwinder machst du eine Kitetour entlang der Küste!'
                            }
                        },
                        {
                            title: {
                                en: 'Learn how to ride strapless / bottom turns / wave skills',
                                du: 'Leer hoe je strapless kunt rijden / bottom turns / golfvaardigheden',
                                gr: 'Lerne, wie man strapless fährt / Bottom Turns / Wellenfähigkeiten'
                            },
                            info: {
                                en: 'Upgrade your skills in the waves',
                                du: 'Verbeter je vaardigheden in de golven',
                                gr: 'Verbessere deine Fähigkeiten in den Wellen'
                            }
                        },
                        {
                            title: {
                                en: 'Get unhooked!',
                                du: 'Ga los!',
                                gr: 'Lass los!'
                            },
                            info: {
                                en: 'Learn how to do unhooked tricks like Raily, S-bend or maybe even a handle pass?',
                                du: 'Leer hoe je unhooked trucs doet zoals Raily, S-bend of misschien zelfs een handle pass?',
                                gr: 'Lerne, wie man unhooked Tricks wie Raily, S-bend oder vielleicht sogar einen Handle Pass macht?'
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.',
                                du: 'We linken geen exacte uren aan dit pakket. We proberen iedereen zoveel mogelijk te stimuleren en bieden af en toe 1-op-1 begeleiding. Het doel is om zoveel mogelijk vooruitgang te boeken en tijdens deze cursus geven we je alle tools die je nodig hebt om te blijven oefenen.',
                                gr: 'Wir verlinken keine genauen Stunden mit diesem Paket. Wir versuchen, jeden so gut wie möglich zu unterstützen und bieten gelegentlich 1-zu-1-Begleitung an. Ziel ist es, so viel Fortschritt wie möglich zu machen, und während dieses Kurses geben wir dir alle Werkzeuge, die du brauchst, um weiter zu üben.'
                            }
                        },
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: 'Is kitemateriaal inbegrepen bij dit pakket?',
                                gr: 'Ist Kitematerial in diesem Paket enthalten?'
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 399 / week',
                                du: 'Nee, je moet je eigen kitemateriaal meenemen of een compleet set huren voor € 399 / week',
                                gr: 'Nein, du musst dein eigenes Kitematerial mitbringen oder ein komplettes Set für 399 € / Woche mieten.'
                            }
                        },
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!',
                                du: 'Je bent niet verzekerd tegen schade via KiteActive. We raden je aan om je reisverzekering goed te controleren om te zien of deze ook extreme sporten dekt. Dit om teleurstellingen en gedoe op vakantie te voorkomen. We werken samen met Sportzeker en dan ben je altijd voor alles verzekerd!',
                                gr: 'Du bist nicht gegen Schäden durch KiteActive versichert. Wir empfehlen, deine Reiseversicherung sorgfältig zu prüfen, um festzustellen, ob sie auch Extremsportarten abdeckt. Um Enttäuschungen und Probleme im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, und dann bist du immer für alles versichert!'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team",
                                du: "Professioneel team",
                                gr: "Professionelles Team"
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Get advice from travel expert AJ",
                                du: "Krijg advies van reisspecialist AJ",
                                gr: "Hol dir Ratschläge vom Reiseexperten AJ"
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: "Foto- en video bewijs / analyse",
                                gr: "Foto- und Video-Nachweis / Analyse"
                            }
                        }
                    ]
                },

                {
                    levelName: 'Level 4 - Advanced',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'Level up! There is still so much to learn',
                                du: 'Level omhoog! Er is nog zoveel te leren',
                                gr: 'Level hoch! Es gibt noch so viel zu lernen'
                            },
                            para: {
                                en: 'Do you want to improve in big air kiting? Going for that megaloop? Or do you really want to learn this sick handle pass? This course is also for waveriders who are able to do long downwinders in challenging conditions and who want to learn to perform better in the waves.',
                                du: 'Wil je verbeteren in big air kiten? Ga je voor die megaloop? Of wil je echt deze geweldige handle pass leren? Deze cursus is ook voor golfrijders die in staat zijn om lange downwinders in uitdagende omstandigheden te maken en die beter willen presteren in de golven.',
                                gr: 'Möchtest du im Big Air Kiten besser werden? Gehst du für den Megaloop? Oder möchtest du wirklich diesen coolen Handle Pass lernen? Dieser Kurs ist auch für Wellenreiter, die in der Lage sind, lange Downwinders unter herausfordernden Bedingungen zu machen und die lernen möchten, in den Wellen besser abzuschneiden.'
                            },
                            imgPosition: 'left'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Long downwinders in challenging conditions',
                                du: 'Lange downwinders in uitdagende omstandigheden',
                                gr: 'Lange Downwinders unter herausfordernden Bedingungen'
                            },
                            info: {
                                en: 'Like DEN TVTAS for example',
                                du: 'Zoals DEN TVTAS bijvoorbeeld',
                                gr: 'Wie DEN TVTAS zum Beispiel'
                            }
                        },
                        {
                            title: {
                                en: 'Jump 10 meter +',
                                du: 'Spring 10 meter +',
                                gr: 'Spring 10 Meter +'
                            },
                            info: {
                                en: 'Do you want to go skyhigh with us?',
                                du: 'Wil je met ons de lucht in?',
                                gr: 'Möchtest du mit uns in die Höhe springen?'
                            }
                        },
                        {
                            title: {
                                en: 'Megaloop',
                                du: 'Megaloop',
                                gr: 'Megaloop'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        },
                        {
                            title: {
                                en: 'Unhooked tricks',
                                du: 'Unhooked trucs',
                                gr: 'Unhooked Tricks'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'No, not with us. You need to take care of your own travel insurance. If you want, we can help you find the right one!',
                                du: 'Nee, niet bij ons. Je moet zelf zorgen voor je reisverzekering. Als je wilt, kunnen we je helpen de juiste te vinden!',
                                gr: 'Nein, nicht bei uns. Du musst dich um deine eigene Reiseversicherung kümmern. Wenn du möchtest, können wir dir helfen, die richtige zu finden!'
                            }
                        },
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'No specific hours are attached to this course. From time to time we do 1-on-1 sessions and our team will do our best to teach you as much as possible during this week.',
                                du: 'Er zijn geen specifieke uren aan deze cursus verbonden. Af en toe doen we 1-op-1 sessies en ons team zal ons best doen om je deze week zoveel mogelijk te leren.',
                                gr: 'Es sind keine spezifischen Stunden mit diesem Kurs verbunden. Von Zeit zu Zeit führen wir 1-zu-1-Sitzungen durch, und unser Team wird unser Bestes tun, um dir während dieser Woche so viel wie möglich beizubringen.'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Make progression in ideal conditions",
                                du: 'Maak vooruitgang onder ideale omstandigheden',
                                gr: 'Mach Fortschritte unter idealen Bedingungen'
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Advice from our travel expert AJ",
                                du: 'Advies van onze reisspecialist AJ',
                                gr: 'Rat von unserem Reiseexperten AJ'
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Headset coaching",
                                du: 'Coaching via headset',
                                gr: 'Coaching über Headset'
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: 'Foto- en video-analyse / bewijs',
                                gr: 'Foto- und Videoanalyse / Nachweis'
                            }
                        }
                    ]
                },
            ],


            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kitegear verhuur",
                    gr: "Kiteausrüstungsverleih"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en zorgen ervoor dat je altijd met de juiste maat kite op het water bent. Geen zin om al je materiaal mee te nemen? Geen probleem!",
                    gr: "Bei uns findest du das neueste Kitematerial. Wir haben die meisten Marken verfügbar und sorgen dafür, dass du immer mit der richtigen Kite-Größe auf dem Wasser bist. Keine Lust, all dein Material mitzunehmen? Kein Problem!"
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree"
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Check your insurance. If you need help with the right insurance please ask us for help! Not all insurance companies cover kitesurf material.",
                    du: "Je zorgt ervoor dat je je correcte niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt afronden, kun je het openstaande bedrag op verschillende manieren bij ons vereffenen. - Je kunt het openstaande bedrag in onze webshop gebruiken voor (kite)materiaal - Je kunt het gebruiken voor andere KiteActive-reizen of het als cadeaukaart aan vrienden geven - Je kunt je pakket afronden in 1 van onze scholen in Nederland. Controleer je verzekering. Als je hulp nodig hebt bij de juiste verzekering, vraag het ons dan! Niet alle verzekeringsmaatschappijen dekken kitesurfmateriaal.",
                    gr: "Du stellst sicher, dass du während deiner Buchung dein korrektes Niveau angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den offenen Betrag auf verschiedene Weise bei uns begleichen. - Du kannst den offenen Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden - Du kannst ihn für andere KiteActive-Reisen verwenden oder als Geschenkkarte an Freunde geben - Du kannst dein Paket in einer unserer Schulen in Holland abschließen. Überprüfe deine Versicherung. Wenn du Hilfe bei der richtigen Versicherung benötigst, frag uns um Hilfe! Nicht alle Versicherungsunternehmen decken Kitesurfmaterial ab."
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je op de veiligste manier kitesurfen leert, moet je altijd op jezelf en anderen letten. Luister altijd goed naar de instructies van ons team.",
                    gr: "Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport, und obwohl wir sicherstellen, dass du bei uns auf die sicherste Weise Kitesurfen lernst, musst du dir immer selbst und anderen bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }

        },

        kitespot: [
            {
                imgSrc: kitespotimg1,
                text: {
                    en: 'Dutch most unique downwinder',
                    du: 'De meest unieke downwinder van Nederland',
                    gr: 'Der einzigartigste Downwinder der Niederlande'
                },
                para: {
                    en: 'Den Helder, Texel, Vlieland, Terschelling, Ameland, and Schiermonnikoog… These are the islands we will visit during our unique kitesurf trips. We have planned a wind window from mid-April to the end of October. The start will take place early in the morning in Den Helder, where we will gather at the (free) parking lot. Ideally, we sail to Terschelling in one day, spend the night here, and continue to Schiermonnikoog on the second day. As soon as we arrive in Schiermonnikoog, we take the ferry back to the mainland where a tour bus will be waiting for us to take us back to our starting point in Den Helder. This trip is perfect for those looking for an extreme kitesurf trip or an exciting kite adventure. Our kitesurf downwinders offer a thrilling way to explore the beautiful Dutch coastline.',
                    du: 'Den Helder, Texel, Vlieland, Terschelling, Ameland en Schiermonnikoog... Dit zijn de eilanden die we zullen bezoeken tijdens onze unieke kitesurfreizen. We hebben een windvenster gepland van half april tot het einde van oktober. De start vindt vroeg in de ochtend plaats in Den Helder, waar we ons verzamelen op de (gratis) parkeerplaats. Idealiter zeilen we in één dag naar Terschelling, brengen we hier de nacht door en gaan we de tweede dag verder naar Schiermonnikoog. Zodra we in Schiermonnikoog aankomen, nemen we de ferry terug naar het vasteland waar een touringcar op ons wacht om ons terug te brengen naar ons vertrekpunt in Den Helder. Deze reis is perfect voor degenen die op zoek zijn naar een extreme kitesurfreis of een spannend kite-avontuur. Onze kitesurf downwinders bieden een spannende manier om de prachtige Nederlandse kust te verkennen.',
                    gr: 'Den Helder, Texel, Vlieland, Terschelling, Ameland und Schiermonnikoog... Das sind die Inseln, die wir während unserer einzigartigen Kitesurfreisen besuchen werden. Wir haben ein Windfenster von Mitte April bis Ende Oktober geplant. Der Start erfolgt früh am Morgen in Den Helder, wo wir uns auf dem (kostenlosen) Parkplatz versammeln werden. Idealerweise segeln wir an einem Tag nach Terschelling, verbringen die Nacht hier und setzen am zweiten Tag unsere Reise nach Schiermonnikoog fort. Sobald wir in Schiermonnikoog angekommen sind, nehmen wir die Fähre zurück zum Festland, wo ein Reisebus auf uns wartet, um uns zu unserem Ausgangspunkt in Den Helder zurückzubringen. Diese Reise ist perfekt für diejenigen, die nach einer extremen Kitesurfreise oder einem spannenden Kite-Abenteuer suchen. Unsere Kitesurf Downwinder bieten eine aufregende Möglichkeit, die wunderschöne niederländische Küste zu erkunden.'
                },
                imgPosition: 'right',
            },
        ]
    },




    // Sicily .........................

    {
        name: 'Sicily',
        vedio: Sicily_vedio,
        backcover: sicily,

        alltabs: {
            highlight: [
                {
                    text: {
                        en: "Een gigantische kitespot met alle ruimte",
                        gr: "Ein riesiger Kitespot mit viel Platz",
                        du: "Een gigantische kitespot met alle ruimte"
                    }
                },
                {
                    text: {
                        en: "Je kunt overal staan, perfect voor beginners!",
                        gr: "Du kannst überall stehen, perfekt für Anfänger!",
                        du: "Je kunt overal staan, perfect voor beginners!"
                    }
                },
                {
                    text: {
                        en: "Heerlijk Italiaans eten",
                        gr: "Köstliches italienisches Essen",
                        du: "Heerlijk Italiaans eten"
                    }
                },
                {
                    text: {
                        en: "Leuke feestjes",
                        gr: "Tolle Partys",
                        du: "Leuke feestjes"
                    }
                },
                {
                    text: {
                        en: "Mega gezellige groepen",
                        gr: "Super gesellige Gruppen",
                        du: "Mega gezellige groepen"
                    }
                },
                {
                    text: {
                        en: "Heerlijk hotel met zwembad",
                        gr: "Wundervolles Hotel mit Pool",
                        du: "Heerlijk hotel met zwembad"
                    }
                }
            ],
            question: [
                {
                    title: {
                        en: "How far is the accommodation from the kite spot?",
                        gr: "Wie weit ist die Unterkunft vom Kitespot entfernt?",
                        du: "Hoe ver is de accommodatie van de kitespot?"
                    },
                    para: {
                        en: "The kite spot is on your doorstep!",
                        gr: "Der Kitespot ist direkt vor deiner Haustür!",
                        du: "De kitespot ligt voor de deur!"
                    }
                },
                {
                    title: {
                        en: "Where is the best place to rent a car or scooter?",
                        gr: "Wo kann man am besten ein Auto oder einen Roller mieten?",
                        du: "Waar kun je het beste een auto of scooter huren?"
                    },
                    para: {
                        en: "We have good contacts with local scooter and car rental companies and you can book through us without any problems. Of course there are several car rentals from Palermo airport where you can also rent a car for €10-30 / day. A good website that we recommend for finding the cheapest car is Carjet.",
                        gr: "Wir haben gute Kontakte zu lokalen Roller- und Autovermietungen, und du kannst problemlos über uns buchen. Natürlich gibt es auch mehrere Autovermietungen am Flughafen Palermo, wo du ebenfalls ein Auto für 10-30 € pro Tag mieten kannst. Eine gute Website, die wir empfehlen, um das günstigste Auto zu finden, ist Carjet.",
                        du: "We hebben goede contacten met lokale scooter- en autoverhuurbedrijven, en je kunt zonder problemen via ons boeken. Natuurlijk zijn er verschillende autoverhuurbedrijven vanaf Palermo luchthaven waar je ook een auto kunt huren voor €10-30 / dag. Een goede website die we aanbevelen om de goedkoopste auto te vinden is Carjet."
                    }
                },
                {
                    title: {
                        en: "What is the wind like and what material should I take with me?",
                        gr: "Wie ist der Wind und welches Material sollte ich mitnehmen?",
                        du: "Hoe is de wind en welk materiaal moet ik meenemen?"
                    },
                    para: {
                        en: "On average the wind blows between 14 and 25 knots. So take all the kite sizes you have with you! Furthermore, a large board or a foil can sometimes come in handy in the mornings and a wave board is also recommended because there is also a beautiful wave spot!",
                        gr: "Im Durchschnitt weht der Wind zwischen 14 und 25 Knoten. Nimm also alle Kites mit, die du hast! Außerdem kann ein großes Board oder ein Foil am Morgen nützlich sein, und ein Waveboard wird ebenfalls empfohlen, da es auch einen schönen Wellenspot gibt!",
                        du: "Gemiddeld waait de wind tussen de 14 en 25 knopen. Neem dus alle maten kites mee die je hebt! Verder kan een groot board of een foil soms handig zijn in de ochtend en een waveboard wordt ook aanbevolen omdat er ook een prachtige golvenspot is!"
                    }
                },
                {
                    title: {
                        en: "How is the group?",
                        gr: "Wie ist die Gruppe?",
                        du: "Hoe is de groep?"
                    },
                    para: {
                        en: "Our groups can vary from young to old, and are often evenly distributed with both men and women. The group usually consists of ages between 20 and 45, with exceptions of course. We teach beginners and give tips to advanced kitesurfers, so you can expect every level from us. We also often offer group activities that you can participate in without obligation. On some trips you may share a large room with other KiteActive guests, but this will be indicated. You can often see in advance via our Facebook Events which people will be present on the trip.",
                        gr: "Unsere Gruppen können von jung bis alt variieren und sind oft gleichmäßig mit Männern und Frauen verteilt. Die Gruppe besteht normalerweise aus Menschen im Alter von 20 bis 45 Jahren, mit natürlich einigen Ausnahmen. Wir unterrichten Anfänger und geben fortgeschrittenen Kitesurfern Tipps, sodass du bei uns jedes Niveau erwarten kannst. Wir bieten auch oft Gruppenaktivitäten an, an denen du unverbindlich teilnehmen kannst. Bei einigen Reisen teilst du möglicherweise ein großes Zimmer mit anderen KiteActive-Gästen, aber dies wird vorher angegeben. Über unsere Facebook-Events kannst du oft im Voraus sehen, welche Leute bei der Reise dabei sein werden.",
                        du: "Onze groepen kunnen variëren van jong tot oud en zijn vaak gelijk verdeeld tussen mannen en vrouwen. De groep bestaat meestal uit mensen tussen de 20 en 45 jaar, met natuurlijk enkele uitzonderingen. We leren beginners en geven tips aan gevorderde kitesurfers, dus je kunt van ons elk niveau verwachten. We bieden ook vaak groepsactiviteiten aan waar je vrijblijvend aan kunt deelnemen. Bij sommige reizen deel je mogelijk een grote kamer met andere KiteActive-gasten, maar dit wordt aangegeven. Via onze Facebook-evenementen kun je vaak van tevoren zien welke mensen aanwezig zullen zijn op de reis."
                    }
                },
                {
                    title: {
                        en: "How do I fly?",
                        gr: "Wie fliege ich?",
                        du: "Hoe vlieg ik?"
                    },
                    para: {
                        en: "You fly into Preveza airport. There are several airlines that fly here, but Transavia is a particular favorite.",
                        gr: "Du fliegst zum Flughafen Preveza. Es gibt mehrere Fluggesellschaften, die dorthin fliegen, aber Transavia ist besonders beliebt.",
                        du: "Je vliegt naar de luchthaven van Preveza. Er zijn verschillende luchtvaartmaatschappijen die hierheen vliegen, maar Transavia is een favoriet."
                    }
                },
                {
                    title: {
                        en: "How long is the flight? And from Airport to the Kitevilla?",
                        gr: "Wie lange dauert der Flug? Und vom Flughafen zur Kitevilla?",
                        du: "Hoe lang is de vlucht? En van de luchthaven naar de Kitevilla?"
                    },
                    para: {
                        en: "It is about a 2,5 hour flight and from Amsterdam to Trapani or Palermo. From Trapani it is a 15 minute drive, and from Palermo 2 hour drive to the hotel.",
                        gr: "Es ist ein etwa 2,5-stündiger Flug von Amsterdam nach Trapani oder Palermo. Von Trapani sind es 15 Minuten Fahrt, und von Palermo sind es 2 Stunden Fahrt zum Hotel.",
                        du: "Het is ongeveer een 2,5 uur durende vlucht van Amsterdam naar Trapani of Palermo. Vanaf Trapani is het 15 minuten rijden en vanaf Palermo is het 2 uur rijden naar het hotel."
                    }
                },
                {
                    title: {
                        en: "How is the nightlife?",
                        gr: "Wie ist das Nachtleben?",
                        du: "Hoe is het nachtleven?"
                    },
                    para: {
                        en: "Great, you shouldn't compare it with Ibiza, but there is a great club in the capital and there are endless beach bars that organize great theme parties. So don't forget your trendy outfit!",
                        gr: "Großartig, du solltest es nicht mit Ibiza vergleichen, aber es gibt einen tollen Club in der Hauptstadt und endlose Strandbars, die großartige Themenpartys organisieren. Also vergiss nicht dein trendiges Outfit!",
                        du: "Geweldig, je moet het niet vergelijken met Ibiza, maar er is een geweldige club in de hoofdstad en er zijn eindeloze strandbars die geweldige themaparty's organiseren. Dus vergeet je trendy outfit niet!"
                    }
                },
                {
                    title: {
                        en: "I can't during this period",
                        gr: "Ich kann in diesem Zeitraum nicht",
                        du: "Ik kan niet tijdens deze periode"
                    },
                    para: {
                        en: "Shame!! Unfortunately, we only offer this event during this period. Fortunately, we have many other cool ones outside this period kitesurfing events and keep our Facebook Stay tuned to stay informed when new dates for Italy come online.",
                        gr: "Schade! Leider bieten wir diese Veranstaltung nur in diesem Zeitraum an. Glücklicherweise haben wir viele andere coole Kitesurf-Veranstaltungen außerhalb dieses Zeitraums, und auf unserer Facebook-Seite bleibst du informiert, wenn neue Termine für Italien online gehen.",
                        du: "Jammer! Helaas bieden we dit evenement alleen tijdens deze periode aan. Gelukkig hebben we veel andere coole kitesurfevenementen buiten deze periode, en blijf op de hoogte via onze Facebook-pagina om te weten wanneer nieuwe data voor Italië online komen."
                    }
                }
            ]
        },


        overview: {
            trip_heading: {
                en: "The Sicilian Kite Dream: Kitesurf Sicily Adventures",
                gr: "Der sizilianische Kite-Traum: Kitesurfen auf Sizilien",
                du: "De Siciliaanse Kite Droom: Kitesurf Sicilia Avonturen"
            },
            trip_para: {
                en: "A kitesurfing destination in Europe that has it all! Delicious food, nice people, and a world-class kite spot with... WIND! In short: The Sicilian kitesurfing dream. Travel with us to Marsala this summer for a unique kite camp in Sicily and one of our favorite destinations!",
                gr: "Ein Kitesurfziel in Europa, das alles hat! Köstliches Essen, nette Menschen und einen erstklassigen Kitespot mit... WIND! Kurz gesagt: Der sizilianische Kitesurf-Traum. Reisen Sie mit uns diesen Sommer nach Marsala für ein einzigartiges Kite-Camp auf Sizilien, einem unserer Lieblingsziele!",
                du: "Een kitesurfbestemming in Europa die alles heeft! Heerlijk eten, leuke mensen en een wereldklasse kitespot met... WIND! Kortom: De Siciliaanse kitesurf droom. Reis deze zomer met ons naar Marsala voor een uniek kitekamp op Sicilië, een van onze favoriete bestemmingen!"
            },
            img1: surf_img12,
            img1_head: {
                en: "Only stars!",
                gr: "Nur Sterne!",
                du: "Alleen sterren!"
            },
            img1_para: {
                en: "Read the reviews of our travellers about kite travel.",
                gr: "Lesen Sie die Bewertungen unserer Reisenden über Kite-Reisen.",
                du: "Lees de beoordelingen van onze reizigers over kite-reizen."
            },
            img2: surf_img10,
            img2_head: {
                en: "Community",
                gr: "Gemeinschaft",
                du: "Gemeenschap"
            },
            img2_para: {
                en: "7-12 kiters / week couples, solo, family, single Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "7-12 Kiter / Woche Paare, Alleinreisende, Familien. Treten Sie dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / KA-Team zu chatten.",
                du: "7-12 kitesurfers / week stellen stellen, solo, gezin, single. Sluit je aan bij de groepschat om op de hoogte te blijven of te chatten met andere reizigers / KA Team."
            },
            img3: surf_img4,
            img3_head: {
                en: "Surf skills",
                gr: "Surf-Fähigkeiten",
                du: "Surfvaardigheden"
            },
            img3_para: {
                en: "Level 1-3 / Beginner to Advanced Kite Lessons",
                gr: "Level 1-3 / Anfänger bis Fortgeschrittene Kitesurf-Kurse",
                du: "Niveau 1-3 / Beginners tot Gevorderde Kitesurflessen"
            },
            img4: surf_img13,
            img4_head: {
                en: "Save the date",
                gr: "Merken Sie sich das Datum",
                du: "Zet de datum"
            },
            img4_para: {
                en: "Check - in/out Friday / Saturday 04.10 - 11.10.2024",
                gr: "Check-in/out Freitag / Samstag 04.10 - 11.10.2024",
                du: "Inchecken / uitchecken Vrijdag / Zaterdag 04.10 - 11.10.2024"
            },
            gall_img1: Sicily_img1,
            gall_img2: Sicily_img2,
            gall_img3: Sicily_img3,
            gall_img4: Sicily_img4,
        },

        kitespot: [
            {
                imgSrc: sicily_kitespot_img1,
                text: {
                    en: 'flat water paradise for every level',
                    du: 'vlakke waterparadijs voor elk niveau',
                    gr: 'Flachwasserparadies für jedes Level'
                },

                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: 'Marsala, a great spot on the island of Sicily with high wind certainty and a prime location for kitesurfing in Sicily. The wonderful weather makes this a popular destination among water sports enthusiasts. The kite spot is located all the way in the west and is a huge flat water lagoon! Do you also like waves? Fortunately, there are also various wave spots and other top kitesurf Sicily spots nearby!',
                    du: 'Marsala, een geweldige plek op het eiland Sicilië met een hoge windzekerheid en een prime locatie voor kitesurfen in Sicilië. Het geweldige weer maakt dit een populaire bestemming onder watersportliefhebbers. De kitespot ligt helemaal in het westen en is een enorme vlakwaterlagune! Hou je ook van golven? Gelukkig zijn er ook verschillende golven plekken en andere top kitesurf spots in Sicilië in de buurt!',
                    gr: 'Marsala, ein großartiger Spot auf der Insel Sizilien mit hoher Windsicherheit und einer erstklassigen Lage zum Kitesurfen in Sizilien. Das wunderbare Wetter macht dies zu einem beliebten Ziel unter Wassersportbegeisterten. Der Kitespot liegt ganz im Westen und ist eine riesige Flachwasserlagune! Magst du auch Wellen? Glücklicherweise gibt es in der Nähe auch verschiedene Wellen-Spots und andere Top-Kitesurf-Sizilien-Spots!'
                },
                imgPosition: 'right',
            },
            {
                imgSrc: sicily_kitespot_img2,
                text: {
                    en: 'Kitesurf Sicily',
                    du: 'Kitesurf Sicilië',
                    gr: 'Kitesurf Sizilien'
                },
                heading: {
                    en: '',
                    du: '',
                    gr: ''
                },
                para: {
                    en: 'Kitesurfing in La Stagnone, Sicily, is as easy as possible for beginners and advanced kitesurfers alike. Whether you’re looking to learn kitesurfing or perfect your tricks, you can practice confidently knowing that if you lose your board, you can simply get up, walk back, and retrieve it. For those with an average skill level who might not yet stay upwind, the shallow waters allow you to walk upwind whenever you drift too far from where you started. This way, you can practice kitesurfing in Sicily much more effectively without wasting time.',
                    du: 'Kitesurfen in La Stagnone, Sicilië, is zo gemakkelijk mogelijk voor zowel beginners als gevorderde kitesurfers. Of je nu wilt leren kitesurfen of je tricks wilt perfectioneren, je kunt met vertrouwen oefenen, wetende dat als je je board verliest, je gewoon kunt opstaan, terug kunt lopen en het kunt ophalen. Voor degenen met een gemiddeld vaardigheidsniveau die mogelijk nog niet op de wind kunnen blijven, maken de ondiepe wateren het mogelijk om tegen de wind in te lopen wanneer je te ver van waar je begon drijft. Op deze manier kun je veel effectiever kitesurfen in Sicilië oefenen zonder tijd te verspillen.',
                    gr: 'Kitesurfen in La Stagnone, Sizilien, ist für Anfänger und fortgeschrittene Kitesurfer so einfach wie möglich. Egal, ob du Kitesurfen lernen oder deine Tricks perfektionieren möchtest, du kannst selbstbewusst üben, da du weißt, dass du, wenn du dein Board verlierst, einfach aufstehen, zurücklaufen und es zurückholen kannst. Für diejenigen mit einem durchschnittlichen Fähigkeitsniveau, die möglicherweise noch nicht gegen den Wind bleiben können, erlauben die flachen Gewässer, dass du immer gegen den Wind laufen kannst, wenn du zu weit von deinem Startpunkt abdriftest. Auf diese Weise kannst du in Sizilien viel effektiver kitesurfen, ohne Zeit zu verschwenden.'
                },
                imgPosition: 'left',
            },
        ],

        packages: {
            levelsData: [
                {
                    levelName: 'Level 3 - Independent',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk aangemoedigd en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Learn new tricks and make progression',
                                du: 'Leer nieuwe trucs en maak vooruitgang',
                                gr: 'Lerne neue Tricks und mache Fortschritte'
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: 'Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste spron naar een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!',
                                gr: 'Dies ist das ultimative Paket für jeden, der bereits kitesurfen kann, aber Fortschritte machen und neue Tricks lernen möchte. Von deinem allerersten Sprung bis zum Kiteloop-Handle-Pass. Unser Team ist bereit, dir mit den richtigen Tipps und Tricks zu helfen, um deine Ziele zu erreichen!'
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je van deze cursus verwachten?',
                                gr: 'Was kannst du von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: 'Tijdens de cursus bekijken we wat je persoonlijke doelen zijn en wat haalbaar is. We gaan samen de theorie door en proberen je gedurende de week zoveel mogelijk te stimuleren, coachen en motiveren om nieuwe dingen op een veilige manier te proberen.',
                                gr: 'Während des Kurses werden wir uns ansehen, was deine persönlichen Ziele sind und was machbar ist. Wir gehen gemeinsam die Theorie durch und versuchen während der Woche, dich so gut wie möglich zu pushen, zu coachen und zu motivieren, um neue Dinge auf sichere Weise auszuprobieren.'
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'The basics of pop',
                                du: 'De basis van pop',
                                gr: 'Die Grundlagen des Pops'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitecarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kitesurfkarriere benötigst!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first jump',
                                du: 'Je eerste spron',
                                gr: 'Dein erster Sprung'
                            },
                            info: {
                                en: 'You want to learn how to go skyhigh?',
                                du: 'Wil je leren hoe je de lucht in kunt gaan?',
                                gr: 'Möchtest du lernen, wie du in die Höhe springen kannst?'
                            }
                        },
                        {
                            title: {
                                en: 'Back & Front roll',
                                du: 'Back & Front roll',
                                gr: 'Back & Frontroll'
                            },
                            info: {
                                en: 'Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!',
                                du: 'Maak een salto in de lucht! Je denkt dat het moeilijk is, maar we leren je deze geweldige truc in 1 week!',
                                gr: 'Mach einen Salto in der Luft! Du denkst, es ist schwierig, aber wir werden dir diesen erstaunlichen Trick in 1 Woche beibringen!'
                            }
                        },
                        {
                            title: {
                                en: 'Board grabs, onefooters and board-offs',
                                du: 'Board grabs, onefooters en board-offs',
                                gr: 'Board Grabs, Onefooters und Board-offs'
                            },
                            info: {
                                en: 'Grab the board in front of the camera!',
                                du: 'Pak het board voor de camera!',
                                gr: 'Greife das Board vor der Kamera!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first kiteloop (downloop)',
                                du: 'Je eerste kiteloop (downloop)',
                                gr: 'Dein erster Kiteloop (Downloop)'
                            },
                            info: {
                                en: 'Fly like a rollercoaster',
                                du: 'Vlieg als een achtbaan',
                                gr: 'Flieg wie eine Achterbahn'
                            }
                        },
                        {
                            title: {
                                en: 'Your first downwinder',
                                du: 'Je eerste downwinder',
                                gr: 'Dein erster Downwinder'
                            },
                            info: {
                                en: 'With a downwinder you are doing a kite tour along the coast!',
                                du: 'Met een downwinder maak je een kitetour langs de kust!',
                                gr: 'Mit einem Downwinder machst du eine Kitetour entlang der Küste!'
                            }
                        },
                        {
                            title: {
                                en: 'Learn how to ride strapless / bottom turns / wave skills',
                                du: 'Leer hoe je strapless kunt rijden / bottom turns / golfvaardigheden',
                                gr: 'Lerne, wie man strapless fährt / Bottom Turns / Wellenfähigkeiten'
                            },
                            info: {
                                en: 'Upgrade your skills in the waves',
                                du: 'Verbeter je vaardigheden in de golven',
                                gr: 'Verbessere deine Fähigkeiten in den Wellen'
                            }
                        },
                        {
                            title: {
                                en: 'Get unhooked!',
                                du: 'Ga los!',
                                gr: 'Lass los!'
                            },
                            info: {
                                en: 'Learn how to do unhooked tricks like Raily, S-bend or maybe even a handle pass?',
                                du: 'Leer hoe je unhooked trucs doet zoals Raily, S-bend of misschien zelfs een handle pass?',
                                gr: 'Lerne, wie man unhooked Tricks wie Raily, S-bend oder vielleicht sogar einen Handle Pass macht?'
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.',
                                du: 'We linken geen exacte uren aan dit pakket. We proberen iedereen zoveel mogelijk te stimuleren en bieden af en toe 1-op-1 begeleiding. Het doel is om zoveel mogelijk vooruitgang te boeken en tijdens deze cursus geven we je alle tools die je nodig hebt om te blijven oefenen.',
                                gr: 'Wir verlinken keine genauen Stunden mit diesem Paket. Wir versuchen, jeden so gut wie möglich zu unterstützen und bieten gelegentlich 1-zu-1-Begleitung an. Ziel ist es, so viel Fortschritt wie möglich zu machen, und während dieses Kurses geben wir dir alle Werkzeuge, die du brauchst, um weiter zu üben.'
                            }
                        },
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: 'Is kitemateriaal inbegrepen bij dit pakket?',
                                gr: 'Ist Kitematerial in diesem Paket enthalten?'
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 399 / week',
                                du: 'Nee, je moet je eigen kitemateriaal meenemen of een compleet set huren voor € 399 / week',
                                gr: 'Nein, du musst dein eigenes Kitematerial mitbringen oder ein komplettes Set für 399 € / Woche mieten.'
                            }
                        },
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!',
                                du: 'Je bent niet verzekerd tegen schade via KiteActive. We raden je aan om je reisverzekering goed te controleren om te zien of deze ook extreme sporten dekt. Dit om teleurstellingen en gedoe op vakantie te voorkomen. We werken samen met Sportzeker en dan ben je altijd voor alles verzekerd!',
                                gr: 'Du bist nicht gegen Schäden durch KiteActive versichert. Wir empfehlen, deine Reiseversicherung sorgfältig zu prüfen, um festzustellen, ob sie auch Extremsportarten abdeckt. Um Enttäuschungen und Probleme im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, und dann bist du immer für alles versichert!'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team",
                                du: "Professioneel team",
                                gr: "Professionelles Team"
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Get advice from travel expert AJ",
                                du: "Krijg advies van reisspecialist AJ",
                                gr: "Hol dir Ratschläge vom Reiseexperten AJ"
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: "Foto- en video bewijs / analyse",
                                gr: "Foto- und Video-Nachweis / Analyse"
                            }
                        }
                    ]
                },

                {
                    levelName: 'Level 4 - Advanced',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'Level up! There is still so much to learn',
                                du: 'Level omhoog! Er is nog zoveel te leren',
                                gr: 'Level hoch! Es gibt noch so viel zu lernen'
                            },
                            para: {
                                en: 'Do you want to improve in big air kiting? Going for that megaloop? Or do you really want to learn this sick handle pass? This course is also for waveriders who are able to do long downwinders in challenging conditions and who want to learn to perform better in the waves.',
                                du: 'Wil je verbeteren in big air kiten? Ga je voor die megaloop? Of wil je echt deze geweldige handle pass leren? Deze cursus is ook voor golfrijders die in staat zijn om lange downwinders in uitdagende omstandigheden te maken en die beter willen presteren in de golven.',
                                gr: 'Möchtest du im Big Air Kiten besser werden? Gehst du für den Megaloop? Oder möchtest du wirklich diesen coolen Handle Pass lernen? Dieser Kurs ist auch für Wellenreiter, die in der Lage sind, lange Downwinders unter herausfordernden Bedingungen zu machen und die lernen möchten, in den Wellen besser abzuschneiden.'
                            },
                            imgPosition: 'left'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Long downwinders in challenging conditions',
                                du: 'Lange downwinders in uitdagende omstandigheden',
                                gr: 'Lange Downwinders unter herausfordernden Bedingungen'
                            },
                            info: {
                                en: 'Like DEN TVTAS for example',
                                du: 'Zoals DEN TVTAS bijvoorbeeld',
                                gr: 'Wie DEN TVTAS zum Beispiel'
                            }
                        },
                        {
                            title: {
                                en: 'Jump 10 meter +',
                                du: 'Spring 10 meter +',
                                gr: 'Spring 10 Meter +'
                            },
                            info: {
                                en: 'Do you want to go skyhigh with us?',
                                du: 'Wil je met ons de lucht in?',
                                gr: 'Möchtest du mit uns in die Höhe springen?'
                            }
                        },
                        {
                            title: {
                                en: 'Megaloop',
                                du: 'Megaloop',
                                gr: 'Megaloop'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        },
                        {
                            title: {
                                en: 'Unhooked tricks',
                                du: 'Unhooked trucs',
                                gr: 'Unhooked Tricks'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'No, not with us. You need to take care of your own travel insurance. If you want, we can help you find the right one!',
                                du: 'Nee, niet bij ons. Je moet zelf zorgen voor je reisverzekering. Als je wilt, kunnen we je helpen de juiste te vinden!',
                                gr: 'Nein, nicht bei uns. Du musst dich um deine eigene Reiseversicherung kümmern. Wenn du möchtest, können wir dir helfen, die richtige zu finden!'
                            }
                        },
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'No specific hours are attached to this course. From time to time we do 1-on-1 sessions and our team will do our best to teach you as much as possible during this week.',
                                du: 'Er zijn geen specifieke uren aan deze cursus verbonden. Af en toe doen we 1-op-1 sessies en ons team zal ons best doen om je deze week zoveel mogelijk te leren.',
                                gr: 'Es sind keine spezifischen Stunden mit diesem Kurs verbunden. Von Zeit zu Zeit führen wir 1-zu-1-Sitzungen durch, und unser Team wird unser Bestes tun, um dir während dieser Woche so viel wie möglich beizubringen.'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Make progression in ideal conditions",
                                du: 'Maak vooruitgang onder ideale omstandigheden',
                                gr: 'Mach Fortschritte unter idealen Bedingungen'
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Advice from our travel expert AJ",
                                du: 'Advies van onze reisspecialist AJ',
                                gr: 'Rat von unserem Reiseexperten AJ'
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Headset coaching",
                                du: 'Coaching via headset',
                                gr: 'Coaching über Headset'
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: 'Foto- en video-analyse / bewijs',
                                gr: 'Foto- und Videoanalyse / Nachweis'
                            }
                        }
                    ]
                },
            ],


            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kitegear verhuur",
                    gr: "Kiteausrüstungsverleih"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en zorgen ervoor dat je altijd met de juiste maat kite op het water bent. Geen zin om al je materiaal mee te nemen? Geen probleem!",
                    gr: "Bei uns findest du das neueste Kitematerial. Wir haben die meisten Marken verfügbar und sorgen dafür, dass du immer mit der richtigen Kite-Größe auf dem Wasser bist. Keine Lust, all dein Material mitzunehmen? Kein Problem!"
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree"
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Check your insurance. If you need help with the right insurance please ask us for help! Not all insurance companies cover kitesurf material.",
                    du: "Je zorgt ervoor dat je je correcte niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt afronden, kun je het openstaande bedrag op verschillende manieren bij ons vereffenen. - Je kunt het openstaande bedrag in onze webshop gebruiken voor (kite)materiaal - Je kunt het gebruiken voor andere KiteActive-reizen of het als cadeaukaart aan vrienden geven - Je kunt je pakket afronden in 1 van onze scholen in Nederland. Controleer je verzekering. Als je hulp nodig hebt bij de juiste verzekering, vraag het ons dan! Niet alle verzekeringsmaatschappijen dekken kitesurfmateriaal.",
                    gr: "Du stellst sicher, dass du während deiner Buchung dein korrektes Niveau angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den offenen Betrag auf verschiedene Weise bei uns begleichen. - Du kannst den offenen Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden - Du kannst ihn für andere KiteActive-Reisen verwenden oder als Geschenkkarte an Freunde geben - Du kannst dein Paket in einer unserer Schulen in Holland abschließen. Überprüfe deine Versicherung. Wenn du Hilfe bei der richtigen Versicherung benötigst, frag uns um Hilfe! Nicht alle Versicherungsunternehmen decken Kitesurfmaterial ab."
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je op de veiligste manier kitesurfen leert, moet je altijd op jezelf en anderen letten. Luister altijd goed naar de instructies van ons team.",
                    gr: "Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport, und obwohl wir sicherstellen, dass du bei uns auf die sicherste Weise Kitesurfen lernst, musst du dir immer selbst und anderen bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }

        },

        hosted: [
            {
                hosted_img: Sicily_hosted_img1,
                hosted_name: {
                    en: "Annemaj Muiser",
                    gr: "Annemaj Muiser",
                    du: "Annemaj Muiser"
                },
                hosted_position: {
                    en: "Photographer & Instructor",
                    gr: "Fotograf & Instruktor",
                    du: "Fotograaf & Instructeur"
                },
                kite_exp: {
                    en: "6 years kite experience",
                    gr: "6 Jahre Kiterfahrung",
                    du: "6 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "I love shooting pictures and teaching other people our amazing sport. I will make sure you have the perfect kite picture at the end of the week :)",
                    gr: "Ich liebe es, Bilder zu machen und anderen Menschen unseren großartigen Sport beizubringen. Ich werde dafür sorgen, dass du am Ende der Woche das perfekte Kite-Bild hast :)",
                    du: "Ik hou ervan om foto's te maken en andere mensen onze geweldige sport te leren. Ik zorg ervoor dat je aan het einde van de week de perfecte kitesurffoto hebt :)"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "Zanzibar",
                    gr: "Zanzibar",
                    du: "Zanzibar"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "6 years",
                    gr: "6 Jahre",
                    du: "6 jaar"
                }
            },
        ],

        accommodation: {
            main_title: {
                en: "Feel at Home in our Kiteactive Hotel",
                gr: "Fühlen Sie sich wie zu Hause in unserem Kiteactive Hotel",
                du: "Voel je thuis in ons Kiteactive Hotel"
            },
            main_desc: {
                en: "In our kite camp you can choose different room types. You can choose from a room with a beautiful view of the large garden. But also with a sea view! The hotel has an epic swimming pool and lots of relaxing areas",
                gr: "In unserem Kitecamp können Sie verschiedene Zimmertypen wählen. Sie können zwischen einem Zimmer mit schönem Blick auf den großen Garten oder auch mit Meerblick wählen! Das Hotel verfügt über einen epischen Pool und viele Entspannungsbereiche.",
                du: "In ons kitekamp kun je verschillende kamertypes kiezen. Je kunt kiezen voor een kamer met een prachtig uitzicht op de grote tuin. Maar ook met zeezicht! Het hotel heeft een episch zwembad en veel ontspanningsruimtes."
            },
            card_details: [
                {
                    imgSrc: Sicily_hotel1,
                    title: {
                        en: "SHARED ROOM",
                        gr: "GETEILTES ZIMMER",
                        du: "GEDEELDE KAMER"
                    },
                    description: {
                        en: "Share your room with max. 3 other kiters! We try not to mix male/female. Standard option!",
                        gr: "Teilen Sie Ihr Zimmer mit maximal 3 anderen Kitern! Wir versuchen, Männer und Frauen nicht zu mischen. Standardoption!",
                        du: "Deel je kamer met maximaal 3 andere kiters! We proberen man/vrouw niet te mengen. Standaardoptie!"
                    }
                },
                {
                    imgSrc: Sicily_hotel2,
                    title: {
                        en: "PRIVATE ROOM",
                        gr: "PRIVATZIMMER",
                        du: "PRIVÉKAMER"
                    },
                    description: {
                        en: "Private room for single use. If you want more privacy it will cost you €25/night extra",
                        gr: "Privatzimmer zur Einzelnutzung. Wenn Sie mehr Privatsphäre wünschen, kostet es €25/Nacht extra.",
                        du: "Privékamer voor enkel gebruik. Als je meer privacy wilt, kost het je €25/nacht extra."
                    }
                },
                {
                    imgSrc: Sicily_hotel3,
                    title: {
                        en: "DOUBLE ROOM FOR COUPLES",
                        gr: "DOPPELZIMMER FÜR PAARE",
                        du: "TWEEPERSOONSKAMER VOOR STELLEN"
                    },
                    description: {
                        en: "Private double room for couples with an ensuite bathroom. Additional charge of €20/night.",
                        gr: "Privates Doppelzimmer für Paare mit eigenem Badezimmer. Aufpreis von €20/Nacht.",
                        du: "Privé tweepersoonskamer voor stellen met een eigen badkamer. Toeslag van €20/nacht."
                    }
                },
                {
                    imgSrc: Sicily_hotel4,
                    title: {
                        en: "LARGE POOL",
                        gr: "GROSSER POOL",
                        du: "GROOT ZWEMBAD"
                    },
                    description: {
                        en: "The golden hour with ice-cold drinks and fun with plenty of lounge areas where you can retreat.",
                        gr: "Die goldene Stunde mit eiskalten Getränken und Spaß mit vielen Lounge-Bereichen, in die Sie sich zurückziehen können.",
                        du: "Het gouden uur met ijskoude drankjes en plezier in de vele lounge-ruimtes waar je je kunt terugtrekken."
                    }
                },
                {
                    imgSrc: Sicily_hotel5,
                    title: {
                        en: "AT THE KITE SPOT",
                        gr: "AM KITE SPOT",
                        du: "BIJ DE KITELOCATIE"
                    },
                    description: {
                        en: "A+ location on the beach. Free storage and your kite will be neatly cleaned up every day by our kite boys!",
                        gr: "A+ Lage am Strand. Kostenloser Stauraum und Ihr Kite wird jeden Tag ordentlich von unseren Kite-Boys aufgeräumt!",
                        du: "A+ locatie op het strand. Gratis opslag en je kite wordt elke dag netjes opgeruimd door onze kitejongens!"
                    }
                },
                {
                    imgSrc: Sicily_hotel6,
                    title: {
                        en: "CLEAN ROOMS",
                        gr: "SAUBERE ZIMMER",
                        du: "SCHONE KAMERS"
                    },
                    description: {
                        en: "The location is perfect, in the center of everything you will find the bustling KiteActive guesthouse",
                        gr: "Die Lage ist perfekt, im Zentrum von allem finden Sie das pulsierende KiteActive-Gästehaus.",
                        du: "De locatie is perfect, in het centrum van alles vind je het bruisende KiteActive pension."
                    }
                }
            ],
            location_title: {
                en: "How to get here",
                gr: "Wie man hierher kommt",
                du: "Hoe kom je hier"
            },
            location_subtitle: {
                en: "Marsala – Birgi",
                gr: "Marsala – Birgi",
                du: "Marsala – Birgi"
            },
            location_desc: {
                en: "You fly with Ryanair from Dusseldorf (weeze) to Trapani. From here it is still approx. 10 minutes drive to the hotel!",
                gr: "Sie fliegen mit Ryanair von Düsseldorf (Weeze) nach Trapani. Von hier sind es noch ca. 10 Minuten Fahrt bis zum Hotel!",
                du: "Je vliegt met Ryanair van Düsseldorf (Weeze) naar Trapani. Vanaf hier is het nog ongeveer 10 minuten rijden naar het hotel!"
            },
            iframe : "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10298.975014497346!2d12.47138!3d37.8899681!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x131bddc59b077e19%3A0x4b30956c48812015!2sResort%20Santa%20Maria!5e1!3m2!1sen!2sin!4v1729944942438!5m2!1sen!2sin"
           
        },



        activities: [
            {
                img: zanzibar_activity_img1,
                title: {
                    en: 'Awesome Evenings',
                    du: 'Geweldige Avonden',
                    gr: 'Tolle Abende'
                }
            },
            {
                img: zanzibar_activity_img2,
                title: {
                    en: 'Wave Surfing',
                    du: 'Golfsurfen',
                    gr: 'Wellenreiten'
                }
            },
            {
                img: zanzibar_activity_img3,
                title: {
                    en: 'Down winders',
                    du: 'Downwinders',
                    gr: 'Downwinders'
                }
            },
            {
                img: zanzibar_activity_img4,
                title: {
                    en: 'Climbing',
                    du: 'Klimmen',
                    gr: 'Klettern'
                }
            },
            {
                img: zanzibar_activity_img5,
                title: {
                    en: 'Funbox - beach Games',
                    du: 'Funbox - strandspellen',
                    gr: 'Funbox - Strandspiele'
                }
            }
        ],

        reviews: {}

    },


    // Dhakla Trip data ................................

    {
        name: 'Dakhla',
        vedio: Zanzibar_vedio,
        backcover: zanzibarcover,
        overview: {
            trip_heading: {
                en: "Experience Nature's Beauty and Adventure",
                gr: "Erlebe die Schönheit der Natur und Abenteuer",
                du: "Ervaar de schoonheid van de natuur en avontuur"
            },
            trip_para: {
                en: "Discover the enchanting beauty of Dhakla, a hidden gem nestled in the heart of India. Known for its stunning landscapes and vibrant culture, Dhakla is rapidly becoming one of the top destinations for kite surfing enthusiasts. With pristine beaches, consistent winds, and warm waters, it's the perfect playground for both beginners and seasoned kite surfers.",
                gr: "Entdecke die bezaubernde Schönheit von Dakhla, einem versteckten Juwel im Herzen Indiens. Bekannt für seine atemberaubenden Landschaften und lebendige Kultur, wird Dakhla schnell zu einem der besten Ziele für Kitesurf-Enthusiasten. Mit unberührten Stränden, konstanten Winden und warmem Wasser ist es der perfekte Spielplatz für sowohl Anfänger als auch erfahrene Kitesurfer.",
                du: "Ontdek de betoverende schoonheid van Dakhla, een verborgen parel in het hart van India. Bekend om zijn prachtige landschappen en levendige cultuur, wordt Dakhla snel een van de topbestemmingen voor kitesurfenthousiastelingen. Met ongerepte stranden, constante winden en warm water is het de perfecte speelplaats voor zowel beginners als ervaren kitesurfers."
            },
            img1: surf_img5,
            img1_head: {
                en: "Dates 2025",
                gr: "Daten 2025",
                du: "Data 2025"
            },
            img1_para: {
                en: "19 - 29 September",
                gr: "19 - 29 September",
                du: "19 - 29 September"
            },
            img2: surf_img9,
            img2_head: {
                en: "Skills",
                gr: "Fähigkeiten",
                du: "Vaardigheden"
            },
            img2_para: {
                en: "Every skill is welcome.. from beginners to advanced",
                gr: "Jede Fähigkeit ist willkommen.. von Anfängern bis Fortgeschrittenen",
                du: "Iedere vaardigheid is welkom.. van beginners tot gevorderden"
            },
            img3: surf_img1,
            img3_head: {
                en: "Join KiteActive",
                gr: "Werde Mitglied bei KiteActive",
                du: "Word lid van KiteActive"
            },
            img3_para: {
                en: "Good vibes only. Join the groupchat to stay informed or chat with other travellers / KA Team.",
                gr: "Nur gute Vibes. Trete dem Gruppenchat bei, um informiert zu bleiben oder mit anderen Reisenden / KA-Team zu chatten.",
                du: "Alleen goede vibes. Doe mee in de groepschat om op de hoogte te blijven of te chatten met andere reizigers / KA-team."
            },
            img4: surf_img10,
            img4_head: {
                en: "Choose your style",
                gr: "Wähle deinen Stil",
                du: "Kies je stijl"
            },
            img4_para: {
                en: "Would you like to join, but you don't have a camper? No worries, you can rent a camper with us or join with your 'normal' car.",
                gr: "Möchtest du teilnehmen, aber hast keinen Camper? Kein Problem, du kannst bei uns einen Camper mieten oder mit deinem 'normalen' Auto teilnehmen.",
                du: "Wil je meedoen, maar heb je geen camper? Geen probleem, je kunt bij ons een camper huren of meedoen met je 'gewone' auto."
            },
            gall_img1: vanlife_gall_img1,
            gall_img2: vanlife_gall_img2,
            gall_img3: vanlife_gall_img3,
            gall_img4: vanlife_gall_img4,
        },

        packages: {
            levelsData: [
                {
                    levelName: 'Level 3 - Independent',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk aangemoedigd en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab1_1,
                            text: {
                                en: 'Learn new tricks and make progression',
                                du: 'Leer nieuwe trucs en maak vooruitgang',
                                gr: 'Lerne neue Tricks und mache Fortschritte'
                            },
                            para: {
                                en: 'This is the ultimate package for anyone who can already kitesurf but wants to make progress and learn new tricks. From your very first jump to a kiteloop handle pass. Our team is ready to help you with the right tips and tricks to achieve your goals!',
                                du: 'Dit is het ultieme pakket voor iedereen die al kan kitesurfen maar vooruitgang wil boeken en nieuwe trucs wil leren. Van je allereerste spron naar een kiteloop handle pass. Ons team staat klaar om je te helpen met de juiste tips en trucs om je doelen te bereiken!',
                                gr: 'Dies ist das ultimative Paket für jeden, der bereits kitesurfen kann, aber Fortschritte machen und neue Tricks lernen möchte. Von deinem allerersten Sprung bis zum Kiteloop-Handle-Pass. Unser Team ist bereit, dir mit den richtigen Tipps und Tricks zu helfen, um deine Ziele zu erreichen!'
                            },
                            imgPosition: 'left'
                        },
                        {
                            imgSrc: tab1_2,
                            text: {
                                en: 'What to expect from this course?',
                                du: 'Wat kun je van deze cursus verwachten?',
                                gr: 'Was kannst du von diesem Kurs erwarten?'
                            },
                            para: {
                                en: 'During the course we will look at what your personal goals are and what is feasible. We go through the theory together and during the week we try to push, coach and motivate you as much as possible to try new things in a safe way.',
                                du: 'Tijdens de cursus bekijken we wat je persoonlijke doelen zijn en wat haalbaar is. We gaan samen de theorie door en proberen je gedurende de week zoveel mogelijk te stimuleren, coachen en motiveren om nieuwe dingen op een veilige manier te proberen.',
                                gr: 'Während des Kurses werden wir uns ansehen, was deine persönlichen Ziele sind und was machbar ist. Wir gehen gemeinsam die Theorie durch und versuchen während der Woche, dich so gut wie möglich zu pushen, zu coachen und zu motivieren, um neue Dinge auf sichere Weise auszuprobieren.'
                            },
                            imgPosition: 'right'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'The basics of pop',
                                du: 'De basis van pop',
                                gr: 'Die Grundlagen des Pops'
                            },
                            info: {
                                en: 'Learn how to pop. This is a very important skill which you need for your entire kite career!',
                                du: 'Leer hoe je moet poppen. Dit is een zeer belangrijke vaardigheid die je nodig hebt voor je hele kitecarrière!',
                                gr: 'Lerne, wie man poppt. Dies ist eine sehr wichtige Fähigkeit, die du für deine gesamte Kitesurfkarriere benötigst!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first jump',
                                du: 'Je eerste spron',
                                gr: 'Dein erster Sprung'
                            },
                            info: {
                                en: 'You want to learn how to go skyhigh?',
                                du: 'Wil je leren hoe je de lucht in kunt gaan?',
                                gr: 'Möchtest du lernen, wie du in die Höhe springen kannst?'
                            }
                        },
                        {
                            title: {
                                en: 'Back & Front roll',
                                du: 'Back & Front roll',
                                gr: 'Back & Frontroll'
                            },
                            info: {
                                en: 'Make a salto in the air! You think it is difficult, but we will learn you this amazing trick in 1 week!',
                                du: 'Maak een salto in de lucht! Je denkt dat het moeilijk is, maar we leren je deze geweldige truc in 1 week!',
                                gr: 'Mach einen Salto in der Luft! Du denkst, es ist schwierig, aber wir werden dir diesen erstaunlichen Trick in 1 Woche beibringen!'
                            }
                        },
                        {
                            title: {
                                en: 'Board grabs, onefooters and board-offs',
                                du: 'Board grabs, onefooters en board-offs',
                                gr: 'Board Grabs, Onefooters und Board-offs'
                            },
                            info: {
                                en: 'Grab the board in front of the camera!',
                                du: 'Pak het board voor de camera!',
                                gr: 'Greife das Board vor der Kamera!'
                            }
                        },
                        {
                            title: {
                                en: 'Your first kiteloop (downloop)',
                                du: 'Je eerste kiteloop (downloop)',
                                gr: 'Dein erster Kiteloop (Downloop)'
                            },
                            info: {
                                en: 'Fly like a rollercoaster',
                                du: 'Vlieg als een achtbaan',
                                gr: 'Flieg wie eine Achterbahn'
                            }
                        },
                        {
                            title: {
                                en: 'Your first downwinder',
                                du: 'Je eerste downwinder',
                                gr: 'Dein erster Downwinder'
                            },
                            info: {
                                en: 'With a downwinder you are doing a kite tour along the coast!',
                                du: 'Met een downwinder maak je een kitetour langs de kust!',
                                gr: 'Mit einem Downwinder machst du eine Kitetour entlang der Küste!'
                            }
                        },
                        {
                            title: {
                                en: 'Learn how to ride strapless / bottom turns / wave skills',
                                du: 'Leer hoe je strapless kunt rijden / bottom turns / golfvaardigheden',
                                gr: 'Lerne, wie man strapless fährt / Bottom Turns / Wellenfähigkeiten'
                            },
                            info: {
                                en: 'Upgrade your skills in the waves',
                                du: 'Verbeter je vaardigheden in de golven',
                                gr: 'Verbessere deine Fähigkeiten in den Wellen'
                            }
                        },
                        {
                            title: {
                                en: 'Get unhooked!',
                                du: 'Ga los!',
                                gr: 'Lass los!'
                            },
                            info: {
                                en: 'Learn how to do unhooked tricks like Raily, S-bend or maybe even a handle pass?',
                                du: 'Leer hoe je unhooked trucs doet zoals Raily, S-bend of misschien zelfs een handle pass?',
                                gr: 'Lerne, wie man unhooked Tricks wie Raily, S-bend oder vielleicht sogar einen Handle Pass macht?'
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'We do not link exact hours to this package. We try to push everyone as much as possible and occasionally provide 1-on-1 guidance. The goal is to make as much progress as possible and during this course we will give you all the tools you need to continue practicing.',
                                du: 'We linken geen exacte uren aan dit pakket. We proberen iedereen zoveel mogelijk te stimuleren en bieden af en toe 1-op-1 begeleiding. Het doel is om zoveel mogelijk vooruitgang te boeken en tijdens deze cursus geven we je alle tools die je nodig hebt om te blijven oefenen.',
                                gr: 'Wir verlinken keine genauen Stunden mit diesem Paket. Wir versuchen, jeden so gut wie möglich zu unterstützen und bieten gelegentlich 1-zu-1-Begleitung an. Ziel ist es, so viel Fortschritt wie möglich zu machen, und während dieses Kurses geben wir dir alle Werkzeuge, die du brauchst, um weiter zu üben.'
                            }
                        },
                        {
                            title: {
                                en: 'Is kitegear included in this package?',
                                du: 'Is kitemateriaal inbegrepen bij dit pakket?',
                                gr: 'Ist Kitematerial in diesem Paket enthalten?'
                            },
                            info: {
                                en: 'No, you need to bring your own kitegear or rent a complete set at € 399 / week',
                                du: 'Nee, je moet je eigen kitemateriaal meenemen of een compleet set huren voor € 399 / week',
                                gr: 'Nein, du musst dein eigenes Kitematerial mitbringen oder ein komplettes Set für 399 € / Woche mieten.'
                            }
                        },
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'You are not insured against damage through KiteActive. We recommend that you check your travel insurance carefully to see whether it also covers extreme sports. to avoid disappointment and hassle on holiday. We work together with Sportzeker and then you are always covered for everything!',
                                du: 'Je bent niet verzekerd tegen schade via KiteActive. We raden je aan om je reisverzekering goed te controleren om te zien of deze ook extreme sporten dekt. Dit om teleurstellingen en gedoe op vakantie te voorkomen. We werken samen met Sportzeker en dan ben je altijd voor alles verzekerd!',
                                gr: 'Du bist nicht gegen Schäden durch KiteActive versichert. Wir empfehlen, deine Reiseversicherung sorgfältig zu prüfen, um festzustellen, ob sie auch Extremsportarten abdeckt. Um Enttäuschungen und Probleme im Urlaub zu vermeiden. Wir arbeiten mit Sportzeker zusammen, und dann bist du immer für alles versichert!'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Professional team",
                                du: "Professioneel team",
                                gr: "Professionelles Team"
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Headset coaching",
                                du: "Headset coaching",
                                gr: "Headset-Coaching"
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Get advice from travel expert AJ",
                                du: "Krijg advies van reisspecialist AJ",
                                gr: "Hol dir Ratschläge vom Reiseexperten AJ"
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: "Foto- en video bewijs / analyse",
                                gr: "Foto- und Video-Nachweis / Analyse"
                            }
                        }
                    ]
                },

                {
                    levelName: 'Level 4 - Advanced',
                    desc: {
                        en: 'If you are on the water with kiters of the same level, you always make more progression. You learn from each other and push yourself to learn new things! In this course you will be pushed and coached as much as possible to learn new tricks. With KiteActive you will spend a week in a perfect destination with people who also want to make progress. Hey ho, Let’s go! 🙂',
                        du: 'Als je op het water bent met kiters van hetzelfde niveau, maak je altijd meer vooruitgang. Je leert van elkaar en stimuleert jezelf om nieuwe dingen te leren! In deze cursus word je zoveel mogelijk gepusht en gecoacht om nieuwe trucs te leren. Met KiteActive breng je een week door op een perfecte bestemming met mensen die ook vooruitgang willen boeken. Hey ho, laten we gaan! 🙂',
                        gr: 'Wenn du mit Kitern desselben Niveaus auf dem Wasser bist, machst du immer mehr Fortschritte. Du lernst voneinander und drängst dich selbst, neue Dinge zu lernen! In diesem Kurs wirst du so viel wie möglich gepusht und gecoacht, um neue Tricks zu lernen. Mit KiteActive verbringst du eine Woche an einem perfekten Zielort mit Menschen, die ebenfalls Fortschritte machen wollen. Hey ho, lass uns gehen! 🙂'
                    },
                    imgsect: [
                        {
                            imgSrc: tab2_1,
                            text: {
                                en: 'Level up! There is still so much to learn',
                                du: 'Level omhoog! Er is nog zoveel te leren',
                                gr: 'Level hoch! Es gibt noch so viel zu lernen'
                            },
                            para: {
                                en: 'Do you want to improve in big air kiting? Going for that megaloop? Or do you really want to learn this sick handle pass? This course is also for waveriders who are able to do long downwinders in challenging conditions and who want to learn to perform better in the waves.',
                                du: 'Wil je verbeteren in big air kiten? Ga je voor die megaloop? Of wil je echt deze geweldige handle pass leren? Deze cursus is ook voor golfrijders die in staat zijn om lange downwinders in uitdagende omstandigheden te maken en die beter willen presteren in de golven.',
                                gr: 'Möchtest du im Big Air Kiten besser werden? Gehst du für den Megaloop? Oder möchtest du wirklich diesen coolen Handle Pass lernen? Dieser Kurs ist auch für Wellenreiter, die in der Lage sind, lange Downwinders unter herausfordernden Bedingungen zu machen und die lernen möchten, in den Wellen besser abzuschneiden.'
                            },
                            imgPosition: 'left'
                        }
                    ],
                    acordation_head: {
                        en: 'What do you learn?',
                        du: 'Wat leer je?',
                        gr: 'Was lernst du?'
                    },
                    acordation_data: [
                        {
                            title: {
                                en: 'Long downwinders in challenging conditions',
                                du: 'Lange downwinders in uitdagende omstandigheden',
                                gr: 'Lange Downwinders unter herausfordernden Bedingungen'
                            },
                            info: {
                                en: 'Like DEN TVTAS for example',
                                du: 'Zoals DEN TVTAS bijvoorbeeld',
                                gr: 'Wie DEN TVTAS zum Beispiel'
                            }
                        },
                        {
                            title: {
                                en: 'Jump 10 meter +',
                                du: 'Spring 10 meter +',
                                gr: 'Spring 10 Meter +'
                            },
                            info: {
                                en: 'Do you want to go skyhigh with us?',
                                du: 'Wil je met ons de lucht in?',
                                gr: 'Möchtest du mit uns in die Höhe springen?'
                            }
                        },
                        {
                            title: {
                                en: 'Megaloop',
                                du: 'Megaloop',
                                gr: 'Megaloop'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        },
                        {
                            title: {
                                en: 'Unhooked tricks',
                                du: 'Unhooked trucs',
                                gr: 'Unhooked Tricks'
                            },
                            info: {
                                en: '',
                                du: '',
                                gr: ''
                            }
                        }
                    ],
                    faq_head: {
                        en: "FAQ",
                        du: "FAQ",
                        gr: "Häufig gestellte Fragen"
                    },
                    faq_data: [
                        {
                            title: {
                                en: 'Am I insured?',
                                du: 'Ben ik verzekerd?',
                                gr: 'Bin ich versichert?'
                            },
                            info: {
                                en: 'No, not with us. You need to take care of your own travel insurance. If you want, we can help you find the right one!',
                                du: 'Nee, niet bij ons. Je moet zelf zorgen voor je reisverzekering. Als je wilt, kunnen we je helpen de juiste te vinden!',
                                gr: 'Nein, nicht bei uns. Du musst dich um deine eigene Reiseversicherung kümmern. Wenn du möchtest, können wir dir helfen, die richtige zu finden!'
                            }
                        },
                        {
                            title: {
                                en: 'How many hours is this course?',
                                du: 'Hoeveel uur duurt deze cursus?',
                                gr: 'Wie viele Stunden dauert dieser Kurs?'
                            },
                            info: {
                                en: 'No specific hours are attached to this course. From time to time we do 1-on-1 sessions and our team will do our best to teach you as much as possible during this week.',
                                du: 'Er zijn geen specifieke uren aan deze cursus verbonden. Af en toe doen we 1-op-1 sessies en ons team zal ons best doen om je deze week zoveel mogelijk te leren.',
                                gr: 'Es sind keine spezifischen Stunden mit diesem Kurs verbunden. Von Zeit zu Zeit führen wir 1-zu-1-Sitzungen durch, und unser Team wird unser Bestes tun, um dir während dieser Woche so viel wie möglich beizubringen.'
                            }
                        }
                    ],
                    logo_img: [
                        {
                            img: tab1_card1,
                            title: {
                                en: "Make progression in ideal conditions",
                                du: 'Maak vooruitgang onder ideale omstandigheden',
                                gr: 'Mach Fortschritte unter idealen Bedingungen'
                            }
                        },
                        {
                            img: tab1_card2,
                            title: {
                                en: "Advice from our travel expert AJ",
                                du: 'Advies van onze reisspecialist AJ',
                                gr: 'Rat von unserem Reiseexperten AJ'
                            }
                        },
                        {
                            img: tab1_card3,
                            title: {
                                en: "Headset coaching",
                                du: 'Coaching via headset',
                                gr: 'Coaching über Headset'
                            }
                        },
                        {
                            img: tab1_card4,
                            title: {
                                en: "Photo & Video proof / analysis",
                                du: 'Foto- en video-analyse / bewijs',
                                gr: 'Foto- und Videoanalyse / Nachweis'
                            }
                        }
                    ]
                },
            ],


            Kitegear_rental: {
                title: {
                    en: "Kitegear rental",
                    du: "Kitegear verhuur",
                    gr: "Kiteausrüstungsverleih"
                },
                para1: {
                    en: "With us you can find the latest kite material. We have most brands available and we ensure that you are always on the water with the right size kite. Don’t feel like carrying all your materials with you? No problem!",
                    du: "Bij ons vind je het nieuwste kitemateriaal. We hebben de meeste merken beschikbaar en zorgen ervoor dat je altijd met de juiste maat kite op het water bent. Geen zin om al je materiaal mee te nemen? Geen probleem!",
                    gr: "Bei uns findest du das neueste Kitematerial. Wir haben die meisten Marken verfügbar und sorgen dafür, dass du immer mit der richtigen Kite-Größe auf dem Wasser bist. Keine Lust, all dein Material mitzunehmen? Kein Problem!"
                },
                para2: {
                    en: "A list of our brands: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    du: "Een lijst van onze merken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree",
                    gr: "Eine Liste unserer Marken: Core / Naish / F-one / North / Slingshot / Lieuwe / Appletree"
                }
            },
            Disclaimer: {
                title: {
                    en: "Disclaimer",
                    du: "Disclaimer",
                    gr: "Haftungsausschluss"
                },
                para1: {
                    en: "You ensure that you indicate your correct level during your booking. Unfortunately, we cannot control the wind. If you cannot complete your package you can settle the outstanding amount in various ways with us.- You can use the outstanding amount in our webshop on (kite) gear – You can use it for other KiteActive trips or give it as a gift card to friends – You can finish your package in 1 of our schools in Holland. Check your insurance. If you need help with the right insurance please ask us for help! Not all insurance companies cover kitesurf material.",
                    du: "Je zorgt ervoor dat je je correcte niveau aangeeft tijdens je boeking. Helaas kunnen we de wind niet controleren. Als je je pakket niet kunt afronden, kun je het openstaande bedrag op verschillende manieren bij ons vereffenen. - Je kunt het openstaande bedrag in onze webshop gebruiken voor (kite)materiaal - Je kunt het gebruiken voor andere KiteActive-reizen of het als cadeaukaart aan vrienden geven - Je kunt je pakket afronden in 1 van onze scholen in Nederland. Controleer je verzekering. Als je hulp nodig hebt bij de juiste verzekering, vraag het ons dan! Niet alle verzekeringsmaatschappijen dekken kitesurfmateriaal.",
                    gr: "Du stellst sicher, dass du während deiner Buchung dein korrektes Niveau angibst. Leider können wir den Wind nicht kontrollieren. Wenn du dein Paket nicht abschließen kannst, kannst du den offenen Betrag auf verschiedene Weise bei uns begleichen. - Du kannst den offenen Betrag in unserem Webshop für (Kite-)Ausrüstung verwenden - Du kannst ihn für andere KiteActive-Reisen verwenden oder als Geschenkkarte an Freunde geben - Du kannst dein Paket in einer unserer Schulen in Holland abschließen. Überprüfe deine Versicherung. Wenn du Hilfe bei der richtigen Versicherung benötigst, frag uns um Hilfe! Nicht alle Versicherungsunternehmen decken Kitesurfmaterial ab."
                },
                para2: {
                    en: "Participation in our packages is always at your own risk. Kitesurfing is an extreme sport and although we will make sure you learn kitesurfing with us most safely, you always need to be aware of yourself and others. Always listen well to the instructions from our team.",
                    du: "Deelname aan onze pakketten is altijd op eigen risico. Kitesurfen is een extreme sport en hoewel we ervoor zorgen dat je op de veiligste manier kitesurfen leert, moet je altijd op jezelf en anderen letten. Luister altijd goed naar de instructies van ons team.",
                    gr: "Die Teilnahme an unseren Paketen erfolgt immer auf eigene Gefahr. Kitesurfen ist ein Extremsport, und obwohl wir sicherstellen, dass du bei uns auf die sicherste Weise Kitesurfen lernst, musst du dir immer selbst und anderen bewusst sein. Höre immer gut auf die Anweisungen unseres Teams."
                }
            }

        },

        hosted: [
            {
                hosted_img: hosted_img1,
                hosted_name: {
                    en: "Roderick Pijls",
                    gr: "Roderick Pijls",
                    du: "Roderick Pijls"
                },
                hosted_position: {
                    en: "Camp manager & Instructor",
                    gr: "Camp-Manager & Ausbilder",
                    du: "Campmanager & Instructeur"
                },
                kite_exp: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_para: {
                    en: "Having a background of professional kitesurfer for over 12 years has taught me a lot. Seeing many cultures, meeting great people and knowing everything from the best spots in the world has convinced me to start sharing this with everyone who has that little 'Columbus' inside him/ herself.",
                    gr: "Eine Hintergrund als professioneller Kitesurfer seit über 12 Jahren hat mir viel beigebracht. Viele Kulturen zu sehen, großartige Menschen zu treffen und alles über die besten Spots der Welt zu wissen, hat mich überzeugt, dies mit jedem zu teilen, der das kleine 'Kolumbus' in sich trägt.",
                    du: "Een achtergrond als professionele kitesurfer gedurende meer dan 12 jaar heeft me veel geleerd. Het zien van veel culturen, het ontmoeten van geweldige mensen en alles weten over de beste spots ter wereld heeft me ervan overtuigd dit te delen met iedereen die dat kleine 'Columbus'-gevoel in zich heeft."
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "",
                    gr: "",
                    du: ""
                }
            },
            {
                hosted_img: hosted_img3,
                hosted_name: {
                    en: "Bas ”cool”",
                    gr: "Bas „cool“",
                    du: "Bas 'cool'"
                },
                hosted_position: {
                    en: "Photographer & Instructor",
                    gr: "Fotograf & Instruktor",
                    du: "Fotograaf & Instructeur"
                },
                kite_exp: {
                    en: "8 years kite experience",
                    gr: "8 Jahre Kiterfahrung",
                    du: "8 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "I did my traineeship at Kiteactive when I was 16, and since then I have been part of the Kiteactive family!",
                    gr: "Ich habe mein Praktikum bei Kiteactive gemacht, als ich 16 war, und seitdem bin ich Teil der Kiteactive-Familie!",
                    du: "Ik heb mijn stage bij Kiteactive gedaan toen ik 16 was, en sindsdien ben ik onderdeel van de Kiteactive-familie!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favorite Destination",
                    gr: "Lieblingsziel",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "I like Sicily",
                    gr: "Ich mag Sizilien",
                    du: "Ik hou van Sicilië"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "7 years",
                    gr: "7 Jahre",
                    du: "7 jaar"
                }
            },
            {
                hosted_img: skycap_hosted_img1,
                hosted_name: {
                    en: "Jan Willem",
                    gr: "Jan Willem",
                    du: "Jan Willem"
                },
                hosted_position: {
                    en: "Kitecamp Host",
                    gr: "Kitecamp Gastgeber",
                    du: "Kitecamp Host"
                },
                kite_exp: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_para: {
                    en: "As a Gym teacher my job is to motivate people to do activities and sports. Kitesurfing is my biggest passion and I will teach you everything I know!",
                    gr: "Als Sportlehrer ist es mein Job, Menschen zu motivieren, Aktivitäten und Sport zu betreiben. Kitesurfen ist meine größte Leidenschaft und ich werde dir alles beibringen, was ich weiß!",
                    du: "Als gymdocent is het mijn taak om mensen te motiveren om activiteiten en sport te doen. Kitesurfen is mijn grootste passie en ik zal je alles leren wat ik weet!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Top Destination",
                    gr: "Top-Destination",
                    du: "Topbestemming"
                },
                hosted_msg: {
                    en: "",
                    gr: "",
                    du: ""
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "",
                    gr: "",
                    du: ""
                }
            },
            {
                hosted_img: skycap_hosted_img4,
                hosted_name: {
                    en: "Joeri ”the Chef”",
                    gr: "Joeri ”der Chef”",
                    du: "Joeri ”de Chef”"
                },
                hosted_position: {
                    en: "Chef",
                    gr: "Koch",
                    du: "Chef"
                },
                kite_exp: {
                    en: "4 years kite experience",
                    gr: "4 Jahre Kiterfahrung",
                    du: "4 jaar kitesurfervaring"
                },
                hosted_para: {
                    en: "In the summer I work in a foodtruck on festivals and in the winter I cruise around in my Van and try to kite as much as possible!",
                    gr: "Im Sommer arbeite ich in einem Foodtruck auf Festivals und im Winter fahre ich mit meinem Van herum und versuche, so viel wie möglich zu kiten!",
                    du: "In de zomer werk ik in een foodtruck op festivals en in de winter rijd ik rond in mijn bus en probeer ik zoveel mogelijk te kitesurfen!"
                },
                hosted_msg_img: hosted_earth,
                hosted_msg_head: {
                    en: "Favoriete Bestemming",
                    gr: "Top-Destination",
                    du: "Favoriete Bestemming"
                },
                hosted_msg: {
                    en: "Tarifa!",
                    gr: "Tarifa!",
                    du: "Tarifa!"
                },
                hosted_year_img: hosted_at,
                hosted_year_head: {
                    en: "KiteActive",
                    gr: "KiteActive",
                    du: "KiteActive"
                },
                hosted_year: {
                    en: "3 years",
                    gr: "3 Jahre",
                    du: "3 jaar"
                }
            }
        ],

        activities: [
            {
                img: zanzibar_activity_img1,
                title: {
                    en: 'Awesome Evenings',
                    du: 'Geweldige Avonden',
                    gr: 'Tolle Abende'
                }
            },
            {
                img: zanzibar_activity_img2,
                title: {
                    en: 'Wave Surfing',
                    du: 'Golfsurfen',
                    gr: 'Wellenreiten'
                }
            },
            {
                img: zanzibar_activity_img3,
                title: {
                    en: 'Down winders',
                    du: 'Downwinders',
                    gr: 'Downwinders'
                }
            },
            {
                img: zanzibar_activity_img4,
                title: {
                    en: 'Climbing',
                    du: 'Klimmen',
                    gr: 'Klettern'
                }
            },
            {
                img: zanzibar_activity_img5,
                title: {
                    en: 'Funbox - beach Games',
                    du: 'Funbox - strandspellen',
                    gr: 'Funbox - Strandspiele'
                }
            }
        ],

    },




];

export default trips