import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';

const Admin_VideoUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [videoLink, setVideoLink] = useState('');
  const [videoList, setVideoList] = useState([]); // To store all saved links
  const [isUploading, setIsUploading] = useState(false); // Track upload progress

  // Fetch all video links from the database
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api/videos/getvedio`);
        setVideoList(response.data); // Set the list of videos from the database
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  // Handle file input change
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a video to upload');
      return;
    }

    const formData = new FormData();
    formData.append('video', selectedFile);

    try {
      setIsUploading(true); // Start uploading

      const response = await axios.post(`${config.API_BASE_URL}/api/videos/upload-video`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setVideoLink(response.data.video.videoUrl); // Set the uploaded video link
      alert('Video uploaded successfully!');

      // Update the list of videos after upload
      setVideoList((prevList) => [...prevList, response.data.video]);

    } catch (error) {
      console.error('Error uploading video:', error);
      alert('Failed to upload video');
    } finally {
      setIsUploading(false); // End uploading
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="video-upload">
          <h2>Upload a Video</h2>
          <div className="row">
            <div className="col-6">
              <input type="file" className='form-control' accept="video/*" onChange={handleFileChange} />
            </div>
            <div className="col-6">
              <button onClick={handleUpload} className='btn btn-success' disabled={isUploading}>Upload Video</button>
            </div>
          </div>

          {isUploading && <p className='p-3 text-success-emphasis bg-success-subtle border border-success-subtle rounded-3'>Uploading...</p>} {/* Show uploading message */}

          {videoLink && (
            <div>
              <h3>Video Uploaded Successfully!</h3>
              <p>
                <a href={videoLink} target="_blank" rel="noopener noreferrer">
                  {videoLink}
                </a>
              </p>
            </div>
          )}

          <div className="video-list">
            <h3>Saved Videos</h3>
            {videoList.length > 0 ? (
              <ul>
                {videoList.map((video) => (
                  <li key={video._id}>
                    <a href={video.videoUrl} target="_blank" rel="noopener noreferrer">
                      {video.videoUrl}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No videos found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin_VideoUpload;
